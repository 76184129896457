import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import '../static/css/MobileFooterNav.css';
import home from '../static/images/images/home-solid.svg';
import home_r from '../static/images/images/home-solid-r.svg';
import profile from '../static/images/images/user-solid.svg';
import profile_r from '../static/images/images/user-solid-r.svg';
import explore from '../static/images/images/search-solid.svg';
import explore_r from '../static/images/images/search-solid-r.svg';
import settings from '../static/images/images/cog-solid.svg';
import settings_r from '../static/images/images/cog-solid-r.svg';
import logoIcon from '../static/images/images/logo_icon.png';

class MobileFooterNav extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: false,
            redirect: false,
        };
    };
    render() {
        if(this.state.redirect === true) {
            return <Redirect to = {{pathname: "/signin"}}/>
        };
        return (
            <div className="mobile-nav-bar mobile">

                <ul>
                    <li className="li-home">
                        <Link to="/home" className="no-dec" style={{color: this.props.data.path === "/home" ? "#FF2F46" : "white"}}>
                            <img className="home-home-icon" src={this.props.data.path === "/home" ? home_r : home} alt="" />
                            <br />
                            Home
                        </Link>
                    </li>
                    <li className="li-profile">
                        <Link to={`/profile/${this.props.data.id}`} className="no-dec" style={{color: this.props.data.path === `/profile/${this.props.data.id}` ? "#FF2F46" : "white"}}>
                            <img className="home-profile-icon" src={this.props.data.path === `/profile/${this.props.data.id}` ? profile_r : profile} alt="" />
                            <br />
                            Profile
                        </Link>
                    </li>
                    <li className="li-host">
                        <Link to="/host" className="no-dec" style={{color: this.props.data.path === "/host" ? "#FF2F46" : "white"}}>
                            <img className="home-logo-icon" src={logoIcon} alt="" />
                            <br />
                            Host
                        </Link>
                    </li>
                    <li className="li-explore">
                        <Link to="/explore" className="no-dec" style={{color: this.props.data.path === "/explore" ? "#FF2F46" : "white"}}>
                            <img className="home-explore-icon" src={this.props.data.path === "/explore" ? explore_r : explore} alt="" />
                            <br />
                            Expore
                        </Link>
                    </li>
                    <li className="li-settings">
                        <Link to="/settings" className="no-dec" style={{color: this.props.data.path === "/settings" ? "#FF2F46" : "white"}}>
                            <img className="home-settings-icon" src={this.props.data.path === "/settings" ? settings_r : settings} alt="" />
                            <br />
                            Settings
                        </Link>
                    </li>
                </ul>

            </div>
        )
    }
}

export default MobileFooterNav;