import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'; 
// import { Redirect } from 'react-router-dom';
import './App.css';
import Welcome from './components/Welcome';
// import WelcomeCS from './components/WelcomeCS';
import SignUp from './components/SignUp';
import SignUpInfo from './components/SignUpInfo';
import SignUpPlan from './components/SignUpPlan';
import SignUpPlanTrial from './components/SignUpPlanTrial';
import SignUpImageUpload from './components/SignUpImageUpload';
import SignUpProfileDetails from './components/SignUpProfileDetails';
import SignUpConfirmation from './components/SignUpConfirmation';
import SignIn from './components/SignIn';
import ForgotPassword from './components/ForgotPassword'
import ResetPassword from './components/ResetPassword'
import Home from './components/Home';
import EditProfile from './components/EditProfile';
import UploadPost from './components/UploadPost';
import EditImage from './components/EditImage';
import MyProfile from './components/MyProfile';
import Rider from './components/Rider';
import Explore from './components/Explore';
import CreateEvent from './components/CreateEvent';
import EventDetails from './components/EventDetails';
import EventDetailsEdit from './components/EventDetailsEdit';
import Messages from './components/Messages';
import Message from './components/Message';
import NewMessage from './components/NewMessage';
import ScrollToTop from './ScrollToTop';
import Settings from './components/Settings'
import Followers from './components/Followers';
import Following from './components/Following';
import SearchRider from './components/SearchRider';
import ChangeEmail from './components/ChangeEmail';
import ChangePassword from './components/ChangePassword';
import EmailUpdatesMarketing from './components/EmailUpdatesMarketing';
import HostedEventsHistory from './components/HostedEventsHistory';
import AttendedEventsHistory from './components/AttendedEventsHistory';
import ChangePayment from './components/ChangePayment';
import NextPaymentDate from './components/NextPaymentDate';
import CancelMembership from './components/CancelMembership';
import DeleteAccount from './components/DeleteAccount';
import TermsOfService from './components/TermsOfService';
import Privacy from './components/Privacy';
import Cookies from './components/Cookies';
import TermsOfServiceRead from './components/TermsOfServiceRead';
import PrivacyRead from './components/PrivacyRead';
import CookiesRead from './components/CookiesRead';
import BlockedUsers from './components/BlockedUsers';
import HelpAndFeedback from './components/HelpAndFeedback';
import AboutMotorcyk from './components/AboutMotorcyk';
import AboutMotorcykRead from './components/AboutMotorcykRead';
import ActivateMembership from './components/ActivateMembership';
import RiderTipJar from './components/RiderTipJar';
import RiderTipJarCashout from './components/RiderTipJarCashout';
import RiderTipJarGuide from './components/RiderTipJarGuide';
import MotorcykPlatformRules from './components/MotorcykPlatformRules';
class App extends Component {
  constructor() {
    super();
    this.state = {};
  };
  render() {
    return (
      <Router>

        <ScrollToTop>
          
          <Route path="/" exact component={ Welcome } />

          {/* <Route path="/coming-soon" exact component={ WelcomeCS } /> */}

          <Route path="/signin" exact component={ SignIn } />

          <Route path="/signup" exact component={ SignUp } />

          <Route path="/signup-info" exact component={ SignUpInfo } />

          <Route path={"/signup-plan-trial"} exact component={ SignUpPlanTrial } />

          <Route path={"/signup-plan-trial-signup"} exact component={ SignUpPlanTrial } />

          <Route path={"/signup-plan"} exact component={ SignUpPlan } />

          <Route path={"/profile-setup-profile-picture"} exact component={ SignUpImageUpload } />

          <Route path={"/profile-confirm-details"} exact component={ SignUpProfileDetails } />

          <Route path="/signup-confirmation" exact component={ SignUpConfirmation } />

          <Route path="/activate-membership" exact component={ ActivateMembership } />

          <Route path="/forgot-password" exact component={ ForgotPassword } />

          <Route path="/reset-password/:id/:token" exact component={ ResetPassword } />

          <Route path="/home" exact component={ Home } />
          
          <Route path="/edit-profile/:id" exact component={ EditProfile } />

          <Route path="/upload-post" exact component={ UploadPost } />

          <Route path="/edit-image/:id" exact component={ EditImage } />

          <Route path="/profile/:id" exact component={ MyProfile } />

          <Route path={"/@:rider"} exact component={ Rider } />

          <Route path={"/@:rider/tipped/:success"} exact component={ Rider } />

          <Route path={"/rider/:id"} exact component={ Rider } />

          <Route path="/explore" exact component={ Explore } />

          <Route path="/host" exact component={ CreateEvent } />

          <Route path="/event/:id" exact component={ EventDetails } />

          <Route path="/event/:id/:key" exact component={ EventDetails } />
          
          <Route path="/my/event/edit/:id" exact component={ EventDetailsEdit } />

          <Route path="/messages" exact component={ Messages } />

          <Route path="/message" exact component={ Message } />

          <Route path="/new-message" exact component={ NewMessage } />

          <Route path="/:id/followers" exact component={ Followers } />

          <Route path="/:id/following" exact component={ Following } />

          <Route path="/search-rider" exact component={ SearchRider } />

          <Route path="/settings" exact component={ Settings } />

          <Route path="/change-email" exact component={ ChangeEmail } />

          <Route path="/change-password" exact component={ ChangePassword } />

          <Route path="/email-updates-marketing" exact component={ EmailUpdatesMarketing } />

          <Route path="/hosted-events-history" exact component={ HostedEventsHistory } />

          <Route path="/attended-events-history" exact component={ AttendedEventsHistory } />

          <Route path="/change-payment" exact component={ ChangePayment } />

          <Route path="/process/:success" exact component={ ChangePayment } />

          {/* <Route path="/process/update/past-due/:success" exact component={ ChangePayment } /> */}

          <Route path="/next-payment-date" exact component={ NextPaymentDate } />

          <Route path="/cancel-membership" exact component={ CancelMembership } />

          <Route path="/delete/account" exact component={ DeleteAccount } />

          <Route path="/terms-of-service" exact component={ TermsOfService } />

          <Route path="/privacy" exact component={ Privacy } />

          <Route path="/cookies" exact component={ Cookies } />

          <Route path="/terms-of-service-read" exact component={ TermsOfServiceRead } />

          <Route path="/privacy-read" exact component={ PrivacyRead } />

          <Route path="/cookies-read" exact component={ CookiesRead } />

          <Route path="/blocked-users" exact component={ BlockedUsers } />

          <Route path="/help-feedback" exact component={ HelpAndFeedback} />

          <Route path="/about" exact component={ AboutMotorcyk } />

          <Route path="/about-read" exact component={ AboutMotorcykRead } />

          <Route path="/rider-tip-jar" exact component={ RiderTipJar } />

          <Route path="/rider-tip-jar-cashout" exact component={ RiderTipJarCashout } />

          <Route path="/rider-tip-jar-guide" exact component={ RiderTipJarGuide } />

          <Route path="/rules" exact component={ MotorcykPlatformRules } />

          {/* <Route path="*"> <Redirect to="/" /> </Route> */}

        </ScrollToTop>
        
      </Router>
    );
  };
};
export default App;
