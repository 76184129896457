import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import '../static/css/ForgotPassword.css';
import logoIcon from '../static/images/images/logo_icon.png';
class ForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
            logged_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            redirect: false,
            redirect_home: false,
            email: null,
            email_error: null
        };
        this.onChangeEmail = this.onChangeEmail.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( () => { this.setState({ isLoading: false, }); })
            .catch(err => { console.log( err, "You are not logged in or something went wrong with fetching data!"); this.setState({ isLoading: false, }) });
    };
    onChangeEmail(event) {
        this.setState({
            email: event.target.value
        });
    };
    async onSubmit(event) {
        event.preventDefault();
        this.setState({ isLoading: true, })
        const email = { email: this.state.email, }
        if (this.state.logged_in) this.setState({ redirect_home: true });
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/forgot-password', email)
            .then(response => {
                this.setState({
                    email: '',
                    email_error: response.data,
                    isLoading: false,
                });
            })
            .catch(err => {
                console.log(err, "Error with system!")
                if (err.response.data === false) {
                    this.setState({ email: null, isLoading: false, });
                } else {
                    this.setState({
                        email_error: err.response.data,
                        redirect_home: false,
                        isLoading: false,
                    });
                };
            });
    };
    render() {
        let navigate = this.state.redirect_home?<Link to="/settings" className="no-dec"><button className="forgot-password-cancel">Go Back</button></Link>:
        <div>
            <button className="forgot-password-button no">Forgot Password</button>
            <Link to="/signin" className="no-dec"><button className="forgot-password-cancel">Cancel</button></Link>
        </div>
        if (this.state.redirect === true) return <Redirect to={{pathname: "/signin"}}/>
        const body = this.state.isLoading?<Loading />:
        <div>
            <h1 className="forgot-password-title r">Forgot Password</h1>
            <Link to="/" className="no-dec"><img className="forgot-logo" src={logoIcon} alt="" /></Link>
            <h6 className="forgot-password-message w">Enter the email associated with your account, if account exists, an email will be sent with instructions to reset your password.</h6>
            <form className="forgot-password-form" onSubmit={this.onSubmit}>
                <p className="forgot-password-form-error-message">{ this.state.email_error }</p>
                <input type="text" placeholder="Email" className="forgot-password-input" value={this.email} onChange={this.onChangeEmail}/>
                { navigate }
                <p className="forgot-password-footer w">Motorcyk LLC</p>
            </form>
        </div>
        return (
            <div className="forgot-password-container">
                 { body }
            </div>
        );
    };
};
export default ForgotPassword;