import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import '../static/css/Explore.css';
import user from '../static/images/images/user-circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class Explore extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            isLoading: true,
            redirect: false,
            ui: null,
            end: null,
            cancelled: false,
            trial_used: false,
        };
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui:response.data.id, trial_used: response.data.trial_used===0?false:true, isLoading: false, }); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        const config_sub = {
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    active: response.data.status,
                    end: response.data.date,
                    cancelled: response.data.status === "canceled" ? true : false,
                }) 
                var end_date = new Date(response.data.date);
                var current_date = new Date();
                if (this.state.active) window.location = "/home";
                if (response.data.cancelled) {
                    if (end_date.setHours(0,0,0,0) < current_date.setHours(0,0,0,0)) {
                        window.location = "/home"
                        this.setState({ active: response.data.status, end: response.data.date, }); 
                    } ;
                };
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data, isLoading: false, })})
            .catch((err) => {console.log(err, "my error..."); this.setState({ isLoading: false, }) })
    };
    sign_out = () => {
        localStorage.removeItem('token');
        this.setState({ signed_in: false, FirstName: '' })
    }
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let form;
        form = 
            <div style={{ textAlign: "center", marginTop: "200px", }}>
                <p style={{ width: "400px", margin: "20px auto" }}>You must have an ACTIVE membership or be on a FREE TRIAL to access Motorcyk's features!<br /><br /> Please activate your membership!</p>
                <Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }} className="no-dec"><button className="create-event-form-button">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link><br/>
            </div>
        const body = this.state.isLoading?<Loading />: 
        <div className="explore-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li ><Link to="/" onClick={this.sign_out} className="home-home-link no-dec b">Sign Out</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-home-link no-dec b">Profile</Link></li>
                </ul>
            </div>
            <div className="explore-search-items">
                { form }
            </div>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default Explore;