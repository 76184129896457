import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import '../static/css/SignUpProfileDetails.css';
class SignUpProfileDetails extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            isLoading: true,
            redirect: false,
            ui: null,
            user: '',
            image_url: null,
            first_name: '',
            first_name_required: '',
            first_name_min: '',
            first_name_max: '',
            first_name_valid: '',
            display_name: null,
            display_name_error: '',
            display_name_max_error: '',
            display_name_exists_error: '',
            zip_code: '',
            zip_code_error: '',
            profile_header_message: '',
            profile_header_message_error: '',
            disabled: false,
            end: null,
            cancelled: false,
            message_unread: false,
            trial_used: false,
            non_active: false,
        };
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeZipCode = this.onChangeZipCode.bind(this);
        this.onChangeProfileHeaderMessage = this.onChangeProfileHeaderMessage.bind(this);
        this.onChangeDisplayName = this.onChangeDisplayName.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, }) })
            .catch(err => { this.setState({ redirect: true }) });
        const config_sub = { headers: { Authorization: `Bearer ${token}` }, id: this.state.ui, };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    active: response.data.status,
                    end: response.data.date,
                    cancelled: response.data.status === "canceled" ? true : false,
                }) 
                var end_date = new Date(response.data.date);
                var current_date = new Date();
                if (response.data.cancelled) {
                    if (end_date.setHours(0,0,0,0) < current_date.setHours(0,0,0,0)) {
                        this.setState({ active: response.data.status, end: response.data.date, }); 
                    } 
                };
                return response.data;
            })
            .catch((err) => { console.log(err, "Error in subscription retrieval") })
        var end_date = new Date(this.state.end);
        var current_date = new Date();
        if ((this.state.active === true) || ((this.state.cancelled === true) && (end_date.setHours(0,0,0,0))) > current_date.setHours(0,0,0,0)) {
            await axios.get(process.env.REACT_APP_SERVER_API + `/myprofile/edit/${this.state.ui}`)
                .then( (response) => {
                    this.setState({ 
                        user: response.data,
                        first_name: response.data.first_name,
                        zip_code: response.data.zip_code === null ? '' : response.data.zip_code,
                        profile_header_message: response.data.profile_header_message,
                        display_name: response.data.display_name,
                        isLoading: false,
                    }); 
                })
                .catch( (err) => {console.log(err, "Error in retreiving  user details!"); window.location = "/settings"})
        } else {
            await axios.get(process.env.REACT_APP_SERVER_API + `/myprofile/edit/${this.state.ui}`)
                .then( (response) => {
                    this.setState({ 
                        user: response.data,
                        first_name: response.data.first_name,
                        zip_code: response.data.zip_code === null ? '' : response.data.zip_code,
                        profile_header_message: response.data.profile_header_message,
                        display_name: response.data.display_name,
                        isLoading: false,
                        non_active: true,
                    }); 
                })
                .catch( (err) => {console.log(err, "Error in retreiving  user details!"); window.location = "/settings"})
        }
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status, }); })
            .catch((err) => { console.log(err, "Error checking for message status!"); })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data, isLoading: false, })})
            .catch((err) => {console.log(err, "my error..."); this.setState({ isLoading: false, }) })
    };
    onChangeFirstName(event) {
        this.setState({
            first_name: event.target.value
        });
    };
    onChangeZipCode(event) {
        this.setState({
            zip_code: event.target.value
        });
    };
    onChangeDisplayName(event) {
        this.setState({
            display_name: event.target.value
        });
    };
    onChangeProfileHeaderMessage(event) {
        this.setState({
            profile_header_message: event.target.value
        });
    };
    async onSubmit(event) {
        event.preventDefault();
        this.setState({ 
            disabled: true
        });
        const updates = {
            id: this.state.ui,
            first_name: this.state.first_name,
            zip_code: this.state.zip_code,
            display_name: this.state.display_name,
            profile_header_message: this.state.profile_header_message
        }
        if (this.state.signed_in) {
            await axios.put(process.env.REACT_APP_SERVER_API + '/myprofile/update', updates)
                .then(() => { window.location = "/profile-setup-profile-picture"})
                .catch(err => {
                    this.setState({
                        first_name_required: err.response.data.first_name_required,
                        first_name_min: err.response.data.first_name_min,
                        first_name_max: err.response.data.first_name_max,
                        first_name_valid: err.response.data.first_name_valid,
                        zip_code_error: err.response.data.zip_code_error,
                        profile_header_message_error: err.response.data.profile_header_message_error,
                        display_name_error: err.response.data.display_name_error,
                        display_name_max_error: err.response.data.display_name_max_error,
                        display_name_exists_error: err.response.data.display_name_exists_error,
                        disabled: false
                    });
                });
        } else {
            window.location = '/signin';
        };
    };
    sign_out = () => {
        localStorage.removeItem('token');
        this.setState({ signed_in: false, FirstName: '' })
    }
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let end_date = new Date(this.state.end);
        let current_date = new Date();
        let form;
        if ((this.state.active === true) || ((this.state.cancelled === true) && (end_date.setHours(0,0,0,0)) > current_date.setHours(0,0,0,0))) {
            form =
                <form onSubmit={this.onSubmit}>
                    <p className="signup-profile-details-error-message">{ this.state.first_name_required }</p>
                    <p className="signup-profile-details-error-message">{ this.state.first_name_min }</p>
                    <p className="signup-profile-details-error-message">{ this.state.first_name_max }</p>
                    <p className="signup-profile-details-error-message">{ this.state.first_name_valid }</p>
                    <input className="signup-profile-details-form-input" type="text" placeholder={ "First Name" } value={ this.state.first_name } onChange={ this.onChangeFirstName }/>

                    <p className="signup-profile-details-error-message">{ this.state.zip_code_error }</p>
                    <input className="signup-profile-details-form-input" type="text" placeholder={ "Zip Code" } value={ this.state.zip_code } onChange={ this.onChangeZipCode }/>

                    {/* <input className="signup-profile-details-form-input" type="text" placeholder="Experience Level"/> */}

                    <p className="signup-profile-details-error-message">{ this.state.profile_header_message_error }</p>
                    <input className="signup-profile-details-form-input" type="text" placeholder={ "Profile Header Message" } value={ this.state.profile_header_message } onChange={ this.onChangeProfileHeaderMessage }/>

                    <p className="signup-profile-details-error-message">{ this.state.display_name_error }</p>
                    <p className="signup-profile-details-error-message">{ this.state.display_name_max_error }</p>
                    <p className="signup-profile-details-error-message">{ this.state.display_name_exists_error }</p>
                    <input className="signup-profile-details-form-input" type="text" placeholder={ "Display Name" } value={ this.state.display_name } onChange={ this.onChangeDisplayName }/>
                    <br />
                    <button className="signup-profile-details-form-button" disabled={this.state.disabled ? true : false}>{this.state.disabled ? 'Updating...' : 'Continue'}</button>
                    <Link to="/profile-setup-profile-picture" className="no-dec bottom-button"><button className="signup-profile-details-form-button back">Skip</button></Link>
                </form>
        } else {
            form = 
                <div>
                    <p style={{fontSize: "15px", width: "90%", margin: "10px auto"}}>Looks like you don't have an active membership, please activate your membership to use Motorcyk's full features.</p>
                    <Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="no-dec" style={{marginBottom: "50px"}}><button className="signup-profile-details-form-button back">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link>
                    <hr style={{marginTop: "50px", width: "80%", opacity: ".50"}}/>
                    <form onSubmit={this.onSubmit} style={{marginTop: "50px"}}>
                        <p className="signup-profile-details-error-message">{ this.state.first_name_required }</p>
                        <p className="signup-profile-details-error-message">{ this.state.first_name_min }</p>
                        <p className="signup-profile-details-error-message">{ this.state.first_name_max }</p>
                        <p className="signup-profile-details-error-message">{ this.state.first_name_valid }</p>
                        <input className="signup-profile-details-form-input" type="text" placeholder={ "First Name" } value={ this.state.first_name } onChange={ this.onChangeFirstName }/>

                        <p className="signup-profile-details-error-message">{ this.state.zip_code_error }</p>
                        <input className="signup-profile-details-form-input" type="text" placeholder={ "Zip Code" } value={ this.state.zip_code } onChange={ this.onChangeZipCode }/>

                        {/* <input className="signup-profile-details-form-input" type="text" placeholder="Experience Level"/> */}

                        <p className="signup-profile-details-error-message">{ this.state.profile_header_message_error }</p>
                        <input className="signup-profile-details-form-input" type="text" placeholder={ "Profile Header Message" } value={ this.state.profile_header_message } onChange={ this.onChangeProfileHeaderMessage }/>

                        <p className="signup-profile-details-error-message">{ this.state.display_name_error }</p>
                        <p className="signup-profile-details-error-message">{ this.state.display_name_max_error }</p>
                        <p className="signup-profile-details-error-message">{ this.state.display_name_exists_error }</p>
                        <input className="signup-profile-details-form-input" type="text" placeholder={ "Display Name" } value={ this.state.display_name } onChange={ this.onChangeDisplayName }/>
                        <br />
                        <button className="signup-profile-details-form-button" disabled={this.state.disabled ? true : false}>{this.state.disabled ? 'Updating...' : 'Continue'}</button>
                        <Link to="/profile-setup-profile-picture" className="no-dec bottom-button"><button className="signup-profile-details-form-button">Skip</button></Link>
                    </form>
                </div>
        };
        const body = this.state.isLoading?<Loading />: 
        <div className="signup-profile-details-container">
            <div className="signup-profile-details-title-container">
                <h1 className="signup-profile-details-title">Confirm Or Edit Your Rider Details</h1>
            </div>
            <div className="signup-profile-details-edit-section">
                { form }
            </div>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default SignUpProfileDetails;