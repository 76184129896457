import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../static/css/ProfileFeed.css';
class ProfileFeed extends Component {
    constructor() {
        super();
        this.state = {};
        this.feed = this.feed.bind(this);
    };
    feed() {
        const Feed= (props) => (
            <div className="home-feed">
                <h6 className="home-feed-date-time">{props.feed.formatted_date}</h6>
                {props.feed.type === "New Event" ? <p className="home-feed-stuff"><Link to={props.feed.display_name?`/@${props.feed.display_name}`:`/rider/${props.feed.user_id}`} className="home-feed-link">{props.feed.display_name ? props.feed.display_name : props.feed.first_name}</Link> has set up a new riding event, to join or follow, check it out <Link to={`/event/${props.feed.event_id}`} className="home-feed-link">HERE</Link>.</p> : ''}
                {props.feed.type === "New Post" ? <p className="home-feed-stuff"><Link to={props.feed.display_name?`/@${props.feed.display_name}`:`/rider/${props.feed.user_id}`} className="home-feed-link">{props.feed.display_name ? props.feed.display_name : props.feed.first_name}</Link> has posted a new image to their collection, go check out their profile <Link to={props.feed.display_name?`/@${props.feed.display_name}`:`/rider/${props.feed.user_id}`} className="home-feed-link">HERE</Link>.</p> : ''}
                {props.feed.type === "Event Cancelled" ? <p className="home-feed-stuff"><Link to={props.feed.display_name?`/@${props.feed.display_name}`:`/rider/${props.feed.user_id}`} className="home-feed-link">{props.feed.display_name ? props.feed.display_name : props.feed.first_name}</Link> has cancelled an event, if you had previously joined, you will no longer see it in your upcoming events.</p> : ''}
                {/* {props.feed.type === "Event Join" ? <p className="home-feed-stuff">{props.feed.display_name ? props.feed.display_name : props.feed.first_name} has posted a new event, go check it out HERE.</p> : ''} */}
            </div>
        );
        return this.props.data.feed.map(feed => {
            return  <Feed feed={feed} key={feed.id} />
        });
    };
    render() {
        let feed;
        let statement;
        let activate_button;
        if(this.props.data.status.active === true) {
            if (this.props.data.feed.length > 0) {
                feed = this.props.data.isLoading?'':this.feed();
                statement = '';
            } else {
                feed = '';
                statement = <p style={{width: "80%", textAlign: "center", margin: "auto"}}>Nothing to display! Go Explore and follow other riders to see news feed!</p>
            }
        } 
        return (
            <div className="profile-posts-component-container">
                { feed }
                { statement }
                { activate_button }
            </div>
        );
    };
};
export default ProfileFeed;