import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../static/css/TermsOfService.css';
class TermsOfService extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
        };
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( () => { window.location = "/terms-of-service"; })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ isLoading: false })
            });
    };
    render() {
        const body = this.state.isLoading?
        <h1>Loading...</h1>:
        <div className="terms-of-service-container">
            <div className="terms-of-service-title-container">
                <h1 className="terms-of-service-title" >Terms Of Service</h1>
            </div>
            <div className="terms-of-service-section">
                <Link to="/signup" className="no-dec"><button className="terms-of-service-button">Back</button></Link>
                <br/>
                <p>
                    Below is an overview of our Terms of Service for our “Platform”, which means any website, application, or service we offer. You should read the complete Terms of Service because that document (and not this overview) is our legally binding agreement. The Terms of Service includes information about your legal rights and covers areas such as automatic subscription renewals, limitations of liability, resolution of disputes by mandatory arbitration rather than a judge or jury in a court of law, and a class action waiver.
                </p>

                <h3>Your Relationship with Motorcyk</h3>

                <p>
                    By using our Platform, you are agreeing to our Terms of Service. The Terms of Service are a legally binding agreement between you and Motorcyk.
                    If you break the rules, we may suspend or terminate your account.
                    We charge for certain aspects of our Platform, and some of these fees are billed on a regular and recurring basis (unless you disable auto-renewal or cancel your subscription).
                    Motorcyk Groups, Organizers and Members
                    <br/><br/>
                    Organizers may establish membership criteria for their own Motorcyk groups. While there is probably a Motorcyk group out there for everyone, not every Motorcyk group is for you. If you can't find the right group, you can easily start your own Motorcyk group.
                    Organizers may charge fees for memberships or events.
                    Using our Platform involves meeting real people and doing real things in the real world, which can sometimes lead to unexpected situations. We can't control what happens in the real world, and we are not responsible for it. You should use common sense and good judgment when interacting with others.
                    Your Content and Content of Others
                    <br/><br/>
                    You are responsible for your “Content”, which means any information, material, or other content posted to our Platform. Your Content must comply with our Terms of Service, which includes the Usage and Content Policies, Groups and Events Policies, Organizer and Leadership Standards, Member Restrictions, Payment Policies, Trademark Usage Guidelines, and API License Terms. Your Content is also subject to our Intellectual Property Dispute Policies.
                    We do not own the Content that you post. However, we do require that you provide us a license to use this Content in order for us to operate, improve, promote, and protect Motorcyk and our Platform for the benefit of you and others.
                    We are not responsible for Content that members post or the communications that members send using our Platform. We generally don't review Content before it's posted. If you see Content that violates our Terms of Service, you may report inappropriate Content to us.
                    Our Platform
                    <br/><br/>
                    We try hard to make sure that our Platform is always available and working, but we cannot guarantee it will be. Occasionally things may not go exactly as planned. We apologize in advance for any inconvenience.
                    We are continually improving our Platform. This means that we may modify or discontinue portions of our Platform.
                    By using our Platform, you agree to the limitations of liability and release in our Terms of Service. You also agree to resolve any disputes you may have with us in the manner described in the Terms of Service. Unless you are based in the European Union, you agree to mandatory arbitration and waive your right to seek relief from a judge or jury in a court of law. Claims can only be brought individually, and not as part of a class action.
                    You may be based in a country where the laws that apply provide for different or additional rights and obligations. These Terms of Service will point out areas of difference.
                </p>

                <h3>Terms of Service</h3>

                <p>
                    Our Terms of Service contain important information about your legal rights. To make it easier to understand, we have provided summaries and tips in these gray boxes. These summaries are not part of the official terms, and it is important for you to read the language in each section carefully.
                </p>

                <h4>1. This Agreement</h4>

                <p>
                    <b>Summary:</b> You agree to follow some basic rules when using Motorcyk's Platform. These rules are described in these Terms of Service, including the related policies and guidelines discussed below. We may change these rules.
                    <br/><br/>
                    <b>1.1 The Agreement.</b> Motorcyk enables you and other members to arrange offline, real-world Motorcyk groups and Motorcyk events. The terms “Motorcyk,” “we,” “us,” and “our” include Motorcyk, Inc. and our corporate parent, affiliates, or subsidiaries. We use the terms “you” and “your” to mean any person using our Platform, and any organization or person using the Platform on an organization's behalf. We use the word “Platform” to mean any website, application, or service offered by Motorcyk, including content we offer and electronic communications we send. We provide our Platform to you subject to these Terms of Service. We use the terms “Terms of Service” and “Agreement” interchangeably to mean this document together with our Usage and Content Policies, Groups and Events Policies, Organizer and Leadership Standards, Member Restrictions, Payment Policies, Trademark Usage Guidelines, and API License Terms. Your use of the Platform signifies that you agree to this Agreement. If you are using the Platform for an organization, you agree to this Agreement on behalf of that organization, and represent you have authority to bind that organization to the terms contained in this Agreement. If you do not or are unable to agree to this Agreement, do not use our Platform.
                    <br/><br/>
                    <b>Tip:</b> We have included summaries and tips to provide an overview of some legal concepts and answer common questions, but the text of these summaries and tips are for your convenience only and do not have a legal effect.
                    <br/><br/>
                    <b>Tip:</b> These Terms of Service are an agreement between you and Motorcyk and not between you and any other member. For more information.
                    <br/><br/>
                    <b>1.2 Revisions to this Agreement.</b> We may modify this Agreement from time to time. When we do, we will provide notice to you by publishing the most current version and revising the date at the top of this page. If we make any material change to this Agreement, we will provide additional notice to you, such as by sending you an email or displaying a prominent notice on our Platform. By continuing to use the Platform after any changes come into effect, you agree to the revised Agreement. If you do not wish to accept the revised Agreement, you can close your account.
                </p>
                
                <h4>2. Your Account and Membership</h4>

                <p>
                    <b>Summary:</b> You need to be at least 18 years old to use our Platform. Motorcyk organizers control the Content and membership of their Motorcyk groups. However, we may remove any Content you post or terminate your account at any time.
                    <br/><br/>
                    <b>2.1 Eligibility.</b> Our Platform is available to anyone who is at least 18 years old. You represent that you are at least 18. Additional eligibility requirements for a particular portion of our Platform may be set by any member who has the ability to moderate or manage that portion of our Platform. For example, the eligibility requirements for a Motorcyk group or Motorcyk event may be set by the organizers of that group.
                    <br/><br/>
                    <b>2.2 Modification, Suspension, and Termination of Your Account.</b> We may modify, suspend, or terminate your account or access to the Platform if, in our sole discretion, we determine that you have violated this Agreement, including any of the policies or guidelines that are part of this Agreement, that it is in the best interest of the Motorcyk community, or to protect our brand or Platform. When this happens, we will notify you of the reasons for the modification, suspension, or termination. We also may remove accounts of members who are inactive for an extended period of time. Please email hello@Motorcyk.com if you believe the modification, suspension, or termination has occurred in error.
                    <br/><br/>
                    A member who has the ability to moderate or manage a particular portion of our Platform also has the ability, in his or her sole discretion, to modify, suspend, or terminate your access to that portion of the Platform.
                    <br/><br/>
                    <b>2.3 Account Information and Security.</b> When you register, you provide us with some basic information, including an email address and a password. Keep your email address and other account information current and accurate. Also, you agree to maintain the security and confidentiality of your password (or else we may need to disable your account). We strongly encourage you to choose a strong and unique password that is not shared with any other account or online service and practice other healthy password security habits to help avoid unauthorized access to your account. You alone are responsible for anything that happens from your failure to maintain that security and confidentiality, such as by sharing your account credentials with others. If someone is using your password or accessing your account without your permission, email us at hello@Motorcyk.com.
                    <br/><br/>
                    <b>2.4 License to the Motorcyk Platform and Services.</b> Subject to your compliance with this Agreement, Motorcyk grants you a limited, non-exclusive, revocable, non-sublicensable, non-transferable right to use the Platform in order to access and use the services and features that we make available to you.
                </p>

                <h4>3. Fees, Payments, and Offers</h4>

                <p>
                    <b>Summary:</b> We charge for certain features on our Platform. If you're a Motorcyk group organizer, you're responsible for paying for organizer subscription fees related to your Motorcyk group. These fees are billed on a regular and recurring basis (unless you disable auto-renewal or cancel your subscription), and may change in the future. Additionally, some organizers charge money for participation in a Motorcyk group or Motorcyk event.
                    <br/><br/>
                <b> 3.1 Fees Charged by Motorcyk.</b> Use of some of the features on our Platform is free, and we charge fees for other features. We may in the future implement a new fee, or modify an existing fee, for certain current or future features of our Platform. If we implement a new or modified fee, we will give you notice in advance such as by posting changes on our Platform or sending you an email. You agree to pay those fees and any associated taxes for your continued use of the applicable service. Unless otherwise stated, all fees and all transactions are in U.S. dollars. Organizer subscriptions are non-transferable. Members based in the European Union may cancel with a full refund within 14 days after signing up to our Platform. However, if you use the service, you may be charged for services provided during this 14-day period. Please note that this 14-day period begins when the service starts even if you are using a free trial. Users based in the European Union can exercise their cancellation right by emailing us at hello@Motorcyk.com. See the model cancellation form here.
                    <br/><br/>
                    <b>3.2 Fees Charged by Organizers.</b> Organizers may impose fees related to particular portions of the Platform, such as member dues for a Motorcyk group or an event fee for a Motorcyk event. We reserve the right to initiate refunds of fees paid to organizers, when appropriate, even in cases of fees paid through a third-party payment service. Refunds that we initiate, if any, will be governed by our Payment Policies. Organizers may also have their own refund policies, so long as they are consistent with and do not limit members' ability to receive refunds under our Payment Policies. Payments made to organizers via the Platform are made through a third-party payment service provider. If a member pays a fee to an organizer via the Platform, the member authorizes the organizer (and the organizer's applicable payment service provider) to charge the designated payment method for the total amount of the fees, including any applicable taxes and other charges. Certain types of fees charged by organizers may be billed on a recurring basis. If billed on a recurring basis, you authorize the organizer to charge the applicable fee to the designated payment method. You may cancel auto-renewal at any time.
                    <br/><br/>
                    <b>Tip:</b> You should use common sense in deciding whether to make a payment to any organizer. We cannot ensure that organizers will use payments as they promise or as you expect. You should check the refund policy of the Motorcyk group before making any payments to an organizer.
                    <br/><br/>
                    <b>3.3 Payments to Motorcyk.</b> Organizers are responsible for paying subscription and any other applicable fees to Motorcyk on time and through our approved payment methods. Organizers who allow their organizer subscription to lapse are subject to removal as the organizer of the Motorcyk groups associated with their account, and their Motorcyk group (along with all of the information and Content associated with or posted to that Motorcyk group) may be terminated or transferred to another member. You may only pay organizer fees to Motorcyk using a valid payment method acceptable to us, as specified via the Platform. If we terminate, suspend, or remove your account in connection with violation of this Agreement, we are not obligated to refund any organizer subscription fees paid to Motorcyk. In addition, if you paid your organizer fees through the Motorcyk app for iOS, your payment is subject to the Apple Media Services Terms and Conditions, and you will need to submit your request for a refund to Apple.    
                    <br/><br/>
                    You represent and warrant that you are authorized to use the payment method you designate via the Platform. You authorize us (and our designated third-party payment processors) to charge your designated payment method for the total amount of any fees you owe to Motorcyk, including any applicable taxes and other charges. If the payment method cannot be verified, is invalid, or is otherwise not acceptable to us, your payment may be suspended or cancelled. We reserve the right to adjust, or to instruct our payment processors to make adjustments to a payment that has already been requested or received, in order to correct errors or mistakes, or to issue refunds, in accordance with our Payment Policies.
                    <br/><br/>
                    <b>3.4 Automatic Subscription Renewals.</b> Fees for certain aspects of our Platform may be billed on either a recurring basis or on a one-time basis. If billed on a recurring basis, the fees are payable in advance of the applicable period specified via the Platform with no refunds. We will automatically bill you for each renewal period until cancellation. By purchasing any feature or other aspect of our Platform for which we charge, you authorize us to keep your payment current by charging the applicable fee to your credit card account (or any other means of payment used by you). While you may cancel auto-renewal or cancel your subscription at any time, refunds will be issued solely pursuant to our Subscription Refund Policy.
                    <br/><br/>
                    <b>3.5 Free Trials.</b> We may offer free trials of subscriptions and other products on our Platform. We will inform you of the length of the free trial, your renewal period, and the date and amount of your first payment. After your free trial ends, your paid subscription will begin and we will automatically bill you for each renewal period until cancellation. You can cancel automatic subscription renewals at any time according to the terms and procedures described above. Users based in the European Union are entitled to cancel with a full refund within 14 days after signing up to our Platform by emailing us at hello@Motorcyk.com. See the model cancellation form here. Please note that this 14-day period begins when the free trial starts.
                    <br/><br/>
                    <b>3.6 Third Party Payment Processors.</b> An organizer that uses the Platform to accept payments from other members must comply with the terms and conditions of the third party provider of the applicable payment service used to receive the payment. Motorcyk does not provide those payment services, is not a party to your agreement with the applicable third-party provider, and will not be liable or responsible for your use of those third-party payment services.
                    <br/><br/>
                    <b>3.7 Invoices from Third Parties.</b> In line with the tax laws of the country where an organizer is using the Platform, an organizer may receive Motorcyk invoices from a third party provider. Such third party provider invoicing does not in any way impact the rights and obligations agreed to in this Agreement, nor does it impact the fee being charged for the use of the Platform.
                </p>

                <h4>4. Your Content and Privacy</h4>

                <p>
                    <b>Summary:</b> You are responsible for the Content you post on our Platform or send to us. You give us a license to use this Content to operate, improve, promote, and protect Motorcyk and our Platform. Our Privacy Policy explains how we collect and use information you provide about yourself and the information that may be associated with you, and explains the limited ways we may share this information.
                    <br/><br/>
                    <b>4.1 Your Content.</b> You are responsible for the Content that you post to the Platform or otherwise provide to Motorcyk. We use the word “Content” to mean the information, material, and any other content that you post to the Platform or otherwise send to us. Examples of your Content include:
                    <br/><br/>
                    The material that organizers and members typically post to the Platform, such as information about Motorcyk groups and Motorcyk events, comments, and photos;
                    All feedback, suggestions, and other communications that you send or direct to Motorcyk.
                    <br/><br/>
                    By being responsible for your Content, you agree, among other things, that:
                    <br/><br/>
                    You have all the permissions, rights, and licenses needed (including under copyrights, trademarks, contract rights, privacy rights, or publicity rights) to provide the Content to the Platform and to Motorcyk;<br/>
                    Your Content does not include personal, private or confidential information belonging to others; <br/>and
                    Your Content does not otherwise violate the rights of any individual or entity.<br/>
                    You also agree that you and your Content comply with this Agreement, including our Usage and Content Policies, Groups and Events Policies, Organizer and Leadership Standards, Member Restrictions, Intellectual Property Policies, Trademark Usage Guidelines, and API License Terms. You also agree that we may remove the metadata associated with your Content.
                    <br/><br/>
                    <b>Tip:</b> Search engines can see public areas of the Platform. Your Content within these areas, such as the name and location that you provide during registration, and your membership in public Motorcyk groups, may appear in search results. Our Platform is designed so that search engines cannot see your Content that is within restricted areas of the Platform, such as private Motorcyk groups.
                    <br/><br/>
                    <b>4.2 Content License from You.</b> We do not claim ownership of your Content. However, to enable us to operate, improve, promote, and protect Motorcyk and our Platform, and to ensure we do not violate any rights you may have in your Content, you hereby grant Motorcyk a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sublicensable, transferable right and license (including a waiver of any moral rights) to use, host, store, reproduce, modify, publish, publicly display, publicly perform, distribute, and create derivative works of, your Content and to commercialize and exploit the copyright, trademark, publicity, and database rights you have in your Content. This license would permit your Content to remain on the Platform, even after you cease to be a member of a Motorcyk group or of the Platform.
                    <br/><br/>
                    <b>Tip:</b> This license doesn't give us ownership of any of your Content. It simply gives us and members the right to use it on or related to the Platform. This license continues even if you close your account, because it's necessary for us to operate the Platform.
                    <br/><br/>
                    <b>4.3 Privacy.</b> Motorcyk collects registration and other information about you through our Platform. Please refer to our Privacy Policy and Cookie Policy for details on how we collect, use, and disclose this information. These policies do not govern use of information that you provide to third parties, such as to organizers and other members of Motorcyk's Platform.
                    <br/><br/>
                    <b>Tip:</b> Motorcyk has no control over how other members may use information that you provide to them, so you should exercise common sense and good judgment when sharing information with others on our Platform.
                </p>

                <h4>5. Your Use of Our Platform</h4>

                <p>
                    <b>Summary:</b> We require that you follow our policies and guidelines when using our Platform. We have no responsibility for Content that anyone posts to our Platform.
                    <br/><br/>
                    <b>5.1 Our Policies, Guidelines and Applicable Laws.</b> When you use our Platform, we require that you follow the Usage and Content Policies, Groups and Events Policies, Organizer and Leadership Standards, Member Restrictions, Payment Policies, Trademark Usage Guidelines, and API License Terms. You also agree to comply with all applicable laws, rules and regulations, and to not violate or infringe the rights of any third party.
                    <br/><br/>
                    In addition, your access to and use of some areas or features of the Platform may be subject to additional terms, policies, standards or guidelines (“Additional Terms”). You may be required to accept these Additional Terms before you can access these Platform areas and features. If there is a conflict between the Terms of Service and the Additional Terms, those Additional Terms will govern your access to and use of that Platform area or feature, unless otherwise specified in the Additional Terms.
                    <br/><br/>
                    When the Platform uses third party services to provide certain features and services for our members, our members may be required to comply with the terms of service that apply to these features and services. For example, some areas of our Platform feature mapping services provided by Google Maps and Google Earth. When you use these mapping services on the Platform, you agree to be bound by the Google Maps/Google Earth Additional Terms of Service.
                    <br/><br/>
                    If you do not comply, we may modify, suspend, or terminate your account or access to the Platform, in our sole discretion, and we will provide you with reasons for the modification, suspension, or termination. Please email hello@Motorcyk.com if you believe the modification, suspension, or termination has occurred in error.
                    <br/><br/>
                    <b>5.2 Content of Others.</b> Motorcyk does not control the Content of other members. When we become aware of inappropriate Content on our Platform, we reserve the right to investigate and take appropriate action, but we do not have any obligation to monitor, nor do we take responsibility for, the Content of other members.
                    <br/><br/>
                    <b>5.3 Interactions with Others.</b> Motorcyk is not a party to any offline arrangements made through our Platform. Motorcyk does not conduct or require background checks on members and does not attempt to verify the truth or accuracy of statements made by members. Motorcyk makes no representations or warranties concerning the conduct or Content of any members or their interactions with you.
                    <br/><br/>
                    <b>Tip:</b> Exercise common sense and good judgment when using our Platform and interacting with other members, both on our Platform and at Motorcyk events. If you have a concern regarding other members, you can report it to hello@Motorcyk.com.
                    <br/><br/>
                    <b>5.4 Prohibited Uses of the Platform.</b> Our Platform contains proprietary and confidential information and is protected by intellectual property and other laws. Unless we expressly permit it through this Agreement, you agree that you will not, either directly or indirectly (a) use, host, store, reproduce, modify, publish, publicly display, publicly perform, distribute, or create derivative works of the Platform, or any portion of the Platform; (b) remove or alter the proprietary notices on the Platform; (c) reverse engineer, disassemble, decompile, or attempt to discover the source code or structure, sequence, and organization of the Platform; and (d) rent, lease, resell, distribute, or use the Platform for commercial purposes that are not contemplated by this Agreement. You also agree that you will not use the Platform to solicit or collect (i) personal data from others except as necessary for the administration of or participation in a group or event or (ii) sensitive personal data, as defined or treated as such under applicable law (including, by way of example only, health information and social security numbers and other government identifiers). In addition, you agree that you will comply with the export control laws of your local jurisdiction. You also represent and warrant that you are not located in a country that is subject to a U.S. government embargo or that has been designated by the U.S. government as a “terrorist supporting” country, and that you are not listed on any U.S. government list of prohibited or restricted parties. If you are a developer working with our API, you should review our API License Terms.
                    <br/><br/>
                    <b>5.5 Platform Safety and Security.</b> You agree that you will not, either directly or indirectly, (a) extract data from the Platform for a commercial purpose not permitted by these Terms of Service, whether through use of an automated system or software, and whether operated by a third party or otherwise (“screen scraping,” “data scraping,” or “web scraping”); (b) engage in any activity  that interferes with or disrupts, that is designed to interfere with or disrupt, or imposes undue burdens on the Platform or its systems.
                    <br/><br/>
                    You agree to use, retain, and otherwise process personal data collected from the Platform in accordance with applicable laws, rules, and regulations and solely for purposes of administering and participating in Motorcyk groups and Motorcyk events. Without limitation, you agree to provide notice to individuals about your processing of their personal data, to obtain any necessary consents, and to respond to requests made by individuals as required by applicable law. You also agree to safeguard such data from unauthorized access or processing. You must delete such personal data once it is no longer strictly necessary for the administration of a Motorcyk group or Motorcyk event or promptly following a request from Motorcyk, and you will promptly certify to such deletion. 
                    <br/><br/>
                    <b>5.6 Platform Modifications.</b> We work hard to continuously improve our Platform. This means that we may modify or discontinue portions or all of our Platform with or without notice and without liability to you or any third party.
                    <br/><br/>
                    <b>5.7 Third Party Sites and Services.</b> The Platform contains links to third party sites, and is integrated with various third party services, applications and sites that may make available to you their content and products. We don't control these third parties and aren't responsible for those sites or services or their content or products. These third parties may have their own terms and policies, and your use of them will be governed by those terms and policies. You do not have a license to use the intellectual property of third parties merely by way of your access to our Platform.
                </p>
                
                <h4>6. Release</h4>

                <p>
                    <b>Summary:</b> To the full extent permitted by applicable law, you agree not to hold us responsible for anything that happens related to transactions with third parties, member interactions, or in connection with a Motorcyk group or Motorcyk event. You also agree not to hold organizers responsible for their negligence in connection with their Content, a Motorcyk group, or Motorcyk event.
                    <br/><br/>
                    To the full extent permitted by applicable law, you agree to release us and our officers, directors, shareholders, agents, employees, consultants, corporate parent, affiliates, subsidiaries, sponsors, and other third-party partners ( “Motorcyk Parties”) from claims, demands, and damages (direct and consequential) (“Claims”), arising out of or in any way connected with any transaction with a third party, your interactions with other members, or in connection with a Motorcyk group or a Motorcyk event. You also agree, to the full extent permitted by applicable law, to release organizers from Claims based on an organizer's negligence arising out of or in any way connected with their Content, a Motorcyk group, or a Motorcyk event. The law in some countries and states do not allow the release, so these limits may not apply to you. You waive and relinquish all rights and benefits that you have or may have under Section 1542 of the California Civil Code or any similar provision of statutory or non-statutory law of any other jurisdiction to the fullest extent permitted by law.
                    <br/><br/>
                    You acknowledge that some Motorcyk events carry inherent dangers, such as the risk of illness, bodily injury, disability, or death. By participating in these events, you understand and agree that you have freely chosen to assume these risks.
                </p>

                <h4>7. Indemnification</h4>

                <p>
                    <b>Summary:</b> To the full extent permitted by applicable law, you agree to reimburse us if we get sued in connection with your use of our Platform.
                    <br/><br/>
                    To the full extent permitted by applicable law, you agree to indemnify, defend and hold all Motorcyk Parties harmless from any Claims, made by any third party due to or arising out of (a) your violations of this Agreement, (b) your use, misuse, or abuse of our Platform, (c) your Content, (d) your violation of any law, statute, ordinance or regulation or the rights of a third party, or (e) your participation or conduct in a Motorcyk group or a Motorcyk event that violates this Agreement. You agree to promptly notify us of any third party Claims, cooperate with all Motorcyk Parties in defending such Claims, and pay all fees, costs and expenses associated with defending such Claims (including, but not limited to, attorneys' fees). You agree not to settle any Claim without our prior written consent.
                </p>

                <h4>8. Warranty Disclaimer and Limitation of Liability</h4>

                <p>
                    <b>Summary:</b> Our Platform is provided to you “as is” and we make no warranties of any kind with respect to our Platform. Our liability in connection with the Platform is limited to the fees you paid to us in the 12 months preceding the claim or $100, whichever is greater.
                    <br/><br/>
                    <b>8.1 Warranty Disclaimer.</b> Our Platform is provided to you “as is” and on an “as available” basis. To the full extent permitted by applicable law, we disclaim all warranties and conditions of any kind, including but not limited to statutory warranties, and the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We also disclaim any warranties regarding (a) the reliability, timeliness, accuracy, and performance of our Platform, (b) any information, advice, services, or goods obtained through or advertised on our Platform or by us, as well as for any information or advice received through any links to other websites or resources provided through our Platform, (c) the results that may be obtained from the Platform, and (d) the correction of any errors in the Platform, (e) any material or data obtained through the use of our Platform, and (f) dealings with or as the result of the presence of marketing partners or other third parties on or located through our Platform. You may have additional rights under the law of the country in which you are based. You agree that the duration of such additional rights will be limited to the full extent permitted by such law.
                    <br/><br/>
                    <b>8.2 Limitation of Liability.</b> To the full extent permitted by applicable law, you agree that in no event shall any Motorcyk Parties be liable for any direct, indirect, incidental, special, or consequential damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses (even if any Motorcyk Parties have been advised of the possibility of such damages) arising out of or in connection with (a) our Platform or this Agreement or the inability to use our Platform (however arising, including our negligence), (b) statements or conduct of or transactions with any member or third party on the Platform, (c) your use of our Platform or transportation to or from Motorcyk events, attendance at Motorcyk events, participation in or exclusion from Motorcyk groups or Motorcyk events and the actions of you or others at Motorcyk events, or (d) any other matter relating to the Platform. Our liability to you or any third parties in any circumstance is limited to the greater of $100 or the amount of fees, if any, you paid to us in the 12 months prior to the action that may give rise to liability. The limitations set forth above in this Section 8 will not limit or exclude liability for our gross negligence, fraud, or intentional, malicious, or reckless misconduct. Some laws do not allow the limitation or exclusion of liability, so these limits may not apply to you. If you are a member based in the EU, you have legal remedies if we cause you loss by our breach or non-performance of our legal obligations, or by our negligence, and these terms do not affect those statutory remedies.
                </p>

                <h4>9. Dispute Resolution</h4>

                <p>
                    <b>Summary:</b> If you have a dispute with Motorcyk, you agree to try to work it out directly with us first. If we can't work it out, with limited exceptions, you must submit any dispute with us to a neutral arbitrator instead of taking the claim to a court of law. Claims can only be brought individually, and not as part of a class action. Members based in the European Union may have additional or different rights, as provided by applicable law. Motorcyk has no obligation to get involved with any disputes you have with other members, although we may try to facilitate a resolution.
                    <br/><br/>
                    <b>9.1 Informal Resolution.</b> Before making any claim, you and Motorcyk agree to try to resolve any disputes through good faith discussions. We use the term “claim” in this Section 9 to mean any dispute, claim or controversy arising out of or relating to your use of our Platform or this Agreement, including your participation in Motorcyk events. You or Motorcyk may initiate this process by sending written notice according to Section 11.2 describing the dispute and your proposed resolution. In the event that we cannot resolve the issue within 30 business days following receipt of the initial notice, you or Motorcyk may bring a claim in accordance with this Section 9. Members based in the European Union may have additional or different rights, as provided by applicable law.
                    <br/><br/>
                    <b>9.2 Arbitration Agreement.</b> The arbitration procedures described in this Section 9.2 applies to all members, except for members who are based in the European Union. Section 9.6 sets out the dispute resolution procedures that apply to members who are based in the European Union.
                    <br/><br/>
                    <b><i>a. Mandatory Arbitration.</i></b> Except as set forth in Section 9.3, you agree to submit any claim to JAMS, Inc., or its successor (“JAMS”) for final and binding arbitration. In arbitration, certain rights that you or we would have in court may not be available, such as discovery or appeal. You and Motorcyk are each expressly waiving any right to trial by judge or jury in a court of law. This agreement to arbitrate shall apply regardless of whether the claim arises during or after any termination of this Agreement or your relationship with Motorcyk.
                    <br/>
                    <b><i>b. Arbitration Time for Filing.</i></b> Any claim subject to arbitration must be filed within one year after the date the party asserting the claim first knows or should know of the act, omission or default giving rise to the claim, or the shortest time period permitted by applicable law.
                    <br/>
                    <b><i>c. Arbitration Procedures.</i></b> Either party may commence arbitration by filing a written demand for arbitration with JAMS, with a copy to the other party according to the notice procedures in Section 11.2. The arbitration will be conducted in accordance with JAMS Streamlined Arbitration Rules and Procedures and any other applicable rules that JAMS requires (“JAMS Rules”) that are in effect as of the demand for arbitration. You agree that the U.S. Federal Arbitration Act and federal arbitration law govern the interpretation and enforcement of these arbitration provisions. Any arbitration hearings will take place in New York County, New York or elsewhere as required by JAMS Rules. Your responsibility to pay any filing, administrative and arbitrator fees will be solely as set forth in the JAMS Rules. The parties will cooperate with JAMS and each other in scheduling the arbitration proceedings, and in selecting one arbitrator from the appropriate JAMS list with substantial experience in resolving intellectual property and contract disputes. The arbitrator shall follow this Agreement and, to the extent permitted by JAMS Rules, can award costs, fees, and expenses, including attorneys' fees, to the prevailing party, except that the arbitrator shall not award declaratory or injunctive relief benefiting anyone but the parties to the arbitration. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction.
                    <br/><br/>
                    <b>9.3 Exceptions.</b> You or Motorcyk may assert claims, if they qualify, in small claims court in New York County, New York or any U.S. county where you live or work. You or Motorcyk may seek injunctive relief from a court of competent jurisdiction in New York County, New York as necessary to protect the intellectual property rights of you or Motorcyk pending the completion of arbitration. Motorcyk may take action in court or arbitration to collect any fees or recover damages for, or to seek injunctive relief relating to, Platform operations, or unauthorized use of our Platform or intellectual property. Nothing in this Section 9 shall diminish Motorcyk's right to modify, suspend or terminate your account or access to our Platform under Section 2.2.
                    <br/><br/>
                    <b>9.4 Arbitration Opt Out.</b> You may decline to resolve disputes through arbitration by emailing us at hello@Motorcyk.com within 30 days of the date you first agree to this Agreement. Your email must include your full name, residential address, the email address registered to your Motorcyk account, and a clear statement that you want to opt out of arbitration. If you opt out according to this process, then Section 9.2 of this Agreement does not apply to you. This opt-out does not affect any other sections of this Agreement, such as Sections 9.3 (Exceptions), 9.5 (Class Action Waiver), 11.5 (Governing Law), 11.6 (Judicial Forum), and 11.7 (Time for Filing).
                    <br/><br/>
                    <b>9.5 Class Action Waiver.</b> You agree to resolve disputes with Motorcyk on an individual basis. You agree not to bring a claim as a plaintiff or a class member in a class, consolidated, or representative action. You are expressly waiving any right to participate in class actions, class arbitrations, private attorney general actions, and consolidation with other arbitrations.
                    <br/><br/>
                    <b>9.6 Dispute Resolution Terms Applicable to Members Based in the EU.</b> If you are a member based in the European Union, the mandatory provisions of the law of the country in which you are resident will apply. Nothing in this Agreement will affect your rights as a consumer to rely on the mandatory provisions of the local law that applies. If you are based in the European Union, the local laws in your European Union Member State may allow you to take legal action against Motorcyk in your Member State and to invoke certain local laws against Motorcyk. The European Commission provides for an online dispute resolution platform.
                </p>

                <h4>10. Intellectual Property</h4>

                <p>
                    <b>Summary:</b> If you use Motorcyk's trademark, be sure to follow our Trademark Usage Guidelines. Also, don't infringe on anyone's intellectual property. If you believe your intellectual property is being infringed somewhere on the Motorcyk Platform, please follow the procedures in our Intellectual Property Dispute Policies.
                    <br/><br/>
                    <b>10.1 Intellectual Property of Motorcyk.</b> Motorcyk trademarks, logos, service marks, and service names are the intellectual property of Motorcyk. Our Trademark Usage Guidelines explain how you may and may not use them. Our Platform, including our material on the Platform, are also our or our licensors' intellectual property. Except as described in the Trademark Usage Guidelines, you agree not to use our intellectual property without our prior written consent.
                    <br/><br/>
                    <b>10.2 Intellectual Property of Others.</b> Motorcyk respects the intellectual property of others, and we expect our members to do the same. We may, in appropriate circumstances and in our discretion, remove or disable access to material that we believe may infringe on the intellectual property rights of others. We may also restrict or terminate access to our Platform to those who we believe to be repeat infringers. If you believe your intellectual property rights have been violated, please review our Intellectual Property Dispute Policies.
                </p>

                <h4>11. General Terms</h4>

                <p>
                    <b>Summary:</b> This section contains terms we have added for miscellaneous purposes. Please continue to read this section carefully.
                    <br/><br/>
                    <b>11.1 Translation.</b> This Agreement was written in English. It was then translated into other languages. If there is any inconsistency between the English version and a translated version, the English language version controls. 
                    <br/><br/>
                    <b>11.2 Notices.</b> Except as otherwise stated in this Agreement or as expressly required by law, any notice to us shall be given by certified postal mail to Motorcyk, Inc., Attn: Legal Department, 632 Broadway, 10th Floor, New York, NY 10012, or by email to hello@Motorcyk.com. Any notice to you shall be given to the most current email address in your account.
                    <br/><br/>
                    <b>11.3 Entire Agreement.</b> This Agreement, including the Usage and Content Policies, Groups and Events Policies, Organizer and Leadership Standards, Member Restrictions, Intellectual Property Policies, Trademark Usage Guidelines, and API License Terms, constitutes the entire agreement between you and Motorcyk, superseding any prior agreements between you and Motorcyk on such subject matter.
                    <br/><br/>
                    <b>11.4 No Agency.</b> No agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship between you and Motorcyk is intended or created by this Agreement. A member of the Motorcyk Platform is not Motorcyk's representative or agent, and may not enter into an agreement on Motorcyk's behalf.
                    <br/><br/>
                    <b>11.5 Governing Law.</b> This Agreement and the relationship between you and Motorcyk shall be governed by the laws of the State of New York without regard to its conflict of laws provisions, except as provided in Section 9.
                    <br/><br/>
                    <b>11.6 Judicial Forum.</b> If our agreement to arbitrate is found not to apply to you or your claim, or if you opt out of arbitration pursuant to Section 9.4, you and Motorcyk agree that any judicial proceedings (other than small claims actions) must be brought exclusively in the federal or state courts located in New York County, New York, and you and Motorcyk agree to venue and personal jurisdiction in those courts.
                    <br/><br/>
                    If you are a member based in the European Union, you may bring judicial proceedings against us arising from or in connection with this Agreement in your country of residence. In addition, for members based in the European Union, the European Commission provides for an online dispute resolution platform.
                    <br/><br/>
                    <b>11.7 Time for Filing.</b> Any claim not subject to arbitration must be commenced within one year after the date the party asserting the claim first knows or should know of the act, omission or default giving rise to the claim, or the shortest time period permitted by applicable law. This clause does not apply to you if you are based in the European Union.
                    <br/><br/>
                    <b>11.8 Assignment.</b> This Agreement is not assignable, transferable, or sublicensable by you except with Motorcyk's prior written consent, but may be assigned or transferred by us to our corporate parent or any affiliate or subsidiary, or in connection with a merger, acquisition, corporate reorganization, sale of all or substantially all of Motorcyk's assets, or similar transaction.
                    <br/><br/>
                    <b>11.9 No Waiver.</b> A party's failure to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision and does not waive any right to act with respect to subsequent or similar breaches.
                    <br/><br/>
                    <b>11.10 Severance.</b> If any provision of this Agreement is found to be invalid by a court of competent jurisdiction, you and Motorcyk nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and that the other provisions of this Agreement will remain in full force and effect.
                    <br/><br/>
                    <b>11.11 Thank you.</b> Please accept our wholehearted thanks for reading our Terms of Service.
                    <br/><br/>
                    <b>11.12 Termination.</b> If we terminate your account or access to our Platform, this Agreement terminates with respect to the member account that has been terminated, provided that the provisions listed in Section 11.13 will survive such termination.
                    <br/><br/>
                    <b>11.13 Survival.</b> Sections 3 (Fees, Payments, and Offers), 4.2 (Content License from You), 4.3 (Privacy), 6 (Release), 7 (Indemnification), 8 (Warranty Disclaimer and Limitation of Liability), 9 (Dispute Resolution), and 11 (General Terms) of this Agreement, and any other provisions necessary to give effect to these provisions, shall survive any termination or expiration of this Agreement.
                    <br/><br/>
                    <b>11.14 Titles.</b> The section titles in this Agreement are for convenience only and have no legal or contractual effect. The information in “Terms of Service Overview” section and in the “Tips” and “Summary” sections is also for convenience only and has no legal or contractual effect.
                    <br/><br/>
                    <b>11.15 Violations.</b> Please report any violations of this Agreement by a member or third party by sending an email to hello@Motorcyk.com.
                </p>
                <Link to="/signup" className="no-dec"><button className="terms-of-service-button">Back</button></Link>
            </div>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default TermsOfService;