import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Loading from '../components/LoadingPage'
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/EmailUpdatesMarketing.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';

class EmailUpdatesMarketing extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            redirect: false
        };
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( () => { this.setState({ isLoading: false }); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        const body = this.state.isLoading?<Loading />: 
        <div className="email-updates-marketing-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    <li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" src={messages} alt="" /></Link></li>
                    <li ><Link to="/profile/user" className="home-profile-link no-dec r"><img className="home-user-icon" src={user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages</Link></li>
                    <li ><Link to="/profile/user" className="home-profile-link no-dec b">Profile</Link></li>
                    <li><Link to="/home" className="home-home-link no-dec b">Home</Link></li>
                </ul>
            </div>
            <div className="email-updates-marketing-title-container">
                <h1 className="email-updates-marketing-title">Email Updates & Marketing</h1>
            </div>
            <div className="email-updates-marketing-section">
                <p className="email-updates-marketing-option">Messaging <Link to="/settings" className="no-dec r">ON</Link> | <Link to="/settings" className="no-dec b">OFF</Link></p>
                <p className="email-updates-marketing-option">Comments On Events <Link to="/settings" className="no-dec r">ON</Link> | <Link to="/settings" className="no-dec b">OFF</Link></p>
                <p className="email-updates-marketing-option">Direct Messages <Link to="/settings" className="no-dec r">ON</Link> | <Link to="/settings" className="no-dec b">OFF</Link></p>
                <p className="email-updates-marketing-option">New Follower <Link to="/settings" className="no-dec r">ON</Link> | <Link to="/settings" className="no-dec b">OFF</Link></p>
                <p className="email-updates-marketing-option">Follower Accepted <Link to="/settings" className="no-dec r">ON</Link> | <Link to="/settings" className="no-dec b">OFF</Link></p>
                <p className="email-updates-marketing-option">Marketing Opt-Out <Link to="/settings" className="no-dec r">ON</Link> | <Link to="/settings" className="no-dec b">OFF</Link></p>
                <Link to="/settings" className="no-dec"><button className="email-updates-marketing-button">Back</button></Link>
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default EmailUpdatesMarketing;