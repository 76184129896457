import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/HelpAndFeedback.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class HelpAndFeedback extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            redirect: false,
            ui: null,
            image_url: null,
            message_unread: false,
            type: 'Question',
            text: '',
            text_error: false,
            disabled: true,
            opacity: .5,
        };
        this.onChangeType = this.onChangeType.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id }); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        const config_sub = { 
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .then(()=> { this.setState({ isLoading: false }) })
            .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
    };

    onChangeType(e) {
        this.setState({ type: e.target.value })
    };
    onChangeText(e) {
        this.setState({ text: e.target.value })
        if (this.state.text.length >= 10) {
            this.setState({ disabled: false, opacity: 1, })
        } else {
            this.setState({ disabled: true, opacity: .5, })
        };
    };
    async onSubmit(e) {
        e.preventDefault();
        const data = {
            id: this.state.ui,
            type: this.state.type,
            text: this.state.text,
        };
        if (this.state.text.length === 0) {
            this.setState({ text_error: true, })
        } else {
            console.log(data, "data being set and sent")
            await axios.post(process.env.REACT_APP_SERVER_API + '/help/feedback', data) 
                .then(() => { alert(`${this.state.type} submitted, if we need to contact you, an admin will reach out to you directly, THANK YOU!😁`) })
                .then(()=> { window.location.reload() })
                .catch((err) => { console.log(err, "There was an error with submiting data!")})
        }
    };
    render() {
        if (this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>;
        let text_error = this.state.text_error === false ? '' : <p style={{color: "#FF2F46"}}><strong>Text box cannot be empty!</strong></p>
        const body = this.state.isLoading?<Loading />: 
        <div className="help-and-feedback-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to="/profile/user" className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}                
                </ul>
            </div>
            <div className="help-and-feedback-title-container">
                <h1 className="help-and-feedback-title" >Help & Feedback</h1>
                {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
            </div>
            <div className="help-and-feedback-section">
                <Link to="/settings" className="no-dec"><button className="help-and-feedback-button">Go Back</button></Link>
                <br/><br/>
                <form className="help-and-feedback-form" onSubmit={this.onSubmit}>
                    <p>If you need help, have questions, have feedback, account issues, payment issues, or feature opinions, please send us an email at <b>hello@motorcyk.com</b> or fill out the form below:</p>
                    <br/>
                    <label><b>Select type:</b></label>
                    <br/>
                    <select className="help-and-feedback-types" name="types" id="types" value={this.state.type} onChange={this.onChangeType}>
                        <option value="Question">Question</option>
                        <option value="Help">Help</option>
                        <option value="Feedback">Feedback</option>
                        <option value="Feature | Opinion">Feature | Opinion</option>
                        <option value="Report A User">Report A User</option>
                    </select>
                    <br/><br/>
                    {text_error}
                    <textarea className="help-and-feedback-textarea" placeholder="Type here..." value={this.state.text} onChange={this.onChangeText}></textarea>
                    <button className="help-and-feedback-button" type="submit" disabled={this.state.disabled} style={{opacity: this.state.opacity}}>Submit</button>
                </form>
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default HelpAndFeedback;