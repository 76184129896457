import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/DeleteAccount.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class DeleteAccount extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            redirect: false,
            ui: null,
            image_url: null,
            delete: "block",
            confirm_delete: "none",
            message_unread: false,
            password: '',
            password_error: '',
            password_required: '',
        };
        this.onChangePassword = this.onChangePassword.bind(this);
        this.delete = this.delete.bind(this);
        this.confirm_delete = this.confirm_delete.bind(this)
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id }); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data, isLoading: false, })})
            .catch((err) => {console.log(err, "my error..."); this.setState({ isLoading: false, })})
    };
    onChangePassword(event) { this.setState({ password: event.target.value }); };
    delete = () => { this.setState({ delete: "none", confirm_delete: "block"}); };
    confirm_delete = async (e) => {
        e.preventDefault();
        if (this.state.password.length === 0) { 
            this.setState({ password_required: "PASSWORD required!", password_error: ""  })
        } else {
            this.setState({ isLoading: true });
            const token = localStorage.getItem("token");
            const config = {
                headers: { Authorization: `Bearer ${token}`, },
                id: this.state.ui,
                password: this.state.password,
            }
            await axios.post(process.env.REACT_APP_SERVER_API + '/delete/account', config)
                .then(() => { alert("Your account has been deleted! Hope to have you another time, remember to always stay and ride safe! -Motorcyk") })
                .then (() => { window.location = '/'})
                .catch((err) => { 
                    console.log(err, "Error deleting account!");
                    this.setState({
                        password_error: err.response.data,
                        password_required: '',
                        isLoading: false,
                    });
                });
            this.setState({ isLoading: false });
        };
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let contents;
        contents =
            <div className="cancel-membership-section">
                <button className="cancel-membership-button" style={{display: this.state.delete, margin: "auto", marginTop: "20px"}} onClick={() => this.delete()}>DELETE ACCOUNT</button>
                <p style={{display: this.state.confirm_delete, margin: "auto", marginTop: "20px"}}>We're sad to see you go, but are you sure you want to delete account? Once you confirm, you will lose access to Motorcyk's features, any subscriptions currently active will be automatically cancelled! <br /><br />If you are sure, please enter your account password below to confirm.</p>
                <form style={{display: this.state.confirm_delete, margin: "auto", marginTop: "20px"}} onSubmit={this.confirm_delete}>
                    <p className="change-password-error-message r">{ this.state.password_error }</p>
                    <p className="change-password-error-message r">{ this.state.password_required }</p>
                    <input className="change-password-form-input" type="password" placeholder="Password" value={this.state.password} onChange={this.onChangePassword}/>
                    <h5 className="change-password-forgot-password-statement">We need your password to confirm account deletion. <Link to="/forgot-password" className="no-dec"><h5 className="change-password-forgot-password-link"><strong>Forgot Password?</strong></h5></Link></h5> 
                    <button className="cancel-membership-button" type="submit" style={{display: this.state.confirm_delete, margin: "auto", marginTop: "20px"}} >CONFIRM DELETION</button>
                </form>
                <Link to="/settings" className="no-dec"><button className="cancel-membership-button">Go Back</button></Link>
            </div>
        const body = this.state.isLoading?<Loading />:
        <div className="cancel-membership-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    <li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to="/profile/user" className="home-profile-link no-dec b">Profile</Link></li>
                    <li><Link to="/home" className="home-home-link no-dec b">Home</Link></li>
                </ul>
            </div>
            <div className="cancel-membership-title-container">
                <h1 className="cancel-membership-title" >Delete Account</h1>
            </div>
            { contents }
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default DeleteAccount;