import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import '../static/css/RiderPosts.css';
import Popup from 'reactjs-popup';
class RiderPosts extends Component {
    constructor(props) {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
        };
        this.posts = this.posts.bind(this);
    };
    posts() {
        const Post = (props) => (
            <div className="rider-posts-post no-dec">
                <Popup 
                    onClick={() => {this.opacityOn()}}                        
                    trigger={
                        <img src={props.post.image} alt="" className="button rider-posts-image"/>
                    } 
                    arrow={false} 
                    modal
                >
                    {close => (
                        <div className="modal">
                            <button className="close" onClick={() => close }>&times;</button>
                            <img src={props.post.image} alt="" className="rider-posts-image-test" />
                        </div>
                    )}
                </Popup>
            </div>
        );
        return this.props.data.posts.map(post => {
            return  <Post post={post} key={post.id} />
        });
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let posts;
        let view_more_posts;
        let statement;

        if (!this.props.data.guest) {
            if (this.props.data.posts.length === 0) {
                if (this.props.data.status.active === true) {
                    statement = this.props.data.isLoading ? '' : <p className="rider-posts-statement">{ this.props.data.display_name ? this.props.data.display_name : this.props.data.first_name } hasn't posted anything yet! <span role="img" aria-label="">😔</span></p>  
                } else {
                    if (this.props.data.rider_jar_enabled===true) {
                        statement = this.props.data.isLoading ? '' : <p className="rider-posts-statement">{ this.props.data.display_name ? this.props.data.display_name : this.props.data.first_name } hasn't posted anything yet! <span role="img" aria-label="">😔</span></p>  
                    } else {
                        statement = 
                            <div className="rider-posts-component-message-container">
                                <p className="rider-posts-statement">You must have an active membership to view rider posts!</p>
                                <Link to={{ pathname: this.props.data.trial_used===false?"/signup-plan-trial":"/signup-plan", }} className="no-dec"><button className="edit-profile-form-button back margin-up">{this.props.data.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link>       
                            </div>  
                    }
                }
            } else {
                posts = this.props.data.isLoading ? '' : this.posts();
            }
        } else {
            if (this.props.data.rider_jar_enabled===true) {
                if (this.props.data.posts.length === 0) {
                    statement = this.props.data.isLoading ? '' : <p className="rider-posts-statement">{ this.props.data.display_name ? this.props.data.display_name : this.props.data.first_name } hasn't posted anything yet! <span role="img" aria-label="">😔</span></p>  
                } else {
                    posts =  this.props.data.isLoading ? '' : this.posts();
                }
            } else {
                posts =  this.props.data.isLoading ? '' : this.posts();
                view_more_posts = 
                    <div className="rider-posts-signin-redirect">
                        <p className="rider-posts-statement">Please sign in to view more posts!</p>
                        <Link to={"/signin"} className="no-dec"><button className="rider-posts-signin-button">Sign In</button></Link>
                    </div>
            }
        }
        const body = this.props.data.isLoading?<Loading />:
        <div>
            { statement }
            <div className="rider-posts-post-container">
                { posts }
            </div>
            {view_more_posts}
        </div>
        return (
            <div className="rider-posts-container">
                { body }
            </div>
        );
    };
};
export default RiderPosts;