import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import '../static/css/CreateEvent.css';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
const google = window.google;
class CreateEvent extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            status: '',
            ui: null,
            isLoading: true,
            redirect: false,
            created: false,
            image_url: null,
            title: '',
            description: '',
            date: '',
            time: null,
            googleMapLink: '',
            address_info: '',
            formatted_address: '',
            invite_only: true,
            invite_only_color: 'white',
            invite_only_background: '#FF2F46',
            invite_only_font: 'bolder',
            anyone: false,
            anyone_color: 'black',
            anyone_background: 'white',
            anyone_font: 'normal',
            followers: false,
            followers_color: 'black',
            followers_background: 'white',
            followers_font: 'normal',
            share: false,
            share_color: 'black',
            share_background: 'white',
            share_font: 'normal',
            external_link: null,
            max: 'No Limit',
            invite_url: null,
            invitees: [], // this will be blank for now
            title_required: '',
            title_max: '',
            description_required: '',
            description_max: '',
            date_required: '',
            time_required: '',
            location_required: '',
            disabled: false,
            address_formatted: null,
            end: null,
            cancelled: false,
            message_unread: false,
            trial_used: false,
        };
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this);
        this.onChangeMax = this.onChangeMax.bind(this);
        this.onChangeInvitees = this.onChangeInvitee.bind(this);
        this.onChangeExternalLink = this.onChangeExternalLink.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.autocomplete = null;
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, }) })
            .catch(err => {
                this.setState({ redirect: true });
            });
        const config_sub = { 
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
                if (response.data.canceled===true&&response.data.canceled_access===false&&response.data.active_paid===false) {
                    window.location = `/profile/${this.state.ui}`;
                };
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .then(()=> { this.setState({ isLoading: false }) })
            .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
        this.autocomplete = await new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {})
        this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
    };
    onChangeTitle(event) {
        this.setState({
            title: event.target.value
        });
    };
    onChangeDescription(event) {
        this.setState({
            description: event.target.value
        });
    };
    onChangeDate(event) {
        this.setState({
            startDate: event.target.value
        });
    };
    onChangeTime(event) {
        this.setState({
            time: event.target.value
        });
    };
    onChangeExternalLink(event) {
        this.setState({
            external_link: event.target.value
        });
    };
    handlePlaceSelect() {
        let addressObject = this.autocomplete.getPlace();
        let address = addressObject.address_components;
        let formatted = addressObject.formatted_address;
        this.setState({
            address_info: address,
            address_formatted: formatted
        });
    };
    display = (name) => {
        this.setState({
            current_tab: name,
            clicked: true
        })
        if (name === 'invite_only') {
            this.setState({
                invite_only: true,
                invite_only_color: 'white',
                invite_only_background: '#FF2F46',
                invite_only_font: 'bolder',
                anyone: false,
                anyone_color: 'black',
                anyone_background: 'white',
                anyone_font: 'normal',
                followers: false,
                followers_color: 'black',
                followers_background: 'white',
                followers_font: 'normal',
                share: false,
                share_color: 'black',
                share_background: 'white',
                share_font: 'normal',
            });
        };
        if (name === 'anyone') {
            this.setState({
                anyone: true,
                anyone_color: 'white',
                anyone_background: '#FF2F46',
                anyone_font: 'bolder',
                invite_only: false,
                invite_only_color: 'black',
                invite_only_background: 'white',
                invite_only_font: 'normal',
                followers: false,
                followers_color: 'black',
                followers_background: 'white',
                followers_font: 'normal',
                share: false,
                share_color: 'black',
                share_background: 'white',
                share_font: 'normal',
            });
        };
        if (name === 'followers') {
            this.setState({
                followers: true,
                followers_color: 'white',
                followers_background: '#FF2F46',
                followers_font: 'bolder',
                invite_only: false,
                invite_only_color: 'black',
                invite_only_background: 'white',
                invite_only_font: 'normal',
                anyone: false,
                anyone_color: 'black',
                anyone_background: 'white',
                anyone_font: 'normal',
                share: false,
                share_color: 'black',
                share_background: 'white',
                share_font: 'normal',
            });
        };
        if (name === 'share') {
            this.setState({
                share: true,
                share_color: 'white',
                share_background: '#FF2F46',
                share_font: 'bolder',
                followers: false,
                followers_color: 'black',
                followers_background: 'white',
                followers_font: 'normal',
                invite_only: false,
                invite_only_color: 'black',
                invite_only_background: 'white',
                invite_only_font: 'normal',
                anyone: false,
                anyone_color: 'black',
                anyone_background: 'white',
                anyone_font: 'normal',
            });
        };
    };
    onChangeMax(event) {
        this.setState({
            max: event.target.value
        });
    };
    onChangeInvitee(event) {
        this.setState({
            invitees: event.target.value
        });
    };
    async onSubmit(event) {
        event.preventDefault();
        this.setState({ disabled: true, isLoading: true });
        const details = {
            title: this.state.title,
            description: this.state.description,
            date: this.state.date,
            time: this.state.time,
            address_info: this.state.address_info,
            address_formatted: this.state.address_formatted,
            invite_only: this.state.invite_only,
            anyone: this.state.anyone,
            followers: this.state.followers,
            share: this.state.share,
            external_link: this.state.external_link,
            max: this.state.max,
            ui: this.state.ui
        };
        if(this.state.signed_in) {
            details.time = moment(details.time).format('LT')
            await axios.post(process.env.REACT_APP_SERVER_API + '/create-event', details)
                .then(response => {
                    if (response.data.url === null) {
                        window.location = `/event/${response.data.event_id}`;    
                    } else {
                        window.location = `/event/${response.data.event_id}/${response.data.url}`;    
                    };
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                        title_required: err.response.data.title_required,
                        title_max: err.response.data.title_max,
                        description_required: err.response.data.description_required,
                        description_max: err.response.data.description_max,
                        date_required: err.response.data.date_required,
                        time_required: err.response.data.time_required,
                        location_required: err.response.data.location_required,
                        disabled: false,
                        isLoading: false,
                    });
                });
        } else {
            this.setState({ redirect: true });
        };
    };
    render() {
        if (this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let max;
        if (this.state.anyone === true || this.state.followers === true) {
           max =  
                <div>
                    { this.state.anyone === true ? <p style={{width: "80%", margin: "20px auto"}}>Any Motorcyk member will be able to join this event. Meetup location will be visible to any Motorcyk member.<br /><br /><span style={{fontSize: "10px"}}>*Always be aware of whom joins your events*</span></p> : ''}
                    { this.state.followers === true ? <p style={{width: "80%", margin: "20px auto"}}><strong><u>ONLY</u></strong> your Motorcyk followers will be able to join this event. Meetup location will only be visible to your Motorcyk followers.<br /><br /><span style={{fontSize: "10px"}}>*Always be aware of whom joins your events*</span></p> : ''}
                    <p className="create-event-options-switch-title"><strong><u>Max Allowed</u></strong></p>
                    <select className="create-event-select" value={this.state.max} onChange={this.onChangeMax}>
                        <option>No Limit</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>13</option>
                        <option>14</option>
                        <option>15</option>
                        <option>16</option>
                        <option>17</option>
                        <option>18</option>
                        <option>19</option>
                        <option>20</option>
                        <option>21</option>
                        <option>22</option>
                        <option>23</option>
                        <option>24</option>
                        <option>25</option>
                        <option>No Limit</option>
                    </select>
                </div>
        };
        let invite_message;
        if (this.state.invite_only === true) {
            invite_message =
                <div className="create-event-user-invitees-container">
                    <div className="create-event-user-invitees">
                        <p style={{width: "80%"}}>A shareable private link will be available once event is created in the details. Only those with the private link will be able to see the meetup location as well as join the event.</p>
                    </div>
                </div>
        };
        let share_message;
        if (this.state.share === true) {
            share_message =
                <div className="create-event-user-invitees-container">
                    <div className="create-event-user-invitees">
                        { this.state.share === true ? <p style={{width: "80%", margin: "20px auto"}}>Share an external event happening outside of the Motorcyk platform. Any Motorcyk member will be able to join this event and allow other Motorcyk members see who from the platform is attending. You can also share a link to this event to all your socials accounts.<br /><br /><input className="create-event-form-input-external-link" type="text" placeholder={"Link To Event"} value={this.state.external_link} onChange={this.onChangeExternalLink}/><span style={{fontSize: "10px"}}>(Include link to event if available.)</span></p> : ''}
                    </div>
                </div>
        };
        let content;

        if (this.state.status.active) {
            content = 
                <div className="create-event-search-itmes">
                    <form className="create-event-form" onSubmit={this.onSubmit}>
                        <p className="create-event-form-error-message">{ this.state.title_required }</p>
                        <p className="create-event-form-error-message">{ this.state.title_max }</p>
                        <input className="create-event-form-input" type="text" placeholder={"Title"} value={this.state.title} onChange={this.onChangeTitle}/> 
                        <p className="create-event-form-error-message">{ this.state.description_required }</p>
                        <p className="create-event-form-error-message">{ this.state.description_max }</p>
                        <textarea className="create-event-form-input text-box" placeholder={"Description..."} value={this.state.description} onChange={this.onChangeDescription}></textarea>
                        <p className="create-event-form-error-message">{ this.state.date_required }</p>
                        <div className="create-event-datepicker-container">
                            <DatePicker
                                className="create-event-form-input"
                                selected={this.state.date}
                                onChange={(date) => this.setState({date: date})}
                                minDate={moment().toDate()}
                                dateFormat="EEEE, MMMM d, yyyy"
                                popperPlacement="bottom"
                                placeholderText="Date"
                            />
                        </div>
                        <p className="create-event-form-error-message">{ this.state.time_required }</p>
                        <div className="create-event-datepicker-container">
                            <DatePicker
                                className="create-event-form-input"
                                selected={this.state.time}
                                onChange={(time) => this.setState({time: time})}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                popperPlacement="bottom"
                                placeholderText="Time"
                            />
                        </div>
                        <p className="create-event-form-error-message">{ this.state.location_required }</p>
                        <input id="autocomplete" className="create-event-form-input" ref="input" type="text"/>   
                        <p className="create-event-form-meet-location-hint">(Meetup Location)</p>
                        <div className="create-event-options">
                            <p className="create-event-options-switch-title"><strong><u>Who Can Join</u></strong></p>
                            <div className="create-event-switches">
                                <div className="create-event-switch">
                                    <p className="create-event-privacy-select" style={{ color: this.state.invite_only_color, backgroundColor: this.state.invite_only_background, fontWeight: this.state.invite_only_font}} onClick={() => this.display('invite_only')}>Invite Only</p> 
                                </div>
                                <div className="create-event-switch">
                                    <p className="create-event-privacy-select" style={{ color: this.state.anyone_color, backgroundColor: this.state.anyone_background, fontWeight: this.state.anyone_font}} onClick={() => this.display('anyone')}>Anyone</p>
                                </div>
                                <div className="create-event-switch">
                                    <p className="create-event-privacy-select" style={{ color: this.state.followers_color, backgroundColor: this.state.followers_background, fontWeight: this.state.followers_font}} onClick={() => this.display('followers')}>Followers</p>
                                </div>
                                <div className="create-event-switch">
                                    <p className="create-event-privacy-select" style={{ color: this.state.share_color, backgroundColor: this.state.share_background, fontWeight: this.state.share_font}} onClick={() => this.display('share')}>Share</p>
                                </div>
                            </div>
                            {max}
                            { invite_message }
                            { share_message }
                        </div>   
                        <br /> <br /> <br />
                        <button className="create-event-form-button" disabled={this.state.disabled ? true : false}>{this.state.disabled ? 'Loading...' : 'Create'}</button>
                    </form>       
                </div>  
        } else {
            content =
                <div className="create-event-search-itmes" style={{textAlign: "center"}}>
                    <p>You must have an active membership to host an event! Please activate your membership!</p>
                    <Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }} className="no-dec"><button className="create-event-form-button">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link><br/>
                    <Link to="/home" className="no-dec"><button className="create-event-form-button">Go Home</button></Link>
                </div>
        };
        const body = this.state.isLoading?<Loading />: 
        <div className="create-event-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/settings" className="home-settings-link no-dec b">Settings</Link></li>
                    <li><Link to="/explore" className="home-create-event-link no-dec b">Explore</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={"/profile/" + this.state.ui} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}
                </ul>
            </div>
            <div className="create-event-title-container">
                <h1 className="create-event-title" >Host</h1>
                {this.state.past_due?<p style={{fontSize: "15px",width: "90%", textAlign: "center", backgroundColor: "white", margin: "20px auto"}}>Your account is currently past due, please update payment method to avoid access interruption. <br/> <Link to={"/change-payment"} className="no-dec" ><button className="edit-profile-form-button">Update Payment</button></Link></p>:''}
                {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
            </div>
            { content } 
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default CreateEvent;