import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import '../static/css/AboutMotorcyk.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
import Instagram from '../static/images/images/instagram.svg';
import Facebook from '../static/images/images/facebook.svg';
import Twitter from '../static/images/images/twitter.svg';
import Pinterest from '../static/images/images/pinterest.svg';
import Youtube from '../static/images/images/youtube.svg';
// import Rumble from '../static/images/images/rumble.svg';
class AboutMotorcykRead extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
        };
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( () => { window.location = "/terms-of-service"; })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ isLoading: false, })
            });
    };
    render() {
        const body = this.state.isLoading?<Loading />:
        <div className="about-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    <li ><Link to="/signup" className="home-message-link-guest no-dec b">Sign Up</Link></li>
                    <li>|</li>
                    <li ><Link to="/signin" className="home-message-link-guest no-dec b">Sign in</Link></li> 
                </ul>
                <ul className="home-header-items no-dec td">
                    <li ><Link to="/signup" className="home-message-link-guest no-dec b">Sign Up</Link></li>
                    <li>|</li>
                    <li ><Link to="/signin" className="home-message-link-guest no-dec b">Sign in</Link></li>
                </ul>

            </div>
            <div className="about-section">
                <div className="about-title-container">
                    <h1 className="about-title">About Motorcyk</h1>
                    <h3>Welcome to Motorcyk, a platform connecting motorcyclists from all over the world.</h3>
                </div>
                <div className="about-content">
                    <h2 className="about-header">Our Vision</h2>
                    <p className="about-statement">To connect motorcylists from all around the world into one platform.</p>
                    <h2 className="about-header">Our Mission</h2>
                    <p className="about-statement">There are too many platforms out there mixed in with too many other communities making it difficult to make new connections, our mission is to have one platform where motorcylists can come together, network, grow, motivate those whom want to start, go out on rides, and sharing those experiences.</p>
                    <h2 className="about-header" style={{fontSize: 20}}>Follow Us And Share Your Adventures</h2>
                    <h3 className="about-follow-icons" >
                        <a href="https://www.instagram.com/motorcyk_/" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Instagram} alt=""/></a> 
                        <a href="https://www.facebook.com/motorcyk" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Facebook} alt=""/></a> 
                        <a href="https://twitter.com/motorcyk" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Twitter} alt=""/></a> 
                        {/* <a href="https://www.tiktok.com/@motorcyk?is_from_webapp=1&sender_device=pc" target="_blank"><img className="about-social-icon" src={TikTok} alt=""/></a>  */}
                        <a href="https://www.pinterest.com/motorcyk" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Pinterest} alt=""/></a> 
                        {/* <a href="https://www.reddit.com/user/motorcyk" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Reddit} alt=""/></a>  */}
                        {/* <a href="#" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Snapchat} alt=""/></a>  */}
                        {/* <a href="https://www.linkedin.com/company/motorcyk" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={LinkedIn} alt=""/></a>  */}
                        <a href="https://www.youtube.com/channel/UC74mB3afir3CvrF3NdDjc2w" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Youtube} alt=""/></a> 
                        {/* <a href="https://rumble.com/c/c-2134132" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Rumble} alt=""/></a>  */}
                        {/* <a href="https://www.tumblr.com/motorcyk" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Tumblr} alt=""/></a> */}
                    </h3>
                    <h4 className="about-tag">#LetsRideMotorcyk<br />#LetsRide<br />#Motorcyk</h4>
                    <Link to="/" className="no-dec"><button className="about-button">Back</button></Link>
                </div>
            </div>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default AboutMotorcykRead;