import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/ChangePassword.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class ChangePassword extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            ui: null,
            image_url: null,
            isLoading: true,
            redirect: false,
            current_password: '',
            current_password_error: null,
            current_password_required: null,
            new_password: '',
            new_password_required: null,
            new_password_min: null,
            new_password_special_character: null,
            new_password_number: null,
            confirm_new_password: '',
            confirm_new_password_required: null,
            disabled: false,
        };
        this.onChangeCurrentPassword = this.onChangeCurrentPassword.bind(this);
        this.onChangeNewPassword = this.onChangeNewPassword.bind(this);
        this.onChangeConfirmNewPassword = this.onChangeConfirmNewPassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, }); })
            .catch(err => {
                console.log(token, err, "whats my error")
                this.setState({ redirect: true });
            });
        await axios.get(process.env.REACT_APP_SERVER_API + `/user/provider/${this.state.ui}`)
            .then((response) => { this.setState({ provider: response.data.provider_id ? response.data.provider_id : false, }) })
            .catch(err => { console.log(err, "Error messge"); });
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .then(()=> { this.setState({ isLoading: false }) })
            .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
    };
    onChangeCurrentPassword(event) {
        this.setState({
            current_password: event.target.value
        });
    };
    onChangeNewPassword(event) {
        this.setState({
            new_password: event.target.value
        });
    };
    onChangeConfirmNewPassword(event) {
        this.setState({
            confirm_new_password: event.target.value
        });
    };
    async onSubmit(event) {
        event.preventDefault();
        this.setState({ disabled: true })
        const data = {
            ui: this.state.ui,
            current_password: this.state.current_password,
            new_password: this.state.new_password,
            confirm_new_password: this.state.confirm_new_password,
        }
        if (this.state.signed_in) {
            await axios.put(process.env.REACT_APP_SERVER_API + '/password/update', data)
                .then(() => {alert('Password updated! 🚀')})
                .then(() => window.location = '/settings')
                .catch((err) => {
                    console.log(err)
                    this.setState({
                        current_password_error: err.response.data.current_password_error,
                        current_password_required: err.response.data.current_password_required,
                        new_password_required: err.response.data.new_password_required,
                        new_password_min: err.response.data.new_password_min,
                        new_password_special_character: err.response.data.new_password_special_character,
                        new_password_number: err.response.data.new_password_number,
                        confirm_new_password_required: err.response.data.confirm_new_password_required,
                        confirm_new_password_match: err.response.data.confirm_new_password_match,
                        disabled: false
                    });
            })
        } else {
            window.location = "/signin"
        };
    };
    render() {
        if (this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let form;
        if (!this.state.provider) {
            form =
                <form onSubmit={this.onSubmit}>
                    <p className="change-password-error-message r">{ this.state.current_password_error }</p>
                    <p className="change-password-error-message r">{ this.state.current_password_required }</p>
                    <input className="change-password-form-input" type="password" placeholder="Current Password" value={this.state.current_password} onChange={this.onChangeCurrentPassword}/>
                    <h5 className="change-password-forgot-password-statement">We need your current Password to confirm changes. <Link to="/forgot-password" className="no-dec"><h5 className="change-password-forgot-password-link"><strong>Forgot Password?</strong></h5></Link></h5> 

                    <p className="change-password-error-message r">{ this.state.new_password_required }</p>
                    <p className="change-password-error-message r">{ this.state.new_password_min }</p>
                    <p className="change-password-error-message r">{ this.state.new_password_number }</p>
                    <p className="change-password-error-message r">{ this.state.new_password_special_character }</p>
                    <p className="change-password-error-message r">{ this.state.confirm_new_password_match }</p>
                    <input className="change-password-form-input" type="password" placeholder="New Password" value={this.state.new_password} onChange={this.onChangeNewPassword}/>

                    <p className="change-password-error-message r">{ this.state.confirm_new_password_required }</p>
                    <p className="change-password-error-message r">{ this.state.confirm_new_password_match }</p>
                    <input className="change-password-form-input" type="password" placeholder="Confirm New Password" value={this.state.confirm_new_password} onChange={this.onChangeConfirmNewPassword}/>
                    <button className="change-password-form-button" disabled={this.state.disabled}>{this.state.disabled ? "Updating..." : "Update"}</button><br />
                    <Link to="/settings" className="no-dec"><button className="change-password-form-button">Cancel</button></Link>
                </form>
        } else {
            form =
                <div>
                    <h4>You cannot change password since you are currently registered through Google's Single Sign On, if you would like to set your own password, you can do so by changing your email first <Link to="/change-email" className="no-dec r">here</Link>. <br/><br/>NOTE: Google sign on will no longer be availalble if you set a password!</h4>
                    <Link to="/settings" className="no-dec"><button className="change-password-form-button">Cancel</button></Link>
                </div>
        };
        const body = this.state.isLoading?<Loading />: 
        <div className="change-password-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    <li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    <li><Link to="/home" className="home-home-link no-dec b">Home</Link></li>
                </ul>
            </div>
            <div className="change-password-title-container">
                <h1 className="change-password-title">Change Password</h1>
            </div>
            <div className="change-password-section">
                {form}
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default ChangePassword;