import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import '../static/css/NewMessage.css';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class NewMessage extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            redirect: false,
            status: '',
            ui: null,
            image_url: null,
            name: null,
            q: "",
            search: [],
            opacity: 0.5,
            cursor: "default",
            disabled: true,
            zero: false,
            message_unread: false,
            active: false,
            end: null,
            cancelled: false,
        };
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id }); })
            .catch(err => { console.log(err, "You are not logged in or something went wrong with fetching data!"); this.setState({ redirect: true }); });
        const config_sub = { 
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .then(()=> { this.setState({ isLoading: false }) })
            .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
    };
    setQuery(e) {
        this.setState({
            q: e.target.value,
            search: this.state.search,
            opacity: 1, 
            cursor: "pointer",
            disabled: false
        });
    };
    async runSearch(e) {
        e.preventDefault();
        this.setState({isSearching: true, disabled: true})
        const q = this.state.q
        const id = {id: this.state.ui}
        await axios.post(process.env.REACT_APP_SERVER_API + `/new-message/riders/search?q=${q}`, id)
            .then((response) => { this.setState({ search: response.data }); })
            .then (() => {
                if (this.state.search.length === 0) {
                    this.setState({
                        q: "",
                        zero: true,
                        isSearching: false,
                        disabled: false
                    })
                } else {
                    this.setState({
                        q: "",
                        zero: false,
                        isSearching: false,
                        disabled: false
                    })
                };
            })
            .catch(err => {console.log(err, "Error with retrieving search!")})
    };
    riders () {
        const Rider = (props) => (
            <div className="new-message-profile-container-user">
                <Link className="new-message-user-icon-link b" to={{ pathname: `/message`, state: {rider: props.rider.id, rider_dn: props.rider.display_name, rider_fn: props.rider.first_name} }}>
                    <img className="new-message-user-icon" src={props.rider.image_url ? props.rider.image_url : user} alt="" />
                    <p className="new-message-text-name-user r">{props.rider.display_name ? props.rider.display_name : props.rider.first_name}</p>
                </Link>
            </div>
        );
        return this.state.search.map(i => {
            return <Rider rider={i} key={i.id} />
        });
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>;
        // let image;
        // if (this.state.image_url !== null) image = this.state.isLoading ? '' : <div className="edit-profile-image-contianer-s3"><img className="edit-profile-image-image-s3" src={this.state.image_url} alt=""/></div>;
        // else image = this.state.isLoading ? '' : <img className="edit-profile-image-image" src={user} alt="" />;
        let riders;
        let searching;
        let zero;
        if (this.state.search.length > 0) riders = this.state.isSearching ? '' : this.riders();
        else riders = '';
        if (this.state.isSearching === true) searching = <h5 className="new-message-zero-found">Searching...</h5>;
        else searching = '';
        if (this.state.zero === true) zero = <h5 className="new-message-zero-found">No riders found, please try another search...</h5>;
        else zero = '';
        const body = this.state.isLoading?<Loading />: 
        <div className="new-message-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/settings" className="home-settings-link no-dec b">Settings</Link></li>
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}                  </ul>
            </div>
            <h1 className="new-message-title r">New Message</h1>
            <div className="new-message-user-icons-container">
                <form onSubmit={e => {this.runSearch(e)}}>
                    <input className="new-message-form-input" type="text" placeholder="Search rider..." name="q" value={this.state.q} onChange={e => {this.setQuery(e)}}/>
                    <button className="new-message-form-button" type="submit" disabled={this.state.disabled} style={{opacity:this.state.opacity, cursor:this.state.cursor}}>Search</button>
                </form>
                { searching }
                { zero }
                { riders }
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default NewMessage;