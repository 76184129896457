import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Geocode from "react-geocode";
import '../static/css/EventDetails.css';
import ComingSoon from '../static/images/images/coming_soon.gif';
import send from '../static/images/images/paper-plane-solid.svg';
import user_icon from '../static/images/images/user-circle-solid.svg';
import messages_icon from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
import ScrollToBottom from 'react-scroll-to-bottom';
import Linkify from 'react-linkify';
import io from 'socket.io-client';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
Geocode.setApiKey(process.env.REACT_APP_MAPBOX_API);
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");
const socket = io.connect(process.env.REACT_APP_SERVER_API + '/', { transports : ['websocket', 'polling', 'flashsocket'] });
class EventDetails extends Component {
    constructor(props) {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            guest: false,
            active: false,
            status: '',
            image_url: null,
            isLoading: true,
            redirect: false,
            event_id: props.match.params.id,
            details: [],
            event_error: false,
            ui: null,
            deleted: false,
            saved: false,
            joined: false,
            joined_count: 0,
            users_going: [],
            full: false,
            current_user_attending: false,
            key: null,
            param_key: props.match.params.key ? props.match.params.key : null,
            path: props.match.path,
            copied: null,
            message_unread: false,
            end: null,
            cancelled: false,
            private: true,
            followers: true,
            share: false,
            external_link: null,
            link_http: "https://",
            is_following: true,
            map_display: 'block',
            lng: null,
            lat: null,
            zoom: 17,
            geo: null,
            room: null,
            name: null,
            message: "",
            eventMessageList: [],
            trial_used: false,
            non_active: false,
            guest_details: ''
        };
        this.mapContainer = React.createRef();
        this.delete = this.delete.bind(this);
        this.join = this.join.bind(this);
        this.leave = this.leave.bind(this);
        this.users_going = this.users_going.bind(this);
        this.copy = this.copy.bind(this);
        this.onChangeMessage = this.onChangeMessage.bind(this);
        this.sendEventMessage = this.sendEventMessage.bind(this);
        this.eventMessages = this.eventMessages.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, ContentType: "application/json" } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => {
                this.setState({ ui: response.data.id, test: document.getElementById('event-map'), name: response.data.display_name ? response.data.display_name : response.data.first_name, trial_used: response.data.trial_used===0?false:true, })
                return response.data;
            })
            .then(async (data) => {
                const config_sub = {
                    headers: { Authorization: `Bearer ${token}`, }, 
                    id: data.id,
                };
                await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
                    .then(async (response) => { 
                        this.setState({ status: response.data, }); 

                        if (this.state.status.active === true) {
                            socket.on("recieve_message", (data) => { this.setState({ eventMessageList: [...this.state.eventMessageList, data] }); });
                            await axios.post(process.env.REACT_APP_SERVER_API + `/event/${this.state.event_id}/${data.id}`, config)
                                .then( async (response) => { 
                                    await Geocode.fromAddress(response.data.details[0][0].street_address +", "+ response.data.details[0][0].city +", "+ response.data.details[0][0].state +", "+ response.data.details[0][0].zip_code)
                                        .then((response) => {
                                            const { lat, lng } = response.results[0].geometry.location;
                                            this.setState({ geo: [lng, lat], });
                                        }, (error) => { console.error(error); } );
                                    if (response.data.details[0][0].url_secret && this.state.path === "/event/:id/:key") {
                                        if (response.data.details[0][0].url_secret !== this.state.param_key) {
                                            window.location = "/explore"
                                        };
                                        this.setState({ 
                                            details: response.data.details[0][0], 
                                            key: response.data.details[0][0].url_secret,
                                            saved: response.data.details[0][0].saved,
                                            joined: response.data.details[0][0].joined, 
                                            joined_count: response.data.details[0][0].joined_count, 
                                            users_going: response.data.details[0][0].users_going,
                                            room: response.data.details[0][0].room_id,
                                            full: response.data.details[0][0].max === response.data.details[0][0].joined_count ? true : false,
                                        }); 
                                        socket.emit("join_room", this.state.room);
                                        this.state.users_going.forEach(user => { if (user.id === this.state.ui)  this.setState({ current_user_attending: true, })})
                                        if (response.data.details[0][0].joined_count === parseInt(response.data.details[0][0].max)) this.setState({ full: true, });
                                        if (this.state.full === false && response.data.details[0][0].url_secret === this.state.param_key) {
                                            const map = new mapboxgl.Map({
                                                container: this.mapContainer.current,
                                                style: 'mapbox://styles/mapbox/streets-v11',
                                                center: this.state.geo,
                                                zoom: this.state.zoom
                                            });
                                            new mapboxgl.Marker({color:"#FF2F46"}).setLngLat(this.state.geo).addTo(map)
                                            map.addControl(new mapboxgl.NavigationControl(), "top-right");
                                        } else {
                                            this.setState({ map_display: 'none'})
                                        }
                                    } else if (this.state.path === "/event/:id") {
                                        this.setState({ 
                                            details: response.data.details[0][0], 
                                            key: response.data.details[0][0].url_secret,
                                            saved: response.data.details[0][0].saved,
                                            joined: response.data.details[0][0].joined, 
                                            joined_count: response.data.details[0][0].joined_count, 
                                            users_going: response.data.details[0][0].users_going,
                                            private: response.data.details[0][0].invite_only === 1 ? true : false,
                                            followers: response.data.details[0][0].followers === 1 ? true : false,
                                            share: response.data.details[0][0].share === 1 ? true : false,
                                            external_link: response.data.details[0][0].external_link!==null?response.data.details[0][0].external_link.includes(this.state.link_http)?response.data.details[0][0].external_link:"http://"+response.data.details[0][0].external_link:null,
                                            is_following: response.data.details[0][0].is_following === true ? true : false,
                                            room: response.data.details[0][0].room_id,
                                            full: response.data.details[0][0].share === 1 ? false : (response.data.details[0][0].max === response.data.details[0][0].joined_count ? true : false),
                                        }); 
                                        if (response.data.details[0][0].share===1) this.setState({full: false})
                                        socket.emit("join_room", this.state.room);
                                        this.state.users_going.forEach(user => { if (user.id === this.state.ui)  this.setState({ current_user_attending: true, })})
                                        if (response.data.details[0][0].joined_count === parseInt(response.data.details[0][0].max)) this.setState({ full: true, })
                                        if (this.state.full === false && response.data.details[0][0].followers === 1 && response.data.details[0][0].is_following === true) {
                                            this.setState({ map_display: 'block'});
                                            const map = new mapboxgl.Map({
                                                container: this.mapContainer.current,
                                                style: 'mapbox://styles/mapbox/streets-v11',
                                                center: this.state.geo,
                                                zoom: this.state.zoom
                                            });
                                            new mapboxgl.Marker({color:"#FF2F46"}).setLngLat(this.state.geo).addTo(map)
                                            map.addControl(new mapboxgl.NavigationControl(), "top-right");
                                        } else if (this.state.full === false && response.data.details[0][0].followers === 1 && response.data.details[0][0].is_following === false) {
                                            this.setState({ map_display: 'none', });
                                        };
                                        if (this.state.full === false && response.data.details[0][0].anyone === 1) {
                                            this.setState({ map_display: 'block'});
                                            const map = new mapboxgl.Map({
                                                container: this.mapContainer.current,
                                                style: 'mapbox://styles/mapbox/streets-v11',
                                                center: this.state.geo,
                                                zoom: this.state.zoom,
                                            });
                                            new mapboxgl.Marker({color:"#FF2F46"}).setLngLat(this.state.geo).addTo(map)
                                            map.addControl(new mapboxgl.NavigationControl(), "top-right");
                                        } 
                                        if (response.data.details[0][0].share === 1) {
                                            this.setState({ map_display: 'block'});
                                            const map = new mapboxgl.Map({
                                                container: this.mapContainer.current,
                                                style: 'mapbox://styles/mapbox/streets-v11',
                                                center: this.state.geo,
                                                zoom: this.state.zoom,
                                            });
                                            new mapboxgl.Marker({color:"#FF2F46"}).setLngLat(this.state.geo).addTo(map)
                                            map.addControl(new mapboxgl.NavigationControl(), "top-right");
                                        } 
                                        if (response.data.details[0][0].invite_only === 1 && this.state.param_key === null && response.data.details[0][0].joined === false) {
                                            this.setState({ map_display: 'none', });
                                        } else if (response.data.details[0][0].joined === true) {
                                            this.setState({ map_display: 'block'});
                                            const map = new mapboxgl.Map({
                                                container: this.mapContainer.current,
                                                style: 'mapbox://styles/mapbox/streets-v11',
                                                center: this.state.geo,
                                                zoom: this.state.zoom,
                                            });
                                            new mapboxgl.Marker({color:"#FF2F46"}).setLngLat(this.state.geo).addTo(map)
                                            map.addControl(new mapboxgl.NavigationControl(), "top-right");
                                        }
                                        if (response.data.details[0][0].user_id === data.id) {
                                            this.setState({ map_display: 'block'});
                                            const map = new mapboxgl.Map({
                                                container: this.mapContainer.current,
                                                style: 'mapbox://styles/mapbox/streets-v11',
                                                center: this.state.geo,
                                                zoom: this.state.zoom,
                                            });
                                            new mapboxgl.Marker({color:"#FF2F46"}).setLngLat(this.state.geo).addTo(map)
                                            map.addControl(new mapboxgl.NavigationControl(), "top-right");
                                        };
                                        if(this.state.full===true) {
                                            this.setState({ map_display: 'none', });
                                        }
                                    } else {
                                            this.setState({ event_error: true })
                                    }
                                })
                                .catch( (err) => {
                                    console.log(err)
                                    alert("ERROR: Event does not existt!");
                                    window.location = "/home"
                                    this.setState({event_error: true});
                                })
                            const id = this.state.room;
                            await axios.get(process.env.REACT_APP_SERVER_API + `/messages/${id}`)
                                .then((response) => {
                                    this.setState({ 
                                        eventMessageList: [...this.state.eventMessageList, ...response.data],
                                    });
                                })
                                .then( () => { socket.emit("join_room", this.state.room); })
                                .catch(err => { console.log(err, "Error retrieving messages!") });
                        }  
                    })
                    .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
                return data;
            })
            .then(async (data) => {
                await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${data.id}`)
                    .then((response) => { this.setState({ message_unread: response.data.status }); })
                    .catch((err) => { console.log(err, "Error checking for message status!") })
                return data;
            })
            .then(async (data) => {
                await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${data.id}`)
                    .then((response) => { this.setState({ image_url: response.data })})
                    .then(()=> { this.setState({ isLoading: false }) })
                    .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
            })
            .catch( async () => {
                this.setState({ guest: true });
                await axios.get(process.env.REACT_APP_SERVER_API + `/event/${this.state.event_id}/guest`)
                    .then((res)=>{ this.setState({ guest_details: res.data[0], }); })
                await axios.get(process.env.REACT_APP_SERVER_API + `/event/${this.state.event_id}/external-motorcycle-event`)
                    .then(async (response) => {
                        if (response.data.details[0][0].share===1) {
                            this.setState({ 
                                details: response.data.details[0][0], 
                                joined_count: response.data.details[0][0].joined_count, 
                                users_going: response.data.details[0][0].users_going,
                                private: response.data.details[0][0].invite_only === 1 ? true : false,
                                followers: response.data.details[0][0].followers === 1 ? true : false,
                                share: response.data.details[0][0].share === 1 ? true : false,
                                external_link: response.data.details[0][0].external_link.includes(this.state.link_http)?response.data.details[0][0].external_link:"http://"+response.data.details[0][0].external_link,
                                map_display: 'block',
                            }); 

                        }
                        await Geocode.fromAddress(response.data.details[0][0].street_address +", "+ response.data.details[0][0].city +", "+ response.data.details[0][0].state +", "+ response.data.details[0][0].zip_code).then(
                            (response) => {
                              const { lat, lng } = response.results[0].geometry.location;
                              this.setState({ 
                                    geo: [lng, lat],
                                });
                            },
                            (error) => {
                              console.error(error);
                            }
                        );
                        const map = new mapboxgl.Map({
                            container: this.mapContainer.current,
                            style: 'mapbox://styles/mapbox/streets-v11',
                            center: this.state.geo,
                            zoom: this.state.zoom,
                        });
                        new mapboxgl.Marker({color:"#FF2F46"}).setLngLat(this.state.geo).addTo(map)
                        map.addControl(new mapboxgl.NavigationControl(), "top-right");
                    })
                    .catch(() => {})
                this.setState({ isLoading: false, });
            });
    };
    onChangeMessage(event) { this.setState({ message: event.target.value }); };
    async sendEventMessage(event) {
        event.preventDefault();
        const date = new Date();
        const messageContent = {
            id: this.state.ui,
            event_id: this.state.event_id,
            room: this.state.room,
            content: {
                name: this.state.name,
                message: this.state.message,
                date: date.toDateString(),
                time: date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}),
                user_id: this.state.ui,
            }
        };
        await socket.emit("send_message", messageContent);
        await axios.post(process.env.REACT_APP_SERVER_API + '/event/message', messageContent)
            .then((response) => { console.log(response) })
            .catch(err => { console.log(err, "Error logging message!") });
        this.setState({ 
            message: "", 
            eventMessageList: [...this.state.eventMessageList, messageContent.content] 
        });
    }
    eventMessages() {
        const date = new Date();
        const Message = (props) => (
            <div>
                <h6 className="message-date-time" style={props.message.user_id === this.state.ui ? {color: "#FF2F46"} : {color: "black"}}><img style={{marginBottom: "-5px"}} src={ props.message.image_url ?  props.message.image_url : user_icon } alt="" /> { props.message.display_name || props.message.name ? props.message.display_name || props.message.name : props.message.first_name || props.message.name} | {props.message.date === date.toDateString() ? 'Today' : props.message.date} @ {props.message.time}</h6>
                <p className="message-text" style={props.message.user_id === this.state.ui ? {color: "#FF2F46"} : {color: "black"}}><Linkify>{props.message.message}</Linkify></p>
            </div>
        );
        return this.state.eventMessageList.map(message => {
            return  <Message message={message} key={message.id} />
        });
    };
    async unsave(e, event_id) {
        e.preventDefault();
        this.setState({ isLoading: true });
        const id = event_id;
        const user = this.state.ui;
        if (this.state.signed_in) {
            await axios.delete(process.env.REACT_APP_SERVER_API + `/event/unsave/${id}/${user}`)
                .then(() => alert('🛑 You are no longer following this event! 🛑'))
                .then(() => this.setState({ saved: false,  }))
                .then(() => window.location.reload() )
                .catch((err) => console.log(err, "Error leaving event!"))
        } else {
            window.location = '/signin';
        };
    };
    async save(e, event_id) {
        e.preventDefault();
        this.setState({ isLoading: true });
        const data = {
            id: event_id,
            user: this.state.ui,
        };
        if (this.state.signed_in) {
            await axios.post(process.env.REACT_APP_SERVER_API + `/event/save`, data)
                .then(() => alert('Event saved! 👀 🏍 🚀'))
                .then(() => this.setState({ saved: true, isLoading: false, }))
                .then(() => window.location.reload(true) )
                .catch((err) => {console.log(err, "Error joining event!"); window.location.reload(true)})
        } else {
            window.location = '/signin';
        };
    };
    async delete(e) {
        e.preventDefault();
        const ei = this.state.details.id;
        const ui = this.state.ui;
        if (this.state.signed_in) {
            await axios.delete(process.env.REACT_APP_SERVER_API + `/event/delete/${ei}/${ui}`)
                .then(() => this.setState({ isLoading: true }))
                .then(() => alert("Event has been cancelled!"))
                .then(() => window.location='/home')
                .catch((err) => console.log(err))
        } else {
            this.setState({redirect: true});
        };
    };
    async join(e, event_id) {
        e.preventDefault();
        this.setState({ isLoading: true });
        const data = {
            id: event_id,
            user: this.state.ui,
            key: this.state.key,
        };
        if (this.state.signed_in) {
            await axios.post(process.env.REACT_APP_SERVER_API + `/event/join`, data)
                .then(() => alert('Joined! 👊 😎 🏍 🚀'))
                .then(() => this.setState({ joined: true, joined_count: this.state.joined_count + 1 }))
                .then(() => window.location.reload() )
                .catch((err) => {console.log(err, "Error joining event!"); window.location.reload()})
        } else {
            window.location = '/signin';
        };
    };
    async leave(e, event_id) {
        e.preventDefault();
        this.setState({ isLoading: true });
        const id = event_id;
        const user = this.state.ui;
        if (this.state.signed_in) {
            await axios.delete(process.env.REACT_APP_SERVER_API + `/event/leave/${id}/${user}`)
                .then(() => alert('🛑 You are no longer attending event! 🛑'))
                .then(() => this.setState({ joined: false, joined_count: this.state.joined_count - 1 }))
                .then(() => window.location.reload(true) )
                .catch((err) => console.log(err, "Error leaving event!"))
        } else {
            window.location = '/signin';
        };
    };
    copy(e) {
        e.preventDefault();
        if (this.state.key) {
            const link = `https://www.motorcyk.com/event/${this.state.event_id}/${this.state.key}`;
            navigator.clipboard.writeText(link);
            this.setState({ copied: "Private event link copied successfully!"})
        } else {
            const link = "https://www.motorcyk.com/event/" + this.state.event_id;
            navigator.clipboard.writeText(link);
            this.setState({ copied: "Private event link copied successfully!" })
        };
    };
    users_going() {
        const User = (props) => (
            props.user.display_name==="Motorcyk"?"Motorcyk":<Link to={props.user.display_name?`/@${props.user.display_name}`:`/rider/${props.user.ui}`} className="no-dec event-details-user-going">{props.user.display_name ? props.user.display_name : props.user.first_name}</Link>
        );
        return this.state.users_going.map(user => {
            return  <User user={user} key={user.id} />
        });
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        if(this.state.deleted === true) return <Redirect to = {{pathname: "/home"}}/>
        let follow_option;
        let join_option;
        let event_date_time;
        let address;
        let messages;
        messages = this.state.eventMessageList.length > 0 ? this.eventMessages() : <p className="no-message-text">No messages...</p>;
        if (this.state.saved === true) {
            follow_option =
                <form onSubmit={(e) => {this.unsave(e, this.state.details.id)}}>
                    <button className="eventdetails-form-button">Unfollow Event</button>
                </form>
        } else {
            follow_option =
                <form onSubmit={(e) => {this.save(e, this.state.details.id)}}>
                    <button className="eventdetails-form-button">Follow Event</button>
                </form>
        }
        if (this.state.joined === true) {
            // event_date_time = <p className="no-pointer">{this.state.details.formatted_date} {(this.state.full && !this.state.current_user_attending) || (!this.state.key && this.state.ui !== this.state.details.user_id) ? '' : '@'} {(this.state.full && !this.state.current_user_attending) || (!this.state.key && this.state.ui !== this.state.details.user_id) ? '' : this.state.details.time}</p>;
            event_date_time = (this.state.full===true && this.state.joined===false)?<p className="no-pointer">{this.state.details.formatted_date}</p>:<p className="no-pointer">{this.state.details.formatted_date} @ {this.state.details.time}</p>;
            address = <p className="eventdetails-address no-dec b"><strong><a className="no-dec b hover" href={`http://maps.google.com/?q=1200 ${this.state.details.street_address}, ${this.state.details.city}, ${this.state.details.state}, ${this.state.details.zip_code}`} target="_blank" rel="noopener noreferrer">{this.state.isLoading ? '' : this.state.details.street_address}, {this.state.isLoading ? '' : this.state.details.city}, {this.state.isLoading ? '' : this.state.details.state} {this.state.isLoading ? '' : this.state.details.zip_code}</a></strong></p>;
            join_option = 
                <form onSubmit={(e) => {this.leave(e, this.state.details.id)}}>
                    <button className="eventdetails-form-button">Leave</button>
                </form>
        } else {
            if (this.state.details.anyone) {
                event_date_time = this.state.full?<p className="no-pointer">{this.state.details.formatted_date}</p>:<p className="no-pointer">{this.state.details.formatted_date} @ {this.state.details.time}</p>;
                address = this.state.full?'':<p className="eventdetails-address no-dec b"><strong><a className="no-dec b hover" href={`http://maps.google.com/?q=1200 ${this.state.details.street_address}, ${this.state.details.city}, ${this.state.details.state}, ${this.state.details.zip_code}`} target="_blank" rel="noopener noreferrer">{this.state.isLoading ? '' : this.state.details.street_address}, {this.state.isLoading ? '' : this.state.details.city}, {this.state.isLoading ? '' : this.state.details.state} {this.state.isLoading ? '' : this.state.details.zip_code}</a></strong></p>;
                join_option = this.state.full?
                    <p className="r">Event is currently full, check back later to see if a rider decides to leave!</p>:
                    <form onSubmit={(e) => {this.join(e, this.state.details.id)}}>
                        <button className="eventdetails-form-button">Join</button>
                    </form>
            } 
            if (this.state.private) {
                if (this.state.full === false && this.state.param_key === this.state.details.url_secret && this.state.param_key !== null) {
                    event_date_time = <p className="no-pointer">{this.state.details.formatted_date} @ {this.state.details.time}</p>;
                    address = <p className="eventdetails-address no-dec b"><strong><a className="no-dec b hover" href={`http://maps.google.com/?q=1200 ${this.state.details.street_address}, ${this.state.details.city}, ${this.state.details.state}, ${this.state.details.zip_code}`} target="_blank" rel="noopener noreferrer">{this.state.isLoading ? '' : this.state.details.street_address}, {this.state.isLoading ? '' : this.state.details.city}, {this.state.isLoading ? '' : this.state.details.state} {this.state.isLoading ? '' : this.state.details.zip_code}</a></strong></p>;
                    join_option = 
                        <form onSubmit={(e) => {this.join(e, this.state.details.id)}}>
                            <button className="eventdetails-form-button">Join</button>
                        </form>
                } else {
                    if (this.state.full === true) {
                        event_date_time = <p className="no-pointer">{this.state.details.formatted_date} @ {this.state.details.time}</p>
                        address = <p className="eventdetails-address no-dec b">{this.state.isLoading ? '' : this.state.details.city}, {this.state.isLoading ? '' : this.state.details.state}</p>;
                        join_option = <p className="r">Event is currently full!</p>
                    } else {
                        event_date_time = <p className="no-pointer">{this.state.details.formatted_date} @ {this.state.details.time}</p>
                        address = <p className="eventdetails-address no-dec b">{this.state.isLoading ? '' : this.state.details.city}, {this.state.isLoading ? '' : this.state.details.state}</p>;
                        join_option = <p className="r">This event is private, and can only be joined if invited by the host!</p>
                    };
                };
            };
            if (this.state.followers) {
                if (this.state.full === false && this.state.is_following) {
                    event_date_time = <p className="no-pointer">{this.state.details.formatted_date} @ {this.state.details.time}</p>;
                    address = <p className="eventdetails-address no-dec b"><strong><a className="no-dec b hover" href={`http://maps.google.com/?q=1200 ${this.state.details.street_address}, ${this.state.details.city}, ${this.state.details.state}, ${this.state.details.zip_code}`} target="_blank" rel="noopener noreferrer">{this.state.isLoading ? '' : this.state.details.street_address}, {this.state.isLoading ? '' : this.state.details.city}, {this.state.isLoading ? '' : this.state.details.state} {this.state.isLoading ? '' : this.state.details.zip_code}</a></strong></p>;
                    join_option = 
                        <form onSubmit={(e) => {this.join(e, this.state.details.id)}}>
                            <button className="eventdetails-form-button">Join</button>
                        </form>
                } else {
                    if (this.state.full === true) {
                        event_date_time = ''
                        address ='';
                        join_option = <p className="r">Event is currently full!</p>
                    } else {
                        event_date_time = ''
                        address ='';
                        join_option = <p className="r">Event is only available to the host's followers. You must follow <Link  to={this.state.details.display_name?`/@${this.state.details.display_name}`:`/rider/${this.state.details.user_id}`} className="no-dec r">{this.state.details.display_name ? this.state.details.display_name : this.state.details.first_name}</Link> to join!</p>
                    };
                };
            };
            if (this.state.details.share) {
                event_date_time = this.state.full?<p className="no-pointer">{this.state.details.formatted_date}</p>:<p className="no-pointer">{this.state.details.formatted_date} @ {this.state.details.time}</p>;
                address = this.state.full?'':<p className="eventdetails-address no-dec b"><strong><a className="no-dec b hover" href={`http://maps.google.com/?q=1200 ${this.state.details.street_address}, ${this.state.details.city}, ${this.state.details.state}, ${this.state.details.zip_code}`} target="_blank" rel="noopener noreferrer">{this.state.isLoading ? '' : this.state.details.street_address}, {this.state.isLoading ? '' : this.state.details.city}, {this.state.isLoading ? '' : this.state.details.state} {this.state.isLoading ? '' : this.state.details.zip_code}</a></strong></p>;
                join_option = 
                    <form onSubmit={(e) => {this.join(e, this.state.details.id)}}>
                        <button className="eventdetails-form-button">Join</button>
                    </form>
            } 
            
        };
        let buttons;
        if (this.state.details.user_id === this.state.ui) {
            buttons = this.state.isLoading ? '':
                <div className="eventdetails-owner-buttons">
                    <Link to={`/my/event/edit/${this.state.event_id}`} className="no-dec"><button className="eventdetails-form-button">Edit My Event</button></Link>
                    <form onSubmit={(e) => {this.delete(e)}}>
                        <button className="eventdetails-form-button">CANCEL EVENT</button>
                    </form>
                    {this.state.copied ? <p style={{display: "block", margin: "0px auto", color: "red"}}>{this.state.copied} </p> : ''}
                    <button className="eventdetails-form-button" onClick={(e) => this.copy(e)}>Copy Event Link</button>
                </div>
        };
        let users_going;
        if (this.state.users_going.length > 0) {
            users_going = this.state.isLoading ? '' : this.users_going()
        } else {
            users_going = this.state.isLoading ? '' : <p>There is currently no one attending this event, host is unable to attend and has left this event!</p>
        };
        return (
            <div className="event-details-container">
                <div className="home-header">
                    <h5 className="home-header-title"><Link to="/" className="no-dec"><img className="header-logo" src={logo} alt="" /></Link></h5>
                    <ul className="home-header-items no-dec mobile">
                        { this.state.isLoading?'':(this.state.guest===true && this.state.signed_in===false ? <li ><Link to="/signup" className="home-message-link-guest no-dec b">Sign Up</Link></li> : <li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages_icon} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>) }
                        { this.state.isLoading?'':(this.state.guest===true && this.state.signed_in===false ? <li>|</li> : '') }
                        { this.state.isLoading?'':(this.state.guest===true && this.state.signed_in===false ? <li ><Link to="/signin" className="home-message-link-guest no-dec b">Sign in</Link></li> : <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user_icon} alt="" /></Link></li>) }
                    </ul>
                    <ul className="home-header-items no-dec td">
                        { this.state.isLoading?'':(this.state.status.active===true ? <li><Link to="/settings" className="home-explore-link no-dec b">Settings</Link></li>:'') }
                        { this.state.isLoading?'':(this.state.guest===true && this.state.signed_in===false ? <li ><Link to="/signup" className="home-message-link-guest no-dec b">Sign Up</Link></li> : <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>) }
                        { this.state.isLoading?'':(this.state.guest===true && this.state.signed_in===false ? <li>|</li> : '') }
                        { this.state.isLoading?'':(this.state.guest===true && this.state.signed_in===false ? <li ><Link to="/signin" className="home-message-link-guest no-dec b">Sign in</Link></li> : <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>) }
                        { this.state.isLoading?'':(this.state.guest===true && this.state.signed_in===false ? '' : <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>) }
                        { this.state.isLoading?'':(this.state.guest===true && this.state.signed_in===false ? '' : <li ><Link to={"/profile/" + this.state.ui} className="home-profile-link no-dec b">Profile</Link></li>) }
                        { this.state.isLoading?'':(this.state.guest===true && this.state.signed_in===false ? '' : <li ><Link to="/home" className="home-profile-link no-dec b">Home</Link></li>) }
                    </ul>
                </div>
                <div className="event-details-title-container">
                    <h1 className="event-details-title no-pointer" >Event Details</h1>
                </div>
                {this.state.guest===true?
                    <div className="eventdetails-details-container">
                        <h2 className="eventdetails-details-title no-pointer"><strong>{this.state.details.title}</strong></h2>
                        {this.state.guest===true&&this.state.signed_in===false&&this.state.share===true?
                            <div className="event-details-gif-container">
                                <img className="event-details-gif" src={ComingSoon} alt="POV Motorcycle Riding"/>
                            </div>:''
                        }
                        {this.state.guest===true&&this.state.signed_in===false&&this.state.share===true?<p><span className="r">Streaming Coming Soon!</span><br/></p>:''}
                        {this.state.guest===true&&this.state.signed_in===false&&this.state.share===true?event_date_time:'' }
                        {this.state.guest===true&&this.state.signed_in===false&&this.state.share===true?<div ref={this.mapContainer} className="map-container" style={{display: this.state.map_display}}/>:''}
                        {this.state.guest===true&&this.state.signed_in===false&&this.state.share===true?address:'' }
                        {/* {this.state.guest===true&&this.state.signed_in===false&&this.state.share===true?<p className="r">Streaming Coming Soon!</p>:''} */}
                        {this.state.guest===true&&this.state.signed_in===false&&this.state.share===true?<h3 className="eventdetails-riders-going-title"><u>Motorcyk Riders Going</u></h3>:''}
                        {this.state.guest===true&&this.state.signed_in===false&&this.state.share===true?<div className="event-details-users-going-container" style={{marginTop: "15px"}}>
                            { users_going }
                        </div>:''}
                        {this.state.guest===true&&this.state.signed_in===false&&this.state.share===true?<h3 className="eventdetails-riders-going-title"><u>Description</u></h3>:''}
                        {this.state.guest===true&&this.state.signed_in===false&&this.state.share===true?<p>{this.state.details.description}</p>:''}
                        {this.state.guest===true&&this.state.signed_in===false&&this.state.share===true?<p className="no-pointer">
                            Type: <strong className="r">{this.state.details.invite_only ? 'Invite Only' : this.state.details.anyone ? 'Anyone' : this.state.details.followers ? 'Followers' : this.state.details.share ? 'External Event' : ''}</strong>
                            <br />
                            External Link To Event: <a className="event-details-external-link no-dec" href={this.state.external_link} target="_blank" rel="noopener noreferrer"><strong className="r">Go</strong></a>
                            <br />
                            <strong className="r">{this.state.joined_count}</strong> {this.state.joined_count===1?'Rider':'Riders'} Attending
                            <br />
                            Shared By {this.state.details.display_name==="Motorcyk"?"Motorcyk":<strong className="event-details-external-link r"><Link to={this.state.details.display_name?`/@${this.state.details.display_name}`:`/rider/${this.state.details.user_id}`} className="no-dec r">{this.state.details.display_name ? this.state.details.display_name : this.state.details.first_name}</Link></strong>}
                        </p>:''}
                        {this.state.guest===true&&this.state.signed_in===false&&this.state.share===true?<br />:''}
                        {this.state.guest===true&&this.state.signed_in===false&&this.state.share===true?<p>Please sign in to use Motorcyk's event features.</p>:''}
                        {this.state.guest===true&&this.state.signed_in===false&&this.state.share===true?<Link to={{ pathname: "/signin" }} className="no-dec"><button className="create-event-form-button">Sign In</button></Link>:''}
                        <div style={{ textAlign: "center" }}>
                            {this.state.guest===true&&this.state.signed_in===true&&this.state.share===false?<p><span className="r">Streaming Coming Soon!</span><br/><br/>You must have an active membership to view event details, please activate your membership!</p>:''}
                            {this.state.guest===true&&this.state.signed_in===true&&this.state.share===false?<Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }} className="no-dec"><button className="eventdetails-form-button">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link>:''}
                            {this.state.guest===true&&this.state.signed_in===false&&this.state.share===false?<div className="event-details-gif-container">
                                <img className="event-details-gif" src={ComingSoon} alt="POV Motorcycle Riding"/>
                            </div>:''}
                            {this.state.guest===true&&this.state.signed_in===false&&this.state.share===false?<p><span style={{color:"black"}}>{this.state.guest_details.formatted_date} @ {this.state.guest_details.time}<br/>{this.state.guest_details.city}, {this.state.guest_details.state}</span><br/><br/><span className="r">Streaming Coming Soon!</span><br/><br/><span style={{color:"black"}}>Hosted by {this.state.guest_details.display_name==="Motorcyk"?"Motorcyk":<Link to={this.state.guest_details.display_name?`/@${this.state.guest_details.display_name}`:`/rider/${this.state.guest_details.user_id}`} className="no-dec hover rider-tip-button-name r">{this.state.guest_details.display_name ? this.state.guest_details.display_name : this.state.guest_details.first_name}</Link>}</span><br/><br/>This event is exclusvie to Motorcyk members, you must be signed in to view event details OR wait until rider goes live!</p>:''}
                            {this.state.guest===true&&this.state.signed_in===false&&this.state.share===false?<Link to={{ pathname: "/signin" }} className="no-dec"><button className="create-event-form-button">Sign In</button></Link>:''}

                        </div>
                    </div>:''}
                    {this.state.status.active===true&&this.state.signed_in===true?
                    <div className="eventdetails-details-container">
                        <h2 className="eventdetails-details-title no-pointer"><strong>{this.state.details.title}</strong></h2>
                        <div className="event-details-gif-container">
                            <img className="event-details-gif" src={ComingSoon} alt="POV Motorcycle Riding"/>
                        </div>
                        <p className="r">Streaming Coming Soon!</p>
                        { event_date_time }
                        <div ref={this.mapContainer} className="map-container" style={{display: this.state.map_display}}/>
                        { address }
                        { follow_option }
                        { join_option }
                        { buttons }
                        <h3 className="eventdetails-riders-going-title"><u className="eventdetails-riders-going-title">{this.state.details.share===1?'Motorcyk Riders Attending':'Riders Attending'}</u></h3>
                        <div className="event-details-users-going-container">
                            { users_going }
                        </div>
                        <br />
                        <p className="description-container no-pointer">
                            <strong className="eventdetails-description-title"><u>Description</u></strong>
                            <br />
                            {this.state.details.description}
                            <br /><br /> 
                            Type: <strong className="r">{this.state.details.invite_only ? 'Invite Only' : this.state.details.anyone ? 'Anyone' : this.state.details.followers ? 'Followers' : this.state.details.share ? 'External Event' : ''}</strong>
                            {this.state.details.share===1?<span><br /></span>:''}
                            {this.state.details.share===1?<span>External Link To Event: <a className="event-details-external-link no-dec" href={this.state.external_link} target="_blank" rel="noopener noreferrer"><strong className="r">Go</strong></a></span>:''}
                            <br />
                            {this.state.details.share===1?'':<span>Limit: <strong className="r">{this.state.details.max}</strong></span>}
                            {this.state.details.share===1?'':<span><br /></span>}
                            <strong className="r">{this.state.joined_count}</strong> {this.state.details.share===1?`Motorcyk ${this.state.joined_count===1?'Rider':'Riders'} Attending`:`Riders Attending`}
                            <br />
                            {this.state.details.share===1?'Shared':'Hosted'} By {this.state.details.display_name==="Motorcyk"?"Motorcyk":<strong className="event-details-external-link r"><Link to={this.state.details.display_name?`/@${this.state.details.display_name}`:`/rider/${this.state.details.user_id}`} className="no-dec r">{this.state.details.display_name ? this.state.details.display_name : this.state.details.first_name}</Link></strong>}
                        </p>
                        {this.state.joined===true?<div className="chat-container no-pointer">
                            <strong><u>Chat</u></strong>
                            <br /><br /> 
                            <ScrollToBottom className="messages-box no-pointer b">
                                { messages }
                            </ScrollToBottom>
                            <div className="typing-container">
                                <form onSubmit={e => {this.sendEventMessage(e)}}>
                                    <input className="message-form-input" type="text" placeholder="Type Message..." value={this.state.message}  onChange={this.onChangeMessage}/>     
                                    <button className="eventdetails-post-button" type="submit" disabled={this.state.message.length > 0 ? false : true} style={{opacity: this.state.message.length > 0 ? 1 : .5, cursor: this.state.message.length > 0 ? 'pointer': 'default' }}>Post <img className="message-send" src={send} alt="" /></button>
                                </form>
                            </div>
                        </div>:''}
                    </div>
                :''}
                
                { ((this.state.guest===true && this.state.signed_in===true) || this.state.status.active) ? <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/> : '' }
            </div>
        );
    };
};
export default EventDetails;