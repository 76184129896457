import React, { Component } from 'react';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import user from '../static/images/images/user-circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/Settings.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class Settings extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            status: '',
            redirect: false,
            ui: null,
            image_url: null,
            message_unread: false,
            active: false,
            cancelled: false,
            end: null,
            past_due: null,
        };
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, }); })
            .catch( () => { this.setState({ redirect: true }); });
        const config_sub = { headers: { Authorization: `Bearer ${token}`, },  id: this.state.ui, };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
                return response.data;
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .then(()=> { this.setState({ isLoading: false }) })
            .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
    };
    sign_out = () => {
        localStorage.removeItem('token');
        this.setState({ signed_in: false, FirstName: '' })
    }
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        const body = this.state.isLoading?<Loading />: 
        <div className="settings-container">
            <div className="home-header">
                <h5 className="home-header-title"><Link to="/" className="no-dec"><img className="header-logo" src={logo} alt="" /></Link></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    {this.state.status.active===true?<li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>:""}
                    {this.state.status.active===true?<li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>:""}
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:""}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}
                </ul>
            </div>
            <div className="settings-title-container">
                <h1 className="settings-title" >Settings</h1>
                {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
            </div>
            <div className="settings-items-container">
                <h4><u>Account</u></h4>
                <ul>   
                    <li><Link to={`/edit-profile/${this.state.ui}`} className="no-dec b">Edit Profile Details</Link></li>
                    <li><Link to={`/edit-image/${this.state.ui}`} className="no-dec b">Change Profile Image</Link></li>
                    <li><Link to="/change-email" className="no-dec b">Change Email</Link></li>
                    <li><Link to="/change-password" className="no-dec b">Change Password</Link></li>
                    {/* <li><Link to="#" className="no-dec b">Email Updates | Marketing</Link></li> */}
                    {/* <li><Link to="#" className="no-dec b">Saved Events History</Link></li> */}
                    {(this.state.status.on_trial===true||this.state.status.active_paid||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?<li><Link to="/hosted-events-history" className="no-dec b">Hosted Events History</Link></li>:""}
                    {(this.state.status.on_trial===true||this.state.status.active_paid===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?<li><Link to="/attended-events-history" className="no-dec b">Attended Events History</Link></li>:""}
                    {/* <li><Link to="/blocked-users" className="no-dec b">Blocked Users</Link></li> */}
                </ul>
                <h4><u>Earn Money</u></h4>
                <ul>
                    <li style={{cursor:"default"}}><Link to="/rider-tip-jar" className="no-dec b">Rider Tip Jar</Link> <span className="r" style={{fontSize:"14px"}}>New!</span></li>
                    <li style={{cursor:"default"}}>Become An Affiliate <span style={{fontSize:"10px"}}>(Coming Soon)</span></li>
                </ul>
                {this.state.status.active===true?<h4><u>Membership</u></h4>:""}
                <ul>
                    {(this.state.status.active_paid===false&&this.state.status.on_trial===false)?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }} className="no-dec b">Join Motorcyk</Link></li>:''}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?<li><Link to="/next-payment-date" className="no-dec b">Next Payment Date</Link></li>:''}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?<li><Link to="/change-payment" className="no-dec b">Change Payment</Link></li>:''}
                    {/* <li><Link to="#" className="no-dec b">Change Membership Plan</Link></li> */}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?<li><Link to="/cancel-membership" className="no-dec b">Cancel Membership</Link></li>:''}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?<li><Link to="/delete/account" className="no-dec r">Delete Account</Link></li>:''}
                </ul>
                <h4><u>Motorcyk</u></h4>
                <ul>
                    {/* <li><Link to="#" className="no-dec b">Push Notifications</Link></li> */}
                    <li><Link to="/rules" className="no-dec b">Motorcyk Platform Rules/Guide</Link></li>
                    <li><Link to="/rider-tip-jar-guide" className="no-dec b">Rider Tip Jar Rules/Guide</Link></li>
                    <li><Link to="/terms-of-service" className="no-dec b">Terms Of Service Policy</Link></li>
                    <li><Link to="/privacy" className="no-dec b">Privacy Policy</Link></li>
                    <li><Link to="/cookies" className="no-dec b">Cookie Policy</Link></li>
                </ul>
                <h4><Link to="/help-feedback" className="no-dec b">Help & Feedback</Link></h4>
                <h4><Link to="/about" className="no-dec b">About Motorcyk</Link></h4>
                <h4><Link to="/" onClick={this.sign_out} className="no-dec b">Sign Out</Link></h4>
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default Settings;