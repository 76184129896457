import React, { Component } from 'react';
import '../static/css/LoadingPage.css';
import logo from '../static/images/images/logo_icon.png';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
class Loading extends Component {
    constructor() {
        super();
        this.state = {};
    };
    render() {
        return(
            <div className="loading-container">
                <br />
                <div className="loading-content">
                    <img className="loading-icon" src={logo} alt="" />
                    <Stack sx={{ width: '100%', color: '#FF2F46', marginTop: '30px'}} spacing={2}>
                        <LinearProgress color="inherit" />
                    </Stack>
                </div>
            </div>
        );
    };
};
export default Loading;
