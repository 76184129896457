import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from './LoadingPage';
import axios from 'axios';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/MotorcykPlatformRules.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class MotorcykPlatformRules extends Component {
    constructor() {
        super()
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            status: '',
            isLoading: true,
            redirect: false,
            ui: null,
            image_url: null,
            next_payment: null,
            cancelled: false,
            end: null,
            message_unread: false,
            trial_used: false,
        }
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, }); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        const config_sub = { headers: { Authorization: `Bearer ${token}`, }, id: this.state.ui, };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { this.setState({ status: response.data, }) })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data, isLoading: false })})
            .catch((err) => {console.log(err, "my error..."); this.setState({ isLoading: false })})
    };
    render() {
        if (this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>;
        const body = this.state.isLoading?<Loading />: 
        <div className="next-payment-date-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                <li><Link to="/settings" className="home-explore-link no-dec b">Settings</Link></li>
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}                  </ul>
            </div>
            <div className="next-payment-date-title-container">
                <h1 className="next-payment-date-title" >Rules | Guide</h1>
                {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
                <p style={{margin: "30px auto"}}>Updated May 2023</p>
            </div>
            <div className="motorcyk-platform-rules-container">
                <p className="motorcyk-platform-rules-content-title"><strong><u>Main Rules</u></strong></p>
                <p className="motorcyk-platform-rules-content-item">- Be respectful of other members.</p>
                <p className="motorcyk-platform-rules-content-item">- Motorcycle related content ONLY.</p>
                <p className="motorcyk-platform-rules-content-item">- No sensitive/personal information.</p>
                <p className="motorcyk-platform-rules-content-item">- Report any users violation the rules or causing harassment <Link to="/help-feedback" className="no-dec b"><u>here</u></Link>.</p>
                <p className="motorcyk-platform-rules-content-item">- Do not share your account with anyone.</p>
                <p className="motorcyk-platform-rules-content-item">- Do not share your email/password with anyone.</p>
                <p className="motorcyk-platform-rules-content-item">- Make sure you have a strong password set.</p>
            </div>
            <div className="motorcyk-platform-rules-container">
                <p className="motorcyk-platform-rules-content-title"><strong><u>Your Rider Profile</u></strong></p>
                <p className="motorcyk-platform-rules-content-item">- Upload your rider profile picture <Link to={`/edit-image/${this.state.ui}`} className="no-dec b"><u>here</u></Link> to help build your profile and make new connections. PNG, JPG/JPEG, HEIC, and GIF formats are allowed.</p>
                <p className="motorcyk-platform-rules-content-item">- Share your rider profile link to your other socials to build your following. Make sure to set your rider name <Link to={`/edit-profile/${this.state.ui}`} className="no-dec b"><u>here</u></Link> to have your own custom handles in your links.</p>
                <p className="motorcyk-platform-rules-content-item">- Do not create offensive rider name that may offend other members.</p>
                <p className="motorcyk-platform-rules-content-item">- Do not put any personal/sensitive information in your profile headline for everyone to see.</p>
                <p className="motorcyk-platform-rules-content-item">- Your account may get suspended or terminated if reported of such acts. Please report anyone <Link to="/help-feedback" className="no-dec b"><u>here</u></Link>.</p>
            </div>
            <div className="motorcyk-platform-rules-container">
                <p className="motorcyk-platform-rules-content-title"><strong><u>Posts/Rider Boards</u></strong></p>
                <p className="motorcyk-platform-rules-content-item">- PNG, JPG, and GIF formats are allowed.</p>
                <p className="motorcyk-platform-rules-content-item">- Do not post offensive images.</p>
                <p className="motorcyk-platform-rules-content-item">- Do not post personal images.</p>
                <p className="motorcyk-platform-rules-content-item">- Do not  post or repost anything illegal.</p>
                <p className="motorcyk-platform-rules-content-item">- Do not post/repost scams.</p>
                <p className="motorcyk-platform-rules-content-item">- Do not post images that are not meant to be public. Once posted, always posted and can be saved by anyone until deleted.</p>
                <p className="motorcyk-platform-rules-content-item">- Anything posted will in your boards will be available for others to see, so be careful of what you upload. </p>
                <p className="motorcyk-platform-rules-content-item">- Delete immediately if you accidentally upload something by mistake.</p>
                <p className="motorcyk-platform-rules-content-item">- Please report any one that violates the board rules or for any questions you can do so <Link to="/help-feedback" className="no-dec b"><u>here</u></Link>.</p>
            </div>
            <div className="motorcyk-platform-rules-container">
                <p className="motorcyk-platform-rules-content-title"><strong><u>Riders / Enthusiasts</u></strong></p>
                <p className="motorcyk-platform-rules-content-item">- To make it easier for other riders to find you, set your rider name and zip code <Link to={`/edit-profile/${this.state.ui}`} className="no-dec b"><u>here</u></Link>.</p>
                <p className="motorcyk-platform-rules-content-item">- Anyone can follow anyone.</p>
                <p className="motorcyk-platform-rules-content-item">- Do not harass members.</p>
                <p className="motorcyk-platform-rules-content-item">- Do not spam members.</p>
                <p className="motorcyk-platform-rules-content-item">- Do not troll members.</p>
                <p className="motorcyk-platform-rules-content-item">- Do not discriminate.</p>
                <p className="motorcyk-platform-rules-content-item">- Please report any riders <Link to="/help-feedback" className="no-dec b"><u>here</u></Link>.</p>
            </div>
            <div className="motorcyk-platform-rules-container motorcyk-platform-rules-container-final">
                <p className="motorcyk-platform-rules-content-title"><strong><u>Events</u></strong></p>
                <p className="motorcyk-platform-rules-content-item">- SAFETY FIRST: Always be aware of what event’s you join.</p>
                <p className="motorcyk-platform-rules-content-item">- SAFETY FIRST: Always be aware of the location event’s are being hosted at.</p>
                <p className="motorcyk-platform-rules-content-item">- SAFETY FIRST: Always be aware of the time of an event you plan to join.</p>
                <p className="motorcyk-platform-rules-content-item">- SAFETY FIRST: Even though members have an active membership, never attend event’s with riders you never interacted with especially in the late/dark hours.</p>
                <p className="motorcyk-platform-rules-content-item">- Whether it’s private, anyone allowed, or followers only, always be aware of who joins your created events. </p>
                <p className="motorcyk-platform-rules-content-item">- Never add your home address as a meeting location.</p>
                <p className="motorcyk-platform-rules-content-item">- Always be cautious of what event’s you join.</p>
                <p className="motorcyk-platform-rules-content-item">- Do not join events if you do not plan to attend in the first place.</p>
                <p className="motorcyk-platform-rules-content-item">- Be respectful of member event’s, do not troll.</p>
                <p className="motorcyk-platform-rules-content-item">- PRIVATE EVENTS: Only share private links with those members you want to go on rides with.</p>
                <p className="motorcyk-platform-rules-content-item">- ANYONE ALLOWED EVENTS: Any Motorcyk member will be allowed to join your riding event.</p>
                <p className="motorcyk-platform-rules-content-item">- FOLLOWERS ONLY EVENTS: Only followers will be able to join your riding event.</p>
                <p className="motorcyk-platform-rules-content-item">- Please report any riders that violate event rules <Link to="/help-feedback" className="no-dec b"><u>here</u></Link>.</p>
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default MotorcykPlatformRules;