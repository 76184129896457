import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from './LoadingPage';
import axios from 'axios';
import '../static/css/SignUpPlanTrial.css';
import logoIcon from '../static/images/images/logo_icon.jpg';
// import stripe from '../static/images/images/cc-stripe.svg'
class SignUpPlanTrial extends Component {
    constructor(props) {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            redirect: false,
            status: '',
            ui: null,
            planOne: null,
            priceOne: 0,
            idOne: null,
            recurringOne: null,
            planTwo: null,
            priceTwo: null,
            idTwo: null,
            recurringTwo: null,
            user: null,
            priceTwoDecimal: null,
            currency: null,
            payment: null,
            returned_email: props.match.params.email === undefined ? null : props.match.params.email,
            active: false,
            trial_used: false,
        };
        this.plan = this.plan.bind(this);
        this.goHome = this.goHome.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then((response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, })})
            .then(() => { this.setState({user: this.props.location.data})})
            .catch(() => { window.location = '/signup'; });
        const config_sub = { headers: { Authorization: `Bearer ${token}`, }, id: this.state.ui, };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
                if (response.data.canceled===true&&response.data.canceled_access===false&&response.data.active_paid===false) {
                    window.location = `/profile/${this.state.ui}`;
                };
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.post(process.env.REACT_APP_SERVER_API + '/subs/prices', config)
            .then((response) => { 
                this.setState({
                    planOne: response.data[0].product,
                    priceOne: response.data[0].unit_amount,
                    idOne: response.data[0].id,
                    recurringOne: response.data[0].recurring.interval,
                    planTwo: response.data[1].product,
                    priceTwo: response.data[1].unit_amount,
                    idTwo: response.data[1].id,
                    recurringTwo: response.data[1].recurring.interval,
                    currency: response.data[0].currency,
                    isLoading: false
                })
            })
            .catch((err) => { console.log(err, "Error retrieving pricing.")})
    };
    async plan(type, price, price_id, recurring_type) {
        this.setState({ isLoading: true })
        const token = localStorage.getItem("token");
        const config = { 
            headers: { Authorization: `Bearer ${token}`, },
            id: this.state.ui,
            price: price,
            priceId: price_id,
            currency: this.state.currency,
            recurring_type: recurring_type,
            product: type
        };
        await axios.post(process.env.REACT_APP_SERVER_API + '/subs/session/trial', config)
            .then((response) => { this.setState({ payment: response.data.url  }) })
            .then(() => { window.location.href = this.state.payment })
            .catch((err) => { console.log(err, "Error with checkout.") })
    };

    async goHome() {
        this.setState({ redirect: true, isLoading: true })
    };
    render() {
        if (this.state.redirect === true) return <Redirect to = {{pathname: "/home"}}/>
        const body = this.state.isLoading?<Loading />: 
        <div>
            <Link className="no-dec" to="/"><img className="signup-logo-icon" src={logoIcon} alt="" /></Link>
            <h1 className="signup-plan-title"><strong>Membership</strong></h1><br />
            <Link to="/" className="no-dec signup-plan-exit-button"><button className="signup-plan-exit-button">Exit</button></Link>
            <div className="signup-table">
                <h3><u>Limited Time Promotions:</u></h3>
                <ul style={{paddingInlineStart: "0px"}}>
                    <li style={{listStyle:"none", fontSize:"12px"}}>- 14-DAY FREE TRIAL (Limited time)</li>
                    <li style={{listStyle:"none", fontSize:"12px"}}>- Lock in current membership prices (Limited time)</li>
                </ul>
                {this.state.trial_used===true?<p className="r" style={{fontSize: "15px"}}>You have already used your free trial, please subscribe to a plan to get an additional 30-Days Trial.</p>:''}
                <br/>
                <h3><u>Exclusive Member Features:</u></h3>
                <ul style={{paddingInlineStart: "0px"}}>
                    <li style={{listStyle:"none", fontSize:"12px"}}>- Unlimited Event Hosting</li>
                    <li style={{listStyle:"none", fontSize:"12px"}}>- Access to Motorcyk's personalized Home page view.</li>
                    <li style={{listStyle:"none", fontSize:"12px"}}>- Account will become verified with a checkmark on rider profile.</li>
                    <li style={{listStyle:"none", fontSize:"12px"}}>- Exclusive Merch Discount (Store Coming Soon)</li>
                    <li style={{listStyle:"none", fontSize:"12px"}}>- Access To Huge Riding Community</li>
                    <li style={{listStyle:"none", fontSize:"12px"}}>- And much more!</li>
                </ul>
                <br/>
                <h3 style={{marginBottom: "-50px"}}><u>Choose Membership Plan:</u></h3>
                {/* <p style={{width: "95%", margin: "10px auto", marginBottom: "20px", fontSize: "10px"}}><u>PLEASE NOTE:</u> We require a valid payment method before the use of the platform to help avoid bots, spammers and fake accounts, you will not be charged during the FREE TRIAL. Subscription payments begin after the FREE TRIAL ends unless canceled before trial ends. All subscriptions and payments are handled through our trusted partner <a href="https://www.stripe.com/"><img style={{height: "25px", marginBottom: "-9px"}} src={stripe} alt="Stripe"/></a>, we do not store any payment information. </p> */}
                <table className="signup-table">
                    <thead>
                        <tr>
                            <th><h3><u>FREE</u></h3></th>
                            <th><h3><u>Monthly</u></h3></th>
                            <th><h3><u>Yearly</u></h3></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="signup-plan-row">
                            <td>$0<br/>Free Account</td>
                            <td><strike className="r">$15</strike> ${this.state.priceOne/100}/Month<br/>(SAVE 54%)</td>
                            <td><strike className="r">$165</strike> ${this.state.priceTwo/100}/Month<br/>(SAVE 55%)</td>
                        </tr>
                        <tr className="signup-plan-row">
                           <td></td>
                           <td></td>
                           <td></td>
                        </tr>
                        <tr className="signup-plan-row">
                            <td>Subscribe<br/>Anytime</td>
                            <td>Cancel<br/>Anytime</td>
                            <td>Cancel<br/>Anytime</td>
                        </tr>
                        <tr>
                            {this.props.location.pathname!=="/signup-plan-trial-signup"?<td><p className="r font-1"><br/>CURRENT<br/>ACCOUNT</p></td>:<td><button className="signup-plan-button" onClick={this.goHome}>Join For Free</button></td>}
                            <td><button className="signup-plan-button" onClick={() => {this.plan(this.state.planOne, this.state.priceOne, this.state.idOne)}}>Select Plan</button></td>
                            <td><button className="signup-plan-button" onClick={() => {this.plan(this.state.planTwo, this.state.priceTwo, this.state.idTwo, this.state.recurringTwo)}}>Select Plan</button></td>
                        </tr>
                    </tbody>                    
                </table>
            </div>
            <h6 className="signup-footer w">Motorcyk LLC</h6>
        </div>
        return (
            <div className="signup-container">
                { body }
            </div>
        );
    };
};
// OLD
// class SignUpPlanTrial extends Component {
//     constructor(props) {
//         super();
//         this.state = {
//             signed_in: localStorage.getItem('token') ? true : false,
//             isLoading: true,
//             redirect: false,
//             ui: null,
//             trialPlan: null,
//             trialPrice: null,
//             trialId: null,
//             recurringThree: null,
//             user: null,
//             priceTwoDecimal: null,
//             currency: null,
//             payment: null,
//             returned_email: props.match.params.email === undefined ? null : props.match.params.email,
//             active: false,
//             trial_used: false,

//         };
//         this.plan = this.plan.bind(this);
//     };
//     async componentDidMount () {
//         const token = localStorage.getItem("token");
//         const config = { headers: { Authorization: `Bearer ${token}`, } };
//         await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
//             .then((response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, })})
//             .then(() => { if(this.state.trial_used===true) window.location = "/signup-plan"})
//             .then(() => { this.setState({user: this.props.location.data})})
//             .catch(() => {window.location = '/signup';});
//         const config_sub = { 
//             headers: { Authorization: `Bearer ${token}`, }, 
//             id: this.state.ui,
//         };
//         await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
//             .then((response) => { 
//                 if (response.data.status === true) {
//                     window.location = "/home"
//                 } else {
//                     this.setState({ isLoading: false });
//                 };
//             })
//             .catch((err)=>{ console.log(err, "Error in subscription retrieval"); this.setState({ isLoading: false, }) })
//         await axios.post(process.env.REACT_APP_SERVER_API + '/subs/trial/prices', config)
//             .then((response) => { 
//                 this.setState({
//                     isLoading: false,
//                     trialPlan: response.data.product,
//                     trialPrice: response.data.unit_amount,
//                     trialId: response.data.id,
//                 })
//             })
//             .catch((err) => { console.log(err, "thsis my error in sing up plan under prices")})
//     };
//     async plan(type, price, price_id, recurring_type) {
//         this.setState({ isLoading: true })
//         const token = localStorage.getItem("token");
//         const config = { 
//             headers: { Authorization: `Bearer ${token}`, },
//             id: this.state.ui,
//             price: price,
//             priceId: price_id,
//             currency: this.state.currency,
//             recurring_type: recurring_type,
//             product: type
//         };
//         await axios.post(process.env.REACT_APP_SERVER_API + '/subs/session/signup', config)
//             .then((response) => {
//                 this.setState({ payment: response.data.url  })
//             })
//             .then(() => { window.location.href = this.state.payment })
//             .catch((err) => {
//                 console.log(err, "whats my error in sign up plan under sessions")
//             })
//     };
//     render() {
//         const body = this.state.isLoading?<Loading />: 
//         <div>
//             <Link className="no-dec" to="/"><img className="signup-logo-icon" src={logoIcon} alt="" /></Link>
//             <h1 className="signup-plan-trial-title"><strong>Membership</strong></h1><br />
//             <Link to="/" className="no-dec signup-plan-trial-exit-button"><button className="signup-plan-trial-exit-button">Exit</button></Link>
//             <div className="signup-table">
//                 {this.state.trial_used===true?<p className="r" style={{fontSize: "15px"}}>You have already used your free trial, please subscribe to a plan to get an additional 30-Days Trial.</p>:''}
//                 <h3><u>Limited Time Promotions:</u></h3>
//                 <ul style={{paddingInlineStart: "0px"}}>
//                     <li style={{listStyle:"none", fontSize:"12px"}}>- 30-DAY FREE TRIAL (Limited time)</li>
//                     <li style={{listStyle:"none", fontSize:"12px"}}>- Lock in current membership prices (Limited time)</li>
//                 </ul>
//                 <br/>
//                 <h3><u>Exclusive Member Features:</u></h3>
//                 <ul style={{paddingInlineStart: "0px"}}>
//                     <li style={{listStyle:"none", fontSize:"12px"}}>- Unlimited Event Hosting</li>
//                     <li style={{listStyle:"none", fontSize:"12px"}}>- Claim Your Riding Name In The Riding Community</li>
//                     <li style={{listStyle:"none", fontSize:"12px"}}>- Access To Huge Riding Community</li>
//                     <li style={{listStyle:"none", fontSize:"12px"}}>- Exclusive Merch Discount (Coming Soon)</li>
//                     <li style={{listStyle:"none", fontSize:"12px"}}>- And More!</li>
//                 </ul>
//                 <br/>
//                 <h3><u>Membership Plans:</u></h3>
//                 <p style={{width: "95%", margin: "10px auto", marginBottom: "20px", fontSize: "10px"}}><u>PLEASE NOTE:</u> You will not be charged during the FREE TRIAL. You must select a plan after the FREE TRIAL ends to continue to use the platform. All subscriptions and payments are handled through our trusted partner <a href="https://www.stripe.com/"><img style={{height: "25px", marginBottom: "-9px"}} src={stripe} alt="Stripe"/></a>, we do not store any payment information. </p>
//                 <table className="signup-table">
//                     <thead>
//                         <tr>
//                             <th><h2><u>Monthly</u></h2></th>
//                             <th><h2><u>Yearly</u></h2></th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr className="signup-plan-trial-row">
//                             <td><strike className="r">$15</strike> $8/Month (SAVE 54%)</td>
//                             <td><strike className="r">$165</strike> $90/Month (SAVE 55%)</td>
//                         </tr>
//                         <tr className="signup-plan-trial-row">
//                             <td>Cancel Anytime</td>
//                             <td>Cancel Anytime</td>
//                         </tr>
//                     </tbody>                    
//                 </table>
//                 <h5 style={{marginBottom: "50px", fontSize: "10px"}}>* NO PAYMENT METHOD REQUIRED *</h5>
//                 {this.state.trial_used===false?<button className="signup-plan-start-trial-button" onClick={() => {this.plan(this.state.trialPlan, this.state.trialPrice, this.state.trialId)}}>Start Free Trial</button>:''}
//             </div>
//             <h6 className="signup-footer w">Motorcyk LLC</h6>
//         </div>
//         return (
//             <div className="signup-container">
//                 { body }
//             </div>
//         );
//     };
// };
export default SignUpPlanTrial;