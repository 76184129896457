import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from './LoadingPage';
import axios from 'axios';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/RiderTipJarGuide.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class RiderTipJarGuide extends Component {
    constructor() {
        super()
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            status: '',
            isLoading: true,
            redirect: false,
            ui: null,
            image_url: null,
            next_payment: null,
            cancelled: false,
            end: null,
            message_unread: false,
            trial_used: false,
        }
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, }); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        const config_sub = { 
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data, isLoading: false })})
            .catch((err) => {console.log(err, "my error..."); this.setState({ isLoading: false })})
    };
    render() {
        if (this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>;
        const body = this.state.isLoading?<Loading />: 
        <div className="next-payment-date-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                <li><Link to="/settings" className="home-explore-link no-dec b">Settings</Link></li>
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}                 </ul>
            </div>
            <div className="next-payment-date-title-container">
                <h1 className="next-payment-date-title" >Tip Jar Rules | Guide</h1>
                {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
                <p style={{margin: "30px auto"}}>Updated May 2023</p>
            </div>
            <div className="tip-jar-rules-guide-guide-title-container">
                <h2 className="tip-jar-rules-guide-guide-title"><u>Guide</u></h2>
            </div>
            <div className="tip-jar-rules-guide-guide-steps">
                <p style={{textAlign: "center", margin: "30px auto"}}><strong>In order to become successful and start receiving tips, there are a few tips and steps you can do to get started:</strong></p>
                <p className="guide-content"><u><strong>ENABLE TIP FEATURE:</strong></u> This is the very first step you can do to begin receiving tips for your rider profile. You can do this by navigating to "Settings" {'>'} "Rider Tip Jar" {'>'} Click on "Enable Tip Jar" button and accpet the terms and rules.</p>
                <p className="guide-content"><u><strong>RIDER PROFILE:</strong></u> Having your full rider profile details completed will help make your profile standout and establish yourself as a member of Motorcyk and the content you post. Go to your profile details and make sure you have all your details completed. You can update your rider profile by navigating to "Settings" {'>'} "Edit Profile Details".</p>
                <p className="guide-content"><u><strong>RIDER NAME HANDLE:</strong></u> Your Rider/Display name is an important part of your profile. Having your very own rider name can help you establish yourself as a rider and let other gain your trust with your posts. Your rider name will also be a part of your own sharable link, and will make it easier for others to reach your profile when you share your link (Ex.: "www.motorcyk.com/@YourRiderName"). You can confirm you have set your Rider/Display Name by navigating to "Settings" {'>'} "Edit Profile Details" and check the "Display Name" input.</p>
                <p className="guide-content"><u><strong>PROFILE PICTURE:</strong></u> Having set your profile picture will add more authenticity to your rider profile, as this is what your followers/fans will know you by as well. You can upload a picture for you profile by navigating to "Settings" {'>'} "Edit Profile Image", select picture and then upload it.</p>
                <p className="guide-content"><u><strong>BOARD POSTS:</strong></u> Your profile boards will be one of the main reasons your fans will tip you. Posting a lot or posting less will not matter, but can help your profile stand out when others reach your profile. Please do not spam posts and try to keep your uploads to a minimum of one per day. You can upload a post by just navigation to your Profile (Admin View), and clicking on the "Posts" tab. ALWAYS BE CAUTIOUS OF WHAT YOU POST, KEEP IN MIND, ONCE IT GOES ON THE INTERENT IT BELONGS TO THE INTERNET! 🫣</p> 
                <p className="guide-content"><u><strong>SHARE YOUR PROFILE:</strong></u> Want to reach a further audience? Share your Motorcyk profile link with all your socials, and wherever possible! This will insure to bring you more tips and allow others to appreciate your content. Be sure to share your public profile with your handle (Ex.: www.motorcyk.com/@YourRiderName). In order to make sure you have your handle set, be sure to have your Rider/Display name set by navigating to "Settings" {'>'} "Edit Profile Details" and check the "Display Name" input.</p>
                <p className="guide-content"><u><strong>TIP JAR BALANCE:</strong></u> For now, there will be no expiration to your tip money, you will be able to have a balance for as long as you want, unless otherwise Motorcyk changes it's this tipping rule.</p>
                <p className="guide-content"><u><strong>TIP JAR FEES:</strong></u> A $0.40 ($0.60 for members not on a paid/trial plan) fee will be deducted per tip transaction and a service fee of 20% (25% for members not on a paid/trial plan) of the tip jar balance will be deducted when you cashout. You'll be able to see the total cashout balance once you initiate a cashout.</p>
                <p className="guide-content"><u><strong>CASHING OUT:</strong></u> You must have a balance of at least $5.00. To initiate a cashout, navigate to "Settings" {'>'} "Rider Tip Jar", then click on the "Cashout" button, review your total and agreement, then click on "Confirm and Cashout" button to finalize transfer. Please allow 7-14 days to recieve funds in your PayPal account.</p>
                <p className="guide-content"><u><strong>PAYMENT METHODS:</strong></u> Currently, we will only be doing cashouts through PayPal until further notice. If you do not have PayPal account please go to https://www.PayPal.com, and create an account in order to receive funds. You will be able to provide us your username, email or phone number associated with your PayPal account during cashout.</p>
            </div>
            <div className="tip-jar-rules-guide-rules-title-container">
                <h2 className="tip-jar-rules-guide-rules-title"><u>Rules | Terms</u></h2>
            </div>
            <div className="tip-jar-rules-guide-rules-steps">
                <p style={{textAlign: "center", margin: "30px auto"}}><strong>Below are just some rules to note for the tip feature:</strong></p>
                <p>1. You Must have a minimum of $5 dollars in order to cashout your tip jar.</p>
                <p>2. Everytime you cashout, your jar will become empty.</p>
                <p>3. PayPal is currently the only cashout method until further notice.</p>
                <p>4. Have an up to date PayPal account where you can receive your cashout, Motorcyk will not be responsible for incorrect account information, so always triple check your PayPal account details during cashout!</p>
                <p>5. If you happen to tip other members, once you tip a rider, there will be no refunds or cancelations. So, please always be sure you can tip what you are comfortable with.</p>
                <p>6. Once you initiate a cashout, you will not be able to undo it.</p>
                <p>7. Please do not share your account information with others as they will be able to have access to your cashouts.</p>
                <p>8. Please reach out to us at hello@motorcyk.com for any questions, we are here to help guide and answer any questions you may have.</p>
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default RiderTipJarGuide;