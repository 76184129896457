import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import '../static/css/RiderAttending.css';
class RiderAttending extends Component {
    constructor() {
        super();
        this.state = {};
        this.events = this.events.bind(this);
    };
    events() {
        const Event = (props) => (
            <Link to={`/event/${props.event.id}`}>
                <div className="rider-attending-component-event-box">
                    <p className="rider-attending-component-date"><strong>{props.event.formatted_date} @ {props.event.formatted_time}</strong></p>
                    <p className="rider-attending-component-title"><strong>{props.event.title}</strong></p>
                    <p className="rider-attending-component-city">{props.event.city}, {props.event.state}</p>
                    <p className="rider-attending-component-host">{props.event.share?'Shared':'Hosted'} by <strong>{props.event.display_name ? props.event.display_name : props.event.first_name}</strong></p>
                </div>
            </Link> 
        );
        return this.props.data.attending_events.map(event => {
            return  <Event event={event} key={event.id} />
        });
    };
    render() {
        let events;
        let statement;
        if (!this.props.data.guest) {
            if (this.props.data.attending_events.length === 0) {
                if (this.props.data.status.active === true) {
                    events = 
                        <div className="rider-attending-component-message-container">
                            <p className="rider-attending-statement">{ this.props.data.display_name ? this.props.data.display_name : this.props.data.first_name } is currently not attending anything at the moment! <span role="img" aria-label="">😔</span></p>          
                        </div>  
                } else {
                    events = 
                        <div className="rider-attending-component-message-container">
                            <p className="rider-attending-statement">You must have an active membership to view riding events!</p>   
                            <Link to={{ pathname: this.props.data.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="no-dec"><button className="edit-profile-form-button back">{this.props.data.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link>       
                        </div>  
                }
            } else {
                events = this.props.data.isLoading ? '' : this.events()
            }
        } else {
            events = '';
            statement = 
                <div>
                    <p className="rider-attending-statement">Please sign in to view riders upcoming events!</p>
                    <Link to={"/signin"} className="no-dec"><button className="rider-attending-signin-button">Sign In</button></Link>
                </div>
        }
        const body = this.state.isLoading?<Loading />: 
        <div>
            { events }
            { statement }
        </div>
        return (
            <div className="rider-attending-component-container">{ body }</div>
        );
    };
};
export default RiderAttending;