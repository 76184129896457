import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import moment from "moment";
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/RiderTipJar.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';

class RiderTipJar extends Component {
    constructor() {
        super()
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            isLoading: true,
            redirect: false,
            ui: null,
            status: '',
            image_url: null,
            next_payment: null,
            cancelled: false,
            end: null,
            message_unread: false,
            trial_used: false,
            enable: "block",
            agree_continue: "none",
            jar_enabled: false,
            disable: "block",
            disable_statement: "none",
            agree_disable: "none",
            cancel_disable: "none",
            tips: [],
            balance: 0,
        }
        this.agree_enable = this.agree_enable.bind(this);
        this.disable = this.disable.bind(this);
        this.cancel_disable = this.cancel_disable.bind(this);
        this.agree_disable = this.agree_disable.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, jar_enabled: response.data.tip_jar===0?false:true}); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        const config_sub = { 
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.post(process.env.REACT_APP_SERVER_API + `/rider/tip/jar/${this.state.ui}`, config)
            .then((response) => { this.setState({ tips: response.data.tips, balance: response.data.balance/100 }); })
            .catch((err) => { console.log(err, "Error retrieving balance!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data, isLoading: false, })})
            .catch((err) => {console.log(err, "No profile image found!"); })
    };
    enable = () => { this.setState({ enable: "none", agree_continue: "block", })}
    cancel_enable = () => { this.setState({ enable: "block", agree_continue: "none", })}
    agree_enable = async (e) => {
        e.preventDefault();
        this.setState({ isLoading: true });
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}`, },
            id: this.state.ui,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + '/jar/enable', config)
            .then((response)=>{
                console.log(response)
                if (response.data.message==='SUCCESS') {
                    alert('Your tip jar has been enabled! 🏍💨 🫙 💰')
                };
            })
            .then(()=>window.location = '/rider-tip-jar')
            .catch(()=>{console.log("Error with enabling jar"); this.setState({ isLoading: false})})

    };
    disable = () => { this.setState({ disable: "none", disable_statement: "block", agree_disable: "block", cancel_disable: "block" })}
    cancel_disable = () => { this.setState({ disable: "block", disable_statement: "none", agree_disable: "none", cancel_disable: "none" })}
    agree_disable = async (e) => {
        e.preventDefault();
        this.setState({ isLoading: true });
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}`, },
            id: this.state.ui,
            balance: this.state.balance,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + '/jar/disable', config)
            .then((response)=>{
                if (response.data.message==='SUCCESS') {
                    alert('Your tip jar has been disabled! 🫙')
                };
            })
            .then(()=>window.location = '/rider-tip-jar')
            .catch(()=>{console.log("Error with enabling jar"); this.setState({ isLoading: false})})
    };
    tippers () {
        const Tipper = (props) => (
            <p className="rider-tip-jar-tipper">{props.tip.from_name} tipped you ${(props.tip.tip_amount/100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} on {moment(props.tip.created_at).utc().format('ddd, MMM DD, YYYY')}</p>
        );
        return this.state.tips.map(tip => {
            return <Tipper tip={tip} key={tip.id} />
        })
    }
    render() {
        if (this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>;
        let tippers = this.state.tips.length>0?this.tippers():'';
        const body = this.state.isLoading?<Loading />: 
        <div className="next-payment-date-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/settings" className="home-explore-link no-dec b">Settings</Link></li>
                    {this.state.status.active===true?<li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>:""}
                    {this.state.status.active===true?<li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>:""}
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active===true?<li><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:""}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}
                </ul>
            </div>
            <div className="next-payment-date-title-container">
                <h1 className="next-payment-date-title" >Rider Tip Jar</h1>
                {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
                {this.state.past_due?<p style={{fontSize: "15px",width: "90%", textAlign: "center", backgroundColor: "white", margin: "20px auto"}}>Your account is currently past due, please update payment method to avoid access interruption. <br/> <Link to={"/change-payment"} className="no-dec" ><button className="edit-profile-form-button">Update Payment</button></Link></p>:''}
            </div>
            <div className="rider-tip-jar-overview-statement">
                {this.state.jar_enabled===true?'':<p><span style={{fontWeight: "bolder"}}><u>Overview:</u></span> In order to help you gain followers and tips, we recommend having set a profile image, have at least a few board posts in your profile, and your full profile details filled. Go here for full guide/rules before you enable this feature. <span role="img" aria-label="">👉</span> <Link to="/rider-tip-jar-guide" className="rider-tip-jar-guide-link no-dec b">Rider Tip Jar Guide/Rules</Link></p>}
            </div>
            {this.state.jar_enabled===true?'':<div className="rider-tip-jar-enable-button-container" style={{display: this.state.enable, }}>
                <button className="rider-tip-jar-enable-button" style={{display: this.state.enable}} onClick={()=>this.enable()}>Enable Tip Jar</button>
            </div>}
            {this.state.jar_enabled===true?'':<div className="rider-tip-jar-overview-statement" style={{display: this.state.agree_continue}}>
                <p>By enabling the tipping feature and start accepting tips, you are agreeing to follow the tipping terms and rules found <Link className="rider-tip-jar-guide-link no-dec b"><u>here</u></Link>. Click the 'Continue' button below to enable the tipping feature or 'Cancel' to exit.</p>
            </div>}
            {this.state.jar_enabled===true?'':<div className="rider-tip-jar-enable-button-container" style={{display: this.state.agree_continue}}>
                <form onSubmit={e=>this.agree_enable(e)}><button type="submit" className="rider-tip-jar-enable-button" style={{display: this.state.agree_continue}}>Agree and Continue</button></form>
                <button className="rider-tip-jar-enable-button" style={{display: this.state.agree_continue}} onClick={()=>this.cancel_enable()}>Cancel</button>
            </div>}
            {this.state.jar_enabled===false?'':<div className="rider-tip-jar-details">
                <p className="rider-tip-jar-total">Current balance: ${this.state.balance.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                {this.state.balance===0?'':<Link to="/rider-tip-jar-cashout" className="no-dec"><button className="rider-tip-jar-cashout-button">Cashout</button></Link>}
                <button className="rider-tip-jar-disable-button" style={{display: this.state.disable}} onClick={()=>this.disable()}>Disable Tip Jar</button>
                <p className="rider-tip-jar-disable-statement" style={{display: this.state.disable_statement}}>{this.state.balance===0?'':<span>You currently have a balance, by continuing you agree to forfeit your jar funds.</span>} Are you sure you want to disable your jar?</p>
                {this.state.balance===0?'':<form onSubmit={e=>this.agree_disable(e)}><button type="submit" className="rider-tip-jar-disable-button-confirm" style={{display: this.state.agree_disable}}>Agree & Disable</button></form>}
                {this.state.balance===0?<form onSubmit={e=>this.agree_disable(e)}><button type="submit" className="rider-tip-jar-disable-button-confirm" style={{display: this.state.agree_disable}}>Yes</button></form>:''}
                {this.state.balance===0?'':<button className="rider-tip-jar-disable-button" style={{display: this.state.cancel_disable}} onClick={()=>this.cancel_disable()}>Cancel</button>}
                {this.state.balance===0?<button className="rider-tip-jar-disable-button" style={{display: this.state.cancel_disable}} onClick={()=>this.cancel_disable()}>No</button>:''}
                <div className="rider-tip-jar-tipper-container">{this.state.tips.length>0?<p style={{marginLeft:"10px"}}><strong><u>Tips</u></strong></p>:''}{tippers}</div>
                {this.state.tips.length>0?'':<p className="rider-tip-jar-no-tip-statement">You currently do not have any tips in your current jar, tippers will be displayed here when you receive a tip. <span role="img" aria-label="">🏍💨 🫙 💰</span></p>}
            </div>}
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default RiderTipJar;