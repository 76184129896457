import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../static/css/ProfileSaved.css';
class ProfileSaved extends Component {
    constructor() {
        super();
        this.state = {};
        this.events = this.events.bind(this);
    };
    events() {
        const Event = (props) => (
            <Link to={`/event/${props.event.id}`}>
                <div className="profile-saved-component-event-box">
                    <p className="profile-saved-component-date"><strong>{props.event.formatted_date} @ {props.event.formatted_time}</strong></p>
                    <p className="profile-saved-component-title"><strong>{props.event.title}</strong></p>
                    <p className="profile-saved-component-city">{props.event.city}, {props.event.state}</p>
                    <p className="profile-saved-component-host">Hosted by <strong>{props.event.display_name ? props.event.display_name : props.event.first_name}</strong></p>
                </div>
            </Link> 
        );
        return this.props.data.saved_events.map(event => {
            return  <Event event={event} key={event.id} />
        })
    };
    render() {
        let events;
        let statement;
        let activate_button;
        if(this.props.data.status.active === true) {
            if (this.props.data.saved_events.length === 0) {
                events = 
                    <div className="profile-saved-component-message-container">
                        <p className="profile-saved-statement">You currently aren't following any events! <span role="img" aria-label="">😔</span></p>          
                    </div>     
            } else {
                events = this.state.isLoading ? '' : this.events()
                statement = '';
            };
        } 

        return (
            <div className="profile-saved-component-container">
                { events }
                { statement }
                { activate_button }
            </div>
        );
    };
};
export default ProfileSaved;