import React, { Component } from 'react';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import '../static/css/SignIn.css';
import logoIcon from '../static/images/images/logo_icon.png';
const google = window.google;
class SignIn extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            signed_in: localStorage.getItem('token') ? true : false,
            redirect: false,
            isLoading: true,
            signin_error: null,
            disabled: false,
            user_id: null,
            g_error: null,
            g_error_link: null,
            g_error_reset: null,
        };
    };
    signin_g = async (response) => {
        var userObject = await jwt_decode(response.credential);
        this.setState({ 
            user: userObject,
            user_id: userObject.sub,
        });
        const user = { user_id: this.state.user_id, }
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/signin_g', user)
            .then(response => { localStorage.setItem('token', response.data.token); })
            .then(() => this.setState({redirect: true}))
            .catch(err => {
                if (err.response.data === false) {
                    this.setState({ 
                        g_error: "Google account not registered, please go to our sign up page ",
                        g_error_link: "here",
                        g_error_reset: ", or if you forgot your password, please reset it.",
                        isLoading: false
                    });
                };
                console.log(err, "Google sign in error!")
            })
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( () => { this.setState({ redirect: true }) })
            .catch(err => { this.setState({ isLoading: false }); });
        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID,
            callback: this.signin_g,
        });
        google.accounts.id.renderButton(
            document.getElementById("buttonDiv"),
            { 
                type: "standard",
                shape: "rectangular",
                theme: "outline", 
                text: "signin_with",
                size: "large", 
                logo_alignment: "center",
                width: "250px",
            }  
        );
    };
    handle_change = e => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(prevstate => {
            const newState = { ...prevstate };
            newState[name] = value;
            return newState;
        });
    };
    signin = async (e, data) => {
        e.preventDefault();
        this.setState({ disabled: true, isLoading: true, })
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/signin', data)
        .then(response => {
            localStorage.setItem('token', response.data.token);
            this.setState({ redirect: true, })
        })
        .catch(err => {
            this.setState({
                disabled: false,
                signin_error: err.response.data,
                password: '',
                isLoading: false,
            });
        });
    };
    render() {
        if(this.state.redirect === true) {
            return <Redirect to={{
                pathname: "/home",
                data: {
                    first_name: this.state.first_name,
                    zip_code: this.state.zip_code,
                    display_name: this.state.display_name,
                }
            }}/>
        };
        const body = this.state.isLoading?<Loading />:
        <div className="signin-container">
            <div className="signin-box">
                <Link to="/" className="no-dec"><img className="signin-logo" src={logoIcon} alt="" /></Link>
                <h3 className="signin-box-title r">Sign In</h3>
                <p className="g_error-message r">{this.state.g_error} <Link to="/signup" className="no-dec r"><strong>{this.state.g_error_link}</strong></Link>{this.state.g_error_reset}</p>
                <div className="signin-google-button-content">
                    <div className="signin-with-google-container">
                        <div id="buttonDiv"></div> 
                    </div> 
                </div>
                <p className="signin-spacer ">---------- OR ----------</p>
                <form onSubmit={e => this.signin(e, this.state)}>
                    <p className="error-message r">{ this.state.signin_error }</p>
                    <input className="signin-form-input" type="text" placeholder="Email" name="email" value={this.state.email} onChange={this.handle_change}/>
                    <input className="signin-form-input" type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.handle_change}/>     
                    <button className="signin-form-button" style={{ opacity: this.state.disabled ? .5 : 1}} disabled={this.state.disabled}>{this.state.disabled ? 'Signing in ...' : 'Sign In'}</button>
                </form>
                <h6 className="signin-box-forgot-password"><Link to="/forgot-password" className="no-dec r"><strong>Forgot Password?</strong></Link></h6>
                <h6 className="signin-box-signup w">Don't have an acocunt? <Link to="/signup" className="no-dec"><strong>Sign Up</strong></Link></h6>
            </div>
            <h6 className="signin-footer w">Motorcyk LLC</h6>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default SignIn;