import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage'
import '../static/css/ProfileHosting.css';
class ProfileHosting extends Component {
    constructor() {
        super();
        this.state = {};
          this.events = this.events.bind(this);
    };
    events() {
        const Event = (props) => (
            <Link to={`/event/${props.event.id}`}>
                <div className="profile-hosting-component-event-box">
                    <p className="profile-hosting-component-date"><strong>{props.event.formatted_date} @ {props.event.formatted_time}</strong></p>
                    <p className="profile-hosting-component-title"><strong>{props.event.title}</strong></p>
                    <p className="profile-hosting-component-city">{props.event.city}, {props.event.state}</p>
                    <p className="profile-hosting-component-host">{props.event.share?'Shared':'Hosted'} by <strong>{this.props.data.display_name ? this.props.data.display_name : this.props.data.first_name}</strong></p>
                </div>
            </Link> 
        );
        return this.props.data.hosting_events.map(event => {
            return  <Event event={event} key={event.id} />
        });
    };
    render() {
        if (this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let events;
        let statement;
        let activate_button;
        if(this.props.data.status.active === true) {
            if (this.props.data.hosting_events.length === 0) {
                events =  <p className="profile-hosting-statement">You aren't hosting anything yet! <span role="img" aria-label="">😔</span></p>
            } else {
                events = this.state.isLoading ? <Loading /> : this.events();
                statement = '';
            };
        } else {
            this.setState({redirect: true})
            // statement = this.props.data.isLoading ? '' : <p className="profile-posts-statement">Activate your membership to host events!</p>
            // activate_button = <Link to={{ pathname: this.props.data.trial_used===false?"/signup-plan-trial":"/signup-plan", }} className="profile-posts-upload no-dec">{this.props.data.trial_used===false?"Activate Free Trial":"Activate Membership"}</Link>
        };
        return (
            <div className="profile-hosting-component-container">
                { events }
                { statement }
                { activate_button }
            </div>
        );
    };
};
export default ProfileHosting;