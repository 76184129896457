import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import '../static/css/ProfileAttending.css';
class ProfileAttending extends Component {
    constructor() {
        super();
        this.state = {};
        this.events = this.events.bind(this);
    };
    events() {
        const Event = (props) => (
            <Link to={`/event/${props.event.id}`}>
                <div className="profile-attending-component-event-box">
                    <p className="profile-attending-component-date"><strong>{props.event.formatted_date} @ {props.event.formatted_time}</strong></p>
                    <p className="profile-attending-component-title"><strong>{props.event.title}</strong></p>
                    <p className="profile-attending-component-city">{props.event.city}, {props.event.state}</p>
                    <p className="profile-attending-component-host">{props.event.share?'Shared':'Hosted'} by <strong>{props.event.display_name ? props.event.display_name : props.event.first_name}</strong></p>
                </div>
            </Link> 
        );
        return this.props.data.attending_events.map(event => {
            return  <Event event={event} key={event.id} />
        })
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>;
        let events;
        let statement;
        let activate_button;
        if(this.props.data.status.active === true) {
            if (this.props.data.attending_events.length === 0) {
                events = 
                    <div className="profile-attending-component-message-container">
                        <p className="profile-attending-statement">You aren't attending anything yet! <span role="img" aria-label="">😔</span></p>          
                    </div>     
            } else {
                events = this.state.isLoading ? '' : this.events()
                statement = '';
            };
        } else {
            this.setState({redirect: true})
            // statement = this.props.data.isLoading ? '' : <p className="profile-posts-statement">Activate your membership to join events!</p>
            // activate_button = <Link to={{ pathname: this.props.data.trial_used===false?"/signup-plan-trial":"/signup-plan", }} className="profile-posts-upload no-dec">{this.props.data.trial_used===false?"Activate Free Trial":"Activate Membership"}</Link>
        }
        return (
            <div className="profile-posts-component-container" style={{textAlign: "center"}}>
                { events }
                { statement }
                { activate_button }
            </div>
        );
    };
};
export default ProfileAttending;