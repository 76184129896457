import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import '../static/css/Welcome.css';
import '../static/css/WelcomeCS.css';
import discover from '../static/images/images/magnifying-glass-location-solid.svg';
import create from '../static/images/images/calendar-regular.svg';
import network from '../static/images/images/user-group-solid.svg';
import card from '../static/images/images/id-card-regular.svg';
import camera from '../static/images/images/camera-solid.svg';
import route from '../static/images/images/route-solid.svg';
import dollar from '../static/images/images/dollar-sign-solid.svg';
import jar from '../static/images/images/jar-solid.svg';
import road from '../static/images/images/road-solid_r.svg';
import motorcycle from '../static/images/images/motorcycle-solid-r.svg';
import handshake from '../static/images/images/handshake-simple-solid.svg';
import mountain from '../static/images/images/mountain-city-solid.svg';
import arrow from '../static/images/images/arrow-right-solid.svg';
import apple from '../static/images/images/download-on-the-app-store-apple-logo-svgrepo-com.svg';
import google from '../static/images/images/google-play-badge-logo-svgrepo-com.svg';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
import blogOne from '../static/images/images/motorcyk-motorcycle-blog-one2.jpg';
import blogTwo from '../static/images/images/mototrcyk-motorcyclist-rider-blog-two.jpg';
import blogThree from '../static/images/images/motorcyk-motorcycle-community.jpg';
import blogFour from '../static/images/images/motorcyk-electric-motorcycle.jpg';
import blogFive from '../static/images/images/motorcyk-motorcycle-ducati-profile-main.jpg';
import Instagram from '../static/images/images/instagram.svg';
import Facebook from '../static/images/images/facebook.svg';
import Twitter from '../static/images/images/twitter.svg';
import Pinterest from '../static/images/images/pinterest.svg';
import Youtube from '../static/images/images/youtube.svg';
// import Rumble from '../static/images/images/rumble.svg';
class Welcome extends Component {
    constructor() {
        super()
        this.state = {
            display: '',
            isLoading: true,
            redirect: false,
            signed_in: localStorage.getItem('token') ? true : false,
            events: [],
            events_error: false,
        };
        this.events = this.events.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then(() => { this.setState({ redirect: true }); })
            .catch(err => { console.log(err, "You are not logged in or something went wrong with fetching data!") });
        await axios.get(process.env.REACT_APP_SERVER_API + '/welcome')
            .then((response) => { this.setState({ events: response.data.events, isLoading: false }) })
            .catch((err) => { console.log(err, "Error retrieving events"); this.setState({ event_error: true, isLoading: false }) })
    };
    events() {
        const Event = (props) => (
            <Link to="/signin"> 
                <div className="event-box"> 
                    <p className="event-date">{ props.event.formatted_date}</p>
                    <p className="event-city">{ props.event.city }</p>
                    <p className="event-title"><strong>{ props.event.title }</strong></p>
                </div>
            </Link>
        );
        return this.state.events.map(event => {
            return  <Event event={event} key={event.id} />
        });
    };
    render() {
        if(this.state.redirect === true) {
            return <Redirect to={{pathname: "/home"}}/>
        };
        let events;
        events = this.state.isLoading?'':this.state.events.length < 6? 
        <div>
            <Link to="/signin">
                <div className="event-box"> 
                    <p className="event-date">May 2023</p>
                    <p className="event-city">Santa Monica</p>
                    <p className="event-title">SUNSET TO PACIFIC COAST</p>
                </div>
            </Link>
            <Link to="/signin">
                <div className="event-box"> 
                    <p className="event-date">May 2023</p>
                    <p className="event-city">San Jose</p>
                    <p className="event-title">San Felipe Road</p>
                </div>
            </Link>
            <Link to="/signin">
                <div className="event-box"> 
                    <p className="event-date">July 2023</p>
                    <p className="event-city">New York</p>
                    <p className="event-title">The Catskill Preserve</p>
                </div>
            </Link>
            <Link to="/signin">
                <div className="event-box"> 
                    <p className="event-date">April 2023</p>
                    <p className="event-city">Austin</p>
                    <p className="event-title">Killeen Twisty Getaway</p>
                </div>
            </Link>
            <Link to="/signin">
                <div className="event-box"> 
                    <p className="event-date">March 2023</p>
                    <p className="event-city">Nashville</p>
                    <p className="event-title">Cumberland Plateau Tour</p>
                </div>
            </Link>
            <Link to="/signin">
                <div className="event-box"> 
                    <p className="event-date">April 2023</p>
                    <p className="event-city">Napa</p>
                    <p className="event-title">Berryessa Lake Meet</p>
                </div>
            </Link>
        </div>:
        this.events();
        const body = this.state.isLoading?<Loading />: 
        <div className="container">
            <div className="header">
                <h5 className="header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="welcome-header-items">
                    <li><Link to="/signin" className="welcome-header-item links"> Sign In </Link></li>
                    <li><Link to="/signup" className="welcome-header-item links"> Sign Up</Link></li>
                </ul>
            </div>
            <div className="main-image">
                <p className="image-quote">Let's ride.</p>
                <Link to="/signup" className="links w"><button className="image-button mobile-hide">Sign Up</button></Link>
            </div>
            <p className="upcoming-event-section-title">Upcoming Rides</p>
            <div className="upcoming-event-section-contianer">
                <div className="upcoming-event-section">
                    { events }
                    <Link to="/signin"><button className="upcoming-event-section-button hide-tablet hide-desktop">Sign In to view more!</button></Link>
                </div>
            </div>
            <div className="how-it-works-section">
                <p className="how-it-works-section-title">How it works</p>
                <div className="discover">
                    <p className="discover-title r"> <img className="discover-icon" src={discover} alt="" /> <strong>Discover Riding Events</strong> <img className="discover-icon" src={route} alt="" style={{height: "35px"}}/></p>
                    <p className="discover-content">Explore available riding events in your desired location and join in or just stream <span style={{fontSize: "10px", marginBottom: "5px"}}>(Coming Soon)</span> the open road with other riders.</p>
                    <p className="discover-link"><Link  to="signup" className="links r">Join <strong className="marker">Motorcyk</strong> <img className="arrow-icon" src={arrow} alt="" /></Link></p>
                </div>
                <div className="create">
                    <p className="create-title r"><img className="create-icon r" src={create} alt="" /> <strong>Start A Riding Event</strong> <img className="create-icon r" src={road} alt="" /></p>
                    <p className="create-content">Create your own open, private or followers only type of riding event, and draw from a riding community of thousands.</p>
                    <p className="create-link"><Link  to="signup" className="links r">Join To Get Started <img className="arrow-icon" src={arrow} alt="" /></Link></p>
                </div>
                <div className="create">
                    <p className="create-title r"><img className="create-icon r" src={camera} alt="" /> Rider Boards <img className="create-icon r" src={mountain} alt="" /></p>
                    <p className="create-content">Share pictures from your riding adventures and inspire other riders and enthusiasts.</p>
                    <p className="create-link"><Link  to="signup" className="links r">Post Your First Adventure <img className="arrow-icon" src={arrow} alt="" /></Link></p>
                </div>
                <div className="create">
                    <p className="create-title r"><img className="create-icon r" src={network} alt="" /> Rider Profile <img className="create-icon r" src={card} alt="" /></p>
                    <p className="create-content">Create your rider profile, and build your following from a riding community of thousands.</p>
                    <p className="discover-link"><Link  to="signup" className="links r">Begin Your Journey <img className="arrow-icon" src={arrow} alt="" /></Link></p>
                </div>
                <div className="create">
                    <p className="create-title r"><img className="create-icon r" style={{height: "25px", }} src={dollar} alt="" /> Generate Money <img className="create-icon r" style={{height: "25px", }} src={jar} alt="" /></p>
                    <p className="create-content">Build your rider profile with amazing content and watch your tip jar grow.</p>
                    <p className="create-link"><Link  to="signup" className="links r">Start Today <img className="arrow-icon" src={arrow} alt="" /></Link></p>
                </div>
                <div className="create">
                    <p className="create-title r"><img className="create-icon r" src={motorcycle} alt="" /> Network <img className="create-icon r" src={handshake} alt="" /></p>
                    <p className="create-content">Network, meet and ride with other riders and explore the open road together.</p>
                    <p className="discover-link"><Link  to="signup" className="links r">Join And Begin Networking <img className="arrow-icon" src={arrow} alt="" /></Link></p>
                </div>
                <Link to="/signup"><button className="upcoming-event-section-button hide-tablet hide-desktop">Sign Up To Get Started!</button></Link>
            </div>
            <div className="how-it-works-section">
                <h4 className="how-it-works-section-title b">Blogs</h4>
                <div className="welcomecs-footer-blogs-container">

                    <a className="no-dec" href="https://motorcyk-23657968.hubspotpagebuilder.com/motorcyk-motorcycle-apps/top-6-reasons-why-motorcyk-will-be-the-go-to-platform-for-motorcycle-riders" target="_blank" rel="noopener noreferrer">
                        <div className="welcomecs-blog-box">
                            <img className="welcomecs-blog-image" src={blogTwo} alt="" />
                            <p className="welcomecs-blog-title">Top 6 Reasons Why Motorcyk Will Be <br/>A Riders Go To Platform</p>
                        </div>
                    </a>

                    <a className="no-dec" href="https://motorcyk-23657968.hubspotpagebuilder.com/motorcyk-motorcycle-apps/why-most-motorcycle-apps-arent-really-helpful-or-useful" target="_blank" rel="noopener noreferrer">
                        <div className="welcomecs-blog-box">
                            <img className="welcomecs-blog-image" src={blogOne} alt="" />
                            <p className="welcomecs-blog-title">Why Most Motorcycle Apps Aren’t <br/>Really Helpful Or Useful</p>
                        </div>
                    </a>

                    <a className="no-dec" href="https://motorcyk-23657968.hubspotpagebuilder.com/motorcyk-motorcycle-apps/grow-your-network-in-the-motorcycle-world" target="_blank" rel="noopener noreferrer">
                        <div className="welcomecs-blog-box">
                            <img className="welcomecs-blog-image" src={blogThree} alt="" />
                            <p className="welcomecs-blog-title">Grow Your Network In The Motorcycle <br/>World</p>
                        </div>
                    </a>

                    <a className="no-dec" href="https://motorcyk-23657968.hubspotpagebuilder.com/motorcyk-motorcycle-apps/whether-you-ride-a-gas-or-electric-motorcycle" target="_blank" rel="noopener noreferrer">
                        <div className="welcomecs-blog-box">
                            <img className="welcomecs-blog-image" src={blogFour} alt="" />
                            <p className="welcomecs-blog-title">Whether You Ride A Gas Or Electric <br/>Motorcycle...</p>
                        </div>
                    </a>

                    <a className="no-dec" href="https://motorcyk-23657968.hubspotpagebuilder.com/motorcyk-motorcycle-apps/motorcyk-the-motorcycle-app-every-rider-and-enthusiast-needs" target="_blank" rel="noopener noreferrer">
                        <div className="welcomecs-blog-box">
                            <img className="welcomecs-blog-image" src={blogFive} alt="" />
                            <p className="welcomecs-blog-title">Motorcyk | The Motorcycle App <br/>Every Rider And Enthusiast Needs</p>
                        </div>
                    </a>
                </div>
            </div>
            <div className="welcome-footer-container">
                <div className="welcome-footer-become-member-container">
                    <p className="welcome-footer-statement" >Ready to become a member? <Link to={"/signup"} className="no-dec"><button className="welcome-footer-button">Begin</button></Link></p>
                </div>
                <div className="welcome-footer-list-container">
                    <ul className="welcome-footer-list-one">
                        <p className="welcome-footer-list-title"><u>ACCOUNT</u></p>
                        <li className="welcome-footer-list-item"><Link to={"/signup"} className="welcome-footer-list-item no-dec w">Sign Up</Link></li>
                        <li className="welcome-footer-list-item"><Link to={"/signin"} className="welcome-footer-list-item no-dec w">Sign In</Link></li>
                        <li className="welcome-footer-list-item"><Link to={"/signin"} className="welcome-footer-list-item no-dec w">Help</Link></li>
                    </ul>
                    <ul className="welcome-footer-list-two">
                        <p className="welcome-footer-list-title"><u>MOTORCYK</u></p>
                        <li className="welcome-footer-list-item"><Link to={"/about-read"} className="welcome-footer-list-item no-dec w">About</Link></li>
                        <li className="welcome-footer-list-item"><Link to={"/signin"} className="welcome-footer-list-item no-dec w">Create</Link></li>
                        <li className="welcome-footer-list-item"><Link to={"/signin"} className="welcome-footer-list-item no-dec w">Explore</Link></li>
                        <li className="welcome-footer-list-item"><Link to={"/signin"} className="welcome-footer-list-item no-dec w">Network</Link></li>
                    </ul>
                    <ul className="welcome-footer-list-three">
                        <p className="welcome-footer-list-title"><u>QUESTIONS</u></p>
                        <li className="welcome-footer-list-item welcome-footer-list-item-question">Email us at <br className="footer-break"/> hello@motorcyk.com</li>
                    </ul>
                </div>
                <div className="welcome-footer-follow-soon-container">
                    <div className="welcome-footer-follow-soon-icons-container">
                        <p className="welcome-footer-follow-soon-icons-title"><u>FOLLOW US AND SHARE YOUR ADVENTURES</u></p>
                        <div className="welcome-footer-follow-soon-icons">
                            <a className="welcome-footer-follow-soon-icon" href="https://www.instagram.com/motorcyk/" target="_blank" rel="noopener noreferrer"><img className="welcome-footer-follow-social-icon" src={Instagram} alt=""/></a> 
                            <a className="welcome-footer-follow-soon-icon" href="https://www.facebook.com/motorcyk" target="_blank" rel="noopener noreferrer"><img className="welcome-footer-follow-social-icon" src={Facebook} alt=""/></a> 
                            <a className="welcome-footer-follow-soon-icon" href="https://twitter.com/motorcyk" target="_blank" rel="noopener noreferrer"><img className="welcome-footer-follow-social-icon" src={Twitter} alt=""/></a> 
                            <a className="welcome-footer-follow-soon-icon" href="https://www.pinterest.com/motorcyk" target="_blank" rel="noopener noreferrer"><img className="welcome-footer-follow-social-icon" src={Pinterest} alt=""/></a> 
                            <a className="welcome-footer-follow-soon-icon" href="https://www.youtube.com/channel/UC74mB3afir3CvrF3NdDjc2w" target="_blank" rel="noopener noreferrer"><img className="welcome-footer-follow-social-icon" src={Youtube} alt=""/></a> 
                            {/* <a className="welcome-footer-follow-soon-icon" href="https://rumble.com/c/c-2134132" target="_blank" rel="noopener noreferrer"><img className="welcome-footer-follow-social-icon" src={Rumble} alt=""/></a>  */}
                        </div>
                    </div>
                    <div className="welcome-footer-follow-soon-store-container">
                        <p className="welcome-footer-follow-soon-icons-title welcome-footer-follow-soon-icons-title-comming"><u>COMING SOON</u></p>
                        <img className="welcome-footer-follow-soon-icons-google" src={google} alt="" /> <img className="welcome-footer-follow-soon-icons-apple" src={apple} alt="" />
                    </div>
                </div>
                <p className="welcome-footer-bottom-title"><span className="welcome-footer-bottom-title-title">2023 Motorcyk LLC</span><Link to={"/about-read"} className="welcome-footer-bottom-title-link no-dec">About</Link><Link to={"/terms-of-service-read"} className="welcome-footer-bottom-title-link no-dec">Terms Of Service</Link><Link to={"/privacy-read"} className="welcome-footer-bottom-title-link no-dec">Privacy</Link><Link to={"/cookies-read"} className="welcome-footer-bottom-title-link no-dec">Cookie</Link></p>
            </div>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default Welcome;

