import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import '../static/css/Following.css';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import userPlus from '../static/images/images/user-plus-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class Following extends Component {
    constructor(props) {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            isLoading: true,
            redirect: false,
            ui: null,
            image_url: null,
            user_profile: props.match.params.id,
            following: [],
            end: null,
            cancelled: false,
            trial_used: false,
        };
        this.following = this.following.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then((response) => this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, }))
            .catch((err) => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        const id = this.state.user_profile
        await axios.get(process.env.REACT_APP_SERVER_API + `/user/following/${id}`)
            .then((response) => { this.setState({ following: response.data, }) })  
            .catch( (err) => { console.log(err, "ERROR WITH RETRIEVING FOLLOWING") })
        const config_sub = {
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
        };
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    active: response.data.status,
                    end: response.data.date,
                    cancelled: response.data.status === "canceled" ? true : false,
                }) 
                var end_date = new Date(response.data.date);
                var current_date = new Date();
                if (response.data.cancelled) {
                    if (end_date.setHours(0,0,0,0) < current_date.setHours(0,0,0,0)) {
                        this.setState({ active: response.data.status, end: response.data.date, }); 
                    };
                };
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data, isLoading: false  })})
            .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
    };
    following () {
        const Rider = (props) => (
            <div className="following-profile-container-user">
                <Link to={props.following.display_name?`/@${props.following.display_name}`:`/rider/${props.following.user_id}`} className="following-user-icon-link b" >
                    <img className="following-user-icon" src={props.following.image_url ? props.following.image_url : user} alt="" />
                    <p className="following-text-name-user r">{props.following.display_name ? props.following.display_name : props.following.first_name}</p>
                </Link>
            </div>
        );
        return this.state.following.map(i => {
            return <Rider following={i} key={i.id} />
        });
    };
    render() {
        let riders;
        let active;
        let inactive_statement;
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        if (this.state.following.length > 0) riders = this.state.isLoading ? '' : this.following();
        else riders = '' 
        let end_date = new Date(this.state.end);
        let current_date = new Date();
        if ((this.state.active === true) || (this.state.cancelled === true && end_date.setHours(0,0,0,0) > current_date.setHours(0,0,0,0))) {
            active = 
                <Link to="/search-rider" className="following-add-icon-link b" >
                    <img className="following-add-icon" src={userPlus} alt="" />
                    <p className="following-text-add-name r">Add</p>
                </Link>
        } else {
            active = 
                <Link to="/search-rider" className="following-add-icon-link b" >
                    <img className="following-add-icon" src={userPlus} alt="" />
                    <p className="following-text-add-name r">Add</p>
                </Link>
            // active = 
            //     <Link to="#" className="following-add-icon-link b" >
            //         <img className="following-add-icon" src={userPlus} alt="" />
            //         <p className="following-text-add-name r">Add</p>
            //     </Link>
            // inactive_statement = 
            //     <div style={{textAlign: "center", marginBottom: "20px"}}>
            //         <p>You must have an active membership to follow others! <br/> Please activate your membership!</p>
            //         <Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="no-dec"><button className="edit-profile-form-button back">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link>
            //     </div>

        };
        const body = this.state.isLoading?<Loading />: 
        <div className="following-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    <li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/settings" className="home-settings-link no-dec b">Settings</Link></li>
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                </ul>
            </div>
            <h1 className="following-title r">Following</h1>
            { inactive_statement }
            <div className="following-user-icons-container">
                <div className="following-profile-container-add">
                    { active }
                </div>
                { riders }
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default Following;