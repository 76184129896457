import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import '../static/css/EditProfile.css';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class EditProfile extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            status: '',
            isLoading: true,
            redirect: false,
            ui: null,
            user: '',
            image_url: null,
            first_name: '',
            first_name_required: '',
            first_name_min: '',
            first_name_max: '',
            first_name_valid: '',
            display_name: null,
            display_name_error: '',
            display_name_max_error: '',
            display_name_exists_error: '',
            zip_code: '',
            zip_code_error: '',
            profile_header_message: '',
            profile_header_message_error: '',
            instagram: null,
            facebook: null,
            twitter: null,
            tiktok: null,
            pinterest: null,
            snapchat: null,
            youtube: null,
            rumble: null,
            tumblr: null,
            disabled: false,
            end: null,
            cancelled: false,
            message_unread: false,
            trial_used: false,
            non_active: false,
        };
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeZipCode = this.onChangeZipCode.bind(this);
        this.onChangeProfileHeaderMessage = this.onChangeProfileHeaderMessage.bind(this);
        this.onChangeDisplayName = this.onChangeDisplayName.bind(this);
        this.onChangeInstagram = this.onChangeInstagram.bind(this);
        this.onChangeFacebook = this.onChangeFacebook.bind(this);
        this.onChangeTwitter = this.onChangeTwitter.bind(this);
        this.onChangeTiktok = this.onChangeTiktok.bind(this);
        this.onChangePinterest = this.onChangePinterest.bind(this);
        this.onChangeSnapchat = this.onChangeSnapchat.bind(this);
        this.onChangeYoutube = this.onChangeYoutube.bind(this);
        this.onChangeRumble = this.onChangeRumble.bind(this);
        this.onChangeTumblr = this.onChangeTumblr.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, }) })
            .catch(err => { this.setState({ redirect: true }) });
        const config_sub = { headers: { Authorization: `Bearer ${token}` }, id: this.state.ui, };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
                return response.data;
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })

        if (this.state.status.active===true) {
            await axios.get(process.env.REACT_APP_SERVER_API + `/myprofile/edit/${this.state.ui}`)
                .then( (response) => {
                    this.setState({ 
                        user: response.data,
                        first_name: response.data.first_name,
                        zip_code: response.data.zip_code === null ? '' : response.data.zip_code,
                        profile_header_message: response.data.profile_header_message,
                        display_name: response.data.display_name,
                        isLoading: false,
                        instagram: response.data.instagram === null ? '' : response.data.instagram,
                        facebook: response.data.facebook === null ? '' : response.data.facebook,
                        twitter: response.data.twitter === null ? '' : response.data.twitter,
                        tiktok: response.data.tiktok === null ? '' : response.data.tiktok,
                        pinterest: response.data.pinterest === null ? '' : response.data.pinterest,
                        snapchat: response.data.snapchat === null ? '' : response.data.snapchat,
                        youtube: response.data.youtube === null ? '' : response.data.youtube,
                        rumble: response.data.rumble === null ? '' : response.data.rumble,
                        tumblr: response.data.tumblr === null ? '' : response.data.tumblr,
                    }); 
                })
                .catch( (err) => {console.log(err, "Error in retreiving  user details!"); window.location = "/settings"})
        } else {
            await axios.get(process.env.REACT_APP_SERVER_API + `/myprofile/edit/${this.state.ui}`)
                .then( (response) => {
                    this.setState({ 
                        user: response.data,
                        first_name: response.data.first_name,
                        zip_code: response.data.zip_code === null ? '' : response.data.zip_code,
                        profile_header_message: response.data.profile_header_message,
                        display_name: response.data.display_name,
                        isLoading: false,
                        non_active: true,
                        instagram: response.data.instagram === null ? '' : response.data.instagram,
                        facebook: response.data.facebook === null ? '' : response.data.facebook,
                        twitter: response.data.twitter === null ? '' : response.data.twitter,
                        tiktok: response.data.tiktok === null ? '' : response.data.tiktok,
                        pinterest: response.data.pinterest === null ? '' : response.data.pinterest,
                        snapchat: response.data.snapchat === null ? '' : response.data.snapchat,
                        youtube: response.data.youtube === null ? '' : response.data.youtube,
                        rumble: response.data.rumble === null ? '' : response.data.rumble,
                        tumblr: response.data.tumblr === null ? '' : response.data.tumblr,
                    }); 
                })
                .catch( (err) => {console.log(err, "Error in retreiving  user details!"); window.location = "/settings"})
        }
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status, }); })
            .catch((err) => { console.log(err, "Error checking for message status!"); })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data, isLoading: false, })})
            .catch((err) => {console.log(err, "my error..."); this.setState({ isLoading: false, }) })
    };
    onChangeFirstName(event) {
        this.setState({
            first_name: event.target.value
        });
    };
    onChangeZipCode(event) {
        this.setState({
            zip_code: event.target.value
        });
    };
    onChangeDisplayName(event) {
        this.setState({
            display_name: event.target.value
        });
    };
    onChangeInstagram(event) {
        this.setState({
            instagram: event.target.value
        });
    };
    onChangeFacebook(event) {
        this.setState({
            facebook: event.target.value
        });
    };
    onChangeTwitter(event) {
        this.setState({
            twitter: event.target.value
        });
    };
    onChangeTiktok(event) {
        this.setState({
            tiktok: event.target.value
        });
    };
    onChangePinterest(event) {
        this.setState({
            pinterest: event.target.value
        });
    };
    onChangeSnapchat(event) {
        this.setState({
            snapchat: event.target.value
        });
    };
    onChangeYoutube(event) {
        this.setState({
            youtube: event.target.value
        });
    };
    onChangeRumble(event) {
        this.setState({
            rumble: event.target.value
        });
    };
    onChangeTumblr(event) {
        this.setState({
            tumblr: event.target.value
        });
    };
    onChangeProfileHeaderMessage(event) {
        this.setState({
            profile_header_message: event.target.value
        });
    };
    async onSubmit(event) {
        event.preventDefault();
        this.setState({ 
            disabled: true
        });
        const updates = {
            id: this.state.ui,
            first_name: this.state.first_name,
            zip_code: this.state.zip_code,
            display_name: this.state.display_name,
            profile_header_message: this.state.profile_header_message,
            instagram: this.state.instagram,
            facebook: this.state.facebook,
            twitter: this.state.twitter,
            tiktok: this.state.tiktok,
            pinterest: this.state.pinterest,
            snapchat: this.state.snapchat,
            youtube: this.state.youtube,
            rumble: this.state.rumble,
            tumblr: this.state.tumblr,
        }
        if (this.state.signed_in) {
            await axios.put(process.env.REACT_APP_SERVER_API + '/myprofile/update', updates)
                .then(() => {alert('Profile updated! 🚀')})
                .then(() => { window.location.reload(true) })
                .catch(err => {
                    this.setState({
                        first_name_required: err.response.data.first_name_required,
                        first_name_min: err.response.data.first_name_min,
                        first_name_max: err.response.data.first_name_max,
                        first_name_valid: err.response.data.first_name_valid,
                        zip_code_error: err.response.data.zip_code_error,
                        profile_header_message_error: err.response.data.profile_header_message_error,
                        display_name_error: err.response.data.display_name_error,
                        display_name_max_error: err.response.data.display_name_max_error,
                        display_name_exists_error: err.response.data.display_name_exists_error,
                        disabled: false
                    });
                });
        } else {
            window.location = '/signin';
        };
    };
    sign_out = () => {
        localStorage.removeItem('token');
        this.setState({ signed_in: false, FirstName: '' })
    }
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let form;
        if (this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true)) {
            form =
                <form onSubmit={this.onSubmit}>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
                    <p><strong><u>Profile Details</u></strong></p>
                    <p className="edit-profile-error-message">{ this.state.first_name_required }</p>
                    <p className="edit-profile-error-message">{ this.state.first_name_min }</p>
                    <p className="edit-profile-error-message">{ this.state.first_name_max }</p>
                    <p className="edit-profile-error-message">{ this.state.first_name_valid }</p>
                    <input className="edit-profile-form-input" type="text" placeholder={ "First Name" } value={ this.state.first_name } onChange={ this.onChangeFirstName }/>

                    <p className="edit-profile-error-message">{ this.state.zip_code_error }</p>
                    <input className="edit-profile-form-input" type="text" placeholder={ "Zip Code" } value={ this.state.zip_code } onChange={ this.onChangeZipCode }/>

                    {/* <input className="edit-profile-form-input" type="text" placeholder="Experience Level"/> */}

                    <p className="edit-profile-error-message">{ this.state.profile_header_message_error }</p>
                    <input className="edit-profile-form-input" type="text" placeholder={ "Profile Header Message" } value={ this.state.profile_header_message } onChange={ this.onChangeProfileHeaderMessage }/>

                    <p className="edit-profile-error-message">{ this.state.display_name_error }</p>
                    <p className="edit-profile-error-message">{ this.state.display_name_max_error }</p>
                    <p className="edit-profile-error-message">{ this.state.display_name_exists_error }</p>
                    <input className="edit-profile-form-input" type="text" placeholder={ "Display Name" } value={ this.state.display_name } onChange={ this.onChangeDisplayName }/>
                    <br />
                    <p><strong><u>Add Your Socials</u></strong></p>
                    <div className="edit-profile-form-input-socials-container">
                        <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.instagram.com/</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "YourUserName" } value={ this.state.instagram } onChange={ this.onChangeInstagram }/></div>
                        <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.facebook.com/</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "PageName" } value={ this.state.facebook } onChange={ this.onChangeFacebook }/></div>
                        <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.twitter.com/</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "YourUserName" } value={ this.state.twitter } onChange={ this.onChangeTwitter }/></div>
                        <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.tiktok.com/@</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "YourUserName" } value={ this.state.tiktok } onChange={ this.onChangeTiktok }/></div>
                        <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.pinterest.com/</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "UserName" } value={ this.state.pinterest } onChange={ this.onChangePinterest }/></div>
                        <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.snapchat.com/add/</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "YourUserName" } value={ this.state.snapchat } onChange={ this.onChangeSnapchat }/></div>
                        <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.youtube.com/@</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "YourChannelName" } value={ this.state.youtube } onChange={ this.onChangeYoutube }/></div>
                        <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.rumble.com/</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "YourChannelName" } value={ this.state.rumble } onChange={ this.onChangeRumble }/></div>
                        <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.tumblr.com/</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "YourUserName" } value={ this.state.tumblr } onChange={ this.onChangeTumblr }/></div>
                    </div>
                    <button className="edit-profile-form-button" disabled={this.state.disabled ? true : false}>{this.state.disabled ? 'Updating...' : 'Update'}</button>
                    <Link to="/settings" className="no-dec"><button className="edit-profile-form-button back">Cancel</button></Link>
                </form>
        } else {
            form = 
                <div>
                    {/* <p style={{fontSize: "15px", width: "90%", margin: "10px auto"}}>Looks like you don't have an active membership, please activate your membership to use Motorcyk's full features.</p>
                    <Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="no-dec" style={{marginBottom: "50px"}}><button className="edit-profile-form-button back">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link>
                    <hr style={{marginTop: "50px", width: "80%", opacity: ".50"}}/> */}
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
                    <form onSubmit={this.onSubmit} style={{marginTop: "50px"}}>
                        <p><strong><u>Profile Details</u></strong></p>
                        <p className="edit-profile-error-message">{ this.state.first_name_required }</p>
                        <p className="edit-profile-error-message">{ this.state.first_name_min }</p>
                        <p className="edit-profile-error-message">{ this.state.first_name_max }</p>
                        <p className="edit-profile-error-message">{ this.state.first_name_valid }</p>
                        <input className="edit-profile-form-input" type="text" placeholder={ "First Name" } value={ this.state.first_name } onChange={ this.onChangeFirstName }/>

                        <p className="edit-profile-error-message">{ this.state.zip_code_error }</p>
                        <input className="edit-profile-form-input" type="text" placeholder={ "Zip Code" } value={ this.state.zip_code } onChange={ this.onChangeZipCode }/>

                        {/* <input className="edit-profile-form-input" type="text" placeholder="Experience Level"/> */}

                        <p className="edit-profile-error-message">{ this.state.profile_header_message_error }</p>
                        <input className="edit-profile-form-input" type="text" placeholder={ "Profile Header Message" } value={ this.state.profile_header_message } onChange={ this.onChangeProfileHeaderMessage }/>

                        <p className="edit-profile-error-message">{ this.state.display_name_error }</p>
                        <p className="edit-profile-error-message">{ this.state.display_name_max_error }</p>
                        <p className="edit-profile-error-message">{ this.state.display_name_exists_error }</p>
                        <input className="edit-profile-form-input" type="text" placeholder={ "Display Name" } value={ this.state.display_name } onChange={ this.onChangeDisplayName }/>
                        <br />
                        <p><strong><u>Add Your Socials</u></strong></p>
                        <div className="edit-profile-form-input-socials-container">
                            <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.instagram.com/</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "YourUserName" } value={ this.state.instagram } onChange={ this.onChangeInstagram }/></div>
                            <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.facebook.com/</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "PageName" } value={ this.state.facebook } onChange={ this.onChangeFacebook }/></div>
                            <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.twitter.com/</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "YourUserName" } value={ this.state.twitter } onChange={ this.onChangeTwitter }/></div>
                            <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.tiktok.com/@</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "YourUserName" } value={ this.state.tiktok } onChange={ this.onChangeTiktok }/></div>
                            <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.pinterest.com/</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "UserName" } value={ this.state.pinterest } onChange={ this.onChangePinterest }/></div>
                            <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.snapchat.com/add/</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "YourUserName" } value={ this.state.snapchat } onChange={ this.onChangeSnapchat }/></div>
                            <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.youtube.com/@</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "YourChannelName" } value={ this.state.youtube } onChange={ this.onChangeYoutube }/></div>
                            <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.rumble.com/</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "YourChannelName" } value={ this.state.rumble } onChange={ this.onChangeRumble }/></div>
                            <div className="edit-profile-form-input-social-container"><label className="edit-profile-form-input-social-label">www.tumblr.com/</label><input className="edit-profile-form-input-socials" type="text" placeholder={ "YourUserName" } value={ this.state.tumblr } onChange={ this.onChangeTumblr }/></div>
                        </div>
                        <button className="edit-profile-form-button" disabled={this.state.disabled ? true : false}>{this.state.disabled ? 'Updating...' : 'Update'}</button>
                        <Link to="/settings" className="no-dec"><button className="edit-profile-form-button back">Cancel</button></Link>
                    </form>
                </div>
        };
        const body = this.state.isLoading?<Loading />: 
        <div className="edit-profile-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    {this.state.non_active===true?<li ><Link to="/" onClick={this.sign_out} className="home-home-link no-dec b">Sign Out</Link></li>:''}
                    <li><Link to="/settings" className="home-settings-link no-dec b">Settings</Link></li>
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}
                </ul>
            </div>
            <div className="edit-profile-title-container">
                <h1 className="edit-profile-title">Edit Profile</h1>
            </div>
            <div className="edit-profile-edit-section">
                { form }
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default EditProfile;