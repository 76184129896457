import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import '../static/css/SignUpConfirmation.css';
import logoIcon from '../static/images/images/logo_icon.png';
class SignUpConfirmation extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            ui: null,
        };
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then((response) => this.setState({ ui: response.data.id}))
            .catch((err) => {
                console.log("You are not logged in or something went wrong with fetching data!", err)
                this.setState({ redirect: true });
            });
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/"}}/>
        return (
            <div className="signup-container confirmation">
                <img className="signup-logo-icon" src={logoIcon} alt="" />
                <p className="signup-message w">
                    Thank you and welcome to Motorcyk! <span role="img" aria-label="">🎊 🥳 🎉</span>
                    <br /><br />
                    We are happy to have you as a member of Motorcyk and look forward in seeing your adventures! <span role="img" aria-label="">🛣 🏍💨</span>
                    <br /><br />
                    <span role="img" aria-label="">🏁</span> To get started, we recommend do one of these first:<br/><br/>
                    {/* - Read the platform basics <span role="img" aria-label="">👉</span> <Link className="no-dec" to={`/rules`}><button className="signup-create-event">Read</button></Link><br/> */}
                    - Checkout Rules / Guide <span role="img" aria-label="">👉</span> <Link className="no-dec" to={`/rules`}><button className="signup-create-event">Rules</button></Link><br/>
                    - Add your social profiles <span role="img" aria-label="">👉</span> <Link className="no-dec" to={`/edit-profile/${this.state.ui}`}><button className="signup-create-event">Add</button></Link><br/>
                    - Enable Tip Jar <span role="img" aria-label="">👉</span> <Link className="no-dec" to={`/rider-tip-jar`}><button className="signup-create-event">Enable</button></Link><br/>
                    - Create/Host a riding event <span role="img" aria-label="">👉</span> <Link className="no-dec" to="/host"><button className="signup-create-event">Create</button></Link><br/><br/>
                    Or just continue to the home page and explore the platform!
                    <br /><br />
                    Enjoy and ride safe! <span role="img" aria-label="">😎</span>
                </p>
                <Link to="/home" className="signup-continue no-dec"><button className="signup-continue">Continue</button></Link>
            </div>
        );
    };
};
export default SignUpConfirmation;