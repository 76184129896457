import React, { Component } from 'react';
import Loading from '../components/LoadingPage';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import '../static/css/UploadPost.css';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
import ImageUploading from 'react-images-uploading';
class UploadPost extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            status: '',
            redirect: false,
            ui: null,
            image_url: null,
            file_name: null,
            new_image: null,
            title: '',
            disabled: false,
            active: false,
            end: null,
            cancelled: false,
            trial_used: false,
            missing_image_error: false,
        };
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, }); })
            .catch(err => {
                this.setState({ redirect: true });
            });
        const config_sub = { headers: { Authorization: `Bearer ${token}`, }, id: this.state.ui, };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { this.setState({ status: response.data, }); })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .then(()=> { this.setState({ isLoading: false }) })
            .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
    };
    onChange = (picture) => {
        this.setState({new_image: picture, file_name: picture[0].file.name});
    };
    onChangeTitle(e) {
        this.setState({ title: e.target.value });
    };
    async onSubmit(e) {
        e.preventDefault();
        if (this.state.new_image === null) {
            this.setState({ missing_image_error: true })
        } else {
            this.setState({ disabled: true, isLoading: true, });
            const image = this.state.new_image;
            const data = new FormData();
            data.append('image', image[0].file, image[0].file.name);
            data.append('ui', this.state.ui);
            data.append('title', this.state.title)
            const config = { headers: { 'content-type': 'multipart/form-data' }, };
            if (this.state.signed_in) {
                await axios.post(process.env.REACT_APP_SERVER_API + '/user/post/image', data, config)
                    .then(() => { })
                    .catch((err) => { console.log(err, "Issue with processing post!"); this.setState({ disabled: false }); })
                alert("Image uploaded! 📸");
                window.location = `/profile/${this.state.ui}`;
            } else {
                window.location = '/signin';
            };
        }
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let display;
        if (this.state.status.active === true) {
            display = 
                <div className="upload-post-section">
                    <form onSubmit={this.onSubmit}>
                        <p style={{margin: "10px auto", marginTop: "50px", width: "100%", textAlign: "center"}}>{this.state.file_name}</p>
                        <div className="edit-profile-image-icon-container">
                            <ImageUploading
                                multiple
                                value={this.state.file_name}
                                onChange={this.onChange}
                                // onChange={this.onDrop}
                                maxNumber={1}
                                dataURLKey="data_url"
                                acceptType={["jpg", "jpeg", "heic", "png", "gif", "webp", "heif"]}
                                allowNonImageType={true}
                            >
                                {({
                                onImageUpload,
                                isDragging,
                                dragProps,
                                }) => (
                                // write your building UI
                                <div className="upload__image-wrapper">
                                    <button
                                        type="button"
                                        className="edit-profile-image-button"
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                    >
                                    Click or Drop here
                                    </button>
                                    &nbsp;
                                </div>
                                )}
                            </ImageUploading>
                        </div>
                        <input className="upload-post-form-input" type="text" placeholder={"Title"} value={this.state.title} onChange={this.onChangeTitle}/> 
                        <button className="upload-post-form-button" disabled={this.state.disabled ? true : false}>{this.state.disabled ? 'Posting...' : 'Post'}</button>
                        <Link to={`/profile/${this.state.ui}`} className="no-dec upload-post-form-button"><button className="upload-post-form-button">Go Back</button></Link>
                    </form>
                </div>
        } 
        // else {
            // display = 
            //     <div className="upload-post-section">
            //         <p style={{textAlign: "center"}}>Looks like you don't have an active membership, please activate your membership to use Motorcyk's full features.</p>
            //         <Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }} className="no-dec"><button className="edit-profile-image-form-button back">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link>
            //         <hr style={{marginTop: "50px", width: "80%", opacity: ".50"}}/>
            //         <form onSubmit={this.onSubmit}>
            //             <p style={{margin: "10px auto", marginTop: "50px", width: "100%", textAlign: "center"}}>{this.state.file_name}</p>
            //             <div className="edit-profile-image-icon-container">
            //                 <ImageUploading
            //                     multiple
            //                     value={this.state.file_name}
            //                     onChange={this.onChange}
            //                     // onChange={this.onDrop}
            //                     maxNumber={1}
            //                     dataURLKey="data_url"
            //                     acceptType={["jpg", "jpeg", "heic", "png", "gif", "webp", "heif"]}
            //                     allowNonImageType={true}
            //                 >
            //                     {({
            //                     onImageUpload,
            //                     isDragging,
            //                     dragProps,
            //                     }) => (
            //                     // write your building UI
            //                     <div className="upload__image-wrapper">
            //                         <button
            //                             type="button"
            //                             className="edit-profile-image-button"
            //                             style={isDragging ? { color: 'red' } : undefined}
            //                             onClick={onImageUpload}
            //                             {...dragProps}
            //                         >
            //                         Click or Drop here
            //                         </button>
            //                         &nbsp;
            //                     </div>
            //                     )}
            //                 </ImageUploading>
            //             </div>
            //             <input className="upload-post-form-input" type="text" placeholder={"Title"} value={this.state.title} onChange={this.onChangeTitle}/> 
            //             <button className="upload-post-form-button" disabled={this.state.disabled ? true : false}>{this.state.disabled ? 'Posting...' : 'Post'}</button>
            //             <Link to={`/profile/${this.state.ui}`} className="no-dec upload-post-form-button"><button className="upload-post-form-button">Go Back</button></Link>
            //         </form>
            //     </div>
            // this.setState({redirect: true})
        // };
        const body = this.state.isLoading?<Loading />: 
        <div>
            <div className="home-header">
                <h5 className="home-header-title"><Link to="/" className="no-dec"><img className="header-logo" src={logo} alt="" /></Link></h5>
                <ul className="home-header-items no-dec mobile">
                    <li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/settings" className="home-settings-link no-dec b">Settings</Link></li>
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                </ul>
            </div>
            <div className="upload-post-title-container">
                <h1 className="upload-post-title">Upload A Post</h1>
            </div>
            {this.state.missing_image_error===true?<p className="r" style={{textAlign: "center"}}>Please select an image to post!</p>:''}
            { display }
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return(
            <div className="upload-post">{ body }</div>
        );
    };
};
export default UploadPost;