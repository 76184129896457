import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../components/LoadingPage'
import '../static/css/RiderHosting.css';
class RiderHosting extends Component {
    constructor(props) {
        super();
        this.state = {};
          this.events = this.events.bind(this);
    };
    events() {
        const Event = (props) => (
            <Link to={`/event/${props.event.id}`}>
                <div className="rider-hosting-component-event-box">
                    <p className="rider-hosting-component-date"><strong>{props.event.formatted_date} @ {props.event.formatted_time}</strong></p>
                    <p className="rider-hosting-component-title"><strong>{props.event.title}</strong></p>
                    <p className="rider-hosting-component-city">{props.event.city}, {props.event.state}</p>
                    <p className="rider-hosting-component-host">{props.event.share?'Shared':'Hosted'} by <strong>{this.props.data.display_name ? this.props.data.display_name : this.props.data.first_name}</strong></p>
                </div>
            </Link> 
        );
        return this.props.data.hosting_events.map(event => {
            return  <Event event={event} key={event.id} />
        });
    };
    render() {
        let events;
        let statement;
        if (!this.props.data.guest) {
            if (this.props.data.hosting_events.length === 0) {
                if (this.props.data.status.active === true) {
                    events =  <p className="rider-hosting-statement">{ this.props.data.display_name ? this.props.data.display_name : this.props.data.first_name } is currently not hosting anything at the moment! <span role="img" aria-label="">😔</span></p>
                } else {
                    events =  
                        <div>
                            <p className="rider-hosting-statement">You must have an active membership to view riding events!</p>
                            <Link to={{ pathname: this.props.data.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="no-dec"><button className="edit-profile-form-button back">{this.props.data.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link>
                        </div>
                }
            } else {
                events = this.state.isLoading ? '' : this.events()
            };
        } else {
            statement =
                <div>
                    <p className="rider-hosting-statement">Please sign in to view riders hosted events!</p>
                    <Link to={"/signin"} className="no-dec"><button className="rider-hosting-signin-button">Sign In</button></Link>
                </div>
        };
        const body = this.props.data.isLoading?<Loading />:
        <div>
            { statement }
            { events }
        </div>
        return (
            <div className="rider-hosting-component-container">
                { body }
            </div>
        );
    };
};
export default RiderHosting;