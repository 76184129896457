import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import '../static/css/Home.css';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import road from '../static/images/images/road-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
import tab from '../static/images/images/up-right-from-square-solid.svg';
import tab_r from '../static/images/images/up-right-from-square-solid_r.svg';
class Home extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            status: '',
            isLoading: true,
            redirect: false,
            image_url: null,
            first_name: '',
            display_name: '', 
            zip_code: '',
            ui: '',
            events: [],
            upcoming: null,
            saved_events: [],
            feed: [],
            riders: [],
            end: null,
            cancelled: false,
            email: null,
            message_unread: false,
            full: false,
            default_join: true,
            trial_used: false,
        };
        this.feed = this.feed.bind(this);
        this.saved_events = this.saved_events.bind(this);
        this.events = this.events.bind(this);
        this.join = this.join.bind(this);
        this.riders = this.riders.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => {
                this.setState({ 
                    first_name: response.data.first_name,
                    display_name: response.data.display_name, 
                    zip_code: response.data.zip_code,
                    ui: response.data.id,
                    trial_used: response.data.trial_used===0?false:true,
                 });
                 return response.data;
            })
            .then(async (data) => {
                const config_sub = { headers: { Authorization: `Bearer ${token}` }, id: data.id, };
                await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
                    .then((response) => { 
                        this.setState({ 
                            status: response.data,
                        }) 
                        if (response.data.canceled===true&&response.data.canceled_access===false&&response.data.active_paid===false) {
                            window.location = `/profile/${this.state.ui}`;
                        };
                        return response.data;
                    })
                    .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
                return data;
            })
            .then(async (data) => {

                if (this.state.status.active_paid === true || this.state.status.on_trial === true  || (this.state.status.canceled===true && this.state.status.canceled_access===true)) {
                    await axios.get(process.env.REACT_APP_SERVER_API + `/events/home/${data.id}`)
                        .then((response) => {
                            this.setState({
                                events: response.data.data[0],
                                upcoming: response.data.data[1][0],
                                saved_events: response.data.data[2][0],
                                feed: response.data.data[3][0],
                            })
                        })
                        .catch(err => console.log(err, "Issue retrieving events!"))
                } else {
                    window.location = `/profile/${this.state.ui}`;
                    this.setState({ 
                        events: [],
                        upcoming: null,
                        saved_events: [],
                        feed: [],
                    })
                };
                return data;
            })
            .then(async (data) => {
                await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${data.id}`)
                    .then((response) => { this.setState({ message_unread: response.data.status }); })
                    .catch((err) => { console.log(err, "Error checking for message status!") })
                return data;
            })
            .then(async (data) => {
                await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${data.id}`)
                    .then((response) => { if (response.data !== false) this.setState({ image_url: response.data, isLoading: false }); else this.setState({ isLoading: false, }) })
                    .catch((err) => {console.log(err, "my error..."); this.setState({ isLoading: false, })})
                return data;
            })
            .then(async (data) => {
            
                await axios.get(process.env.REACT_APP_SERVER_API + `/rider-recommendation/${data.id}`)
                    .then((response) => { this.setState({ riders: response.data.riders }); })
                    .catch((err) => { console.log(err, "Error checking for message status!") })
                return data;
            })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
    };
    async join(e, event_id) {
        e.preventDefault();
        this.setState({isLoading: true})
        const data = {
            id: event_id,
            user: this.state.ui,
        };
        if (this.state.signed_in) {
            await axios.post(process.env.REACT_APP_SERVER_API + `/event/join`, data)
                .then(() => this.setState({isLoading: true}))
                .then(() => alert('Joined! 👊 😎 🏍 💨 🚀'))
                .then(() => window.location = '/home')
                .catch((err) => console.log(err, "ERROR"))
        } else {
            window.location('/signin')
        };
    };

    events() {
        const Event = (props) => (
            <div className="home-event-box">
                <p className="home-event-date no-pointer">{props.event.formatted_date} @ {props.event.time}</p>
                {((props.event.invite_only === 1 || props.event.followers === 1) || parseInt(props.event.max) === props.event.riders_joined) ? <p className="home-event-address b">{props.event.city}, {props.event.state} <br/>View Details Below </p> : <p className="home-event-address"><strong><a className="no-dec b home-event-address-link" href={`http://maps.google.com/?q=1200 ${props.event.street_address}, ${props.event.city}, ${props.event.state}, ${props.event.zip_code}`} target="_blank" rel="noopener noreferrer">{props.event.street_address}, <br/> {props.event.city}, {props.event.state}, {props.event.zip_code} <img className="home-tab-icon" src={tab} alt="" /></a></strong></p>}
                <Link className="no-dec w" to={`/event/${props.event.id}`} target="_blank" rel="noopener noreferrer"><p className="home-event-title r">{props.event.title}<img className="home-tab-icon" src={tab_r} alt="" /></p></Link>
                <p className="home-event-going no-pointer">{props.event.max ? 'Limit: ' + props.event.max : ''} <br /> Joined: {props.event.riders_joined}</p>
                <p className="home-event-going no-pointer">{props.event.share?'Shared':'Hosted'} by {props.event.display_name==="Motorcyk"?"Motorcyk":<Link to={props.event.display_name?`/@${props.event.display_name}`:`/rider/${props.event.user_id}`} className="no-dec b hover">{props.event.display_name ? props.event.display_name : props.event.first_name}</Link>} <br /></p>
                {props.event.invite_only === 1 || props.event.followers === 1 ? <button className="home-event-button-private" disabled={true}>{props.event.invite_only === 1 ? "INVITE ONLY" : "FOLLOWERS ONLY"}</button>: 
                    <form onSubmit={(e) => this.join(e, props.event.id)}>
                        {parseInt(props.event.max) === props.event.riders_joined ? <button className="home-event-button-private" disabled={true}>FULL</button> : <button type="submit" className="home-event-button">Join</button>}
                    </form>
                }
            </div>
        );
        return this.state.events.map(event => {
            
            return  <Event event={event} key={event.id} />
        });
    };
    saved_events() {
        const EventSaved = (props) => (
            <div className="home-event-box">
                <p className="home-event-date no-pointer">{props.event.formatted_date} @ {props.event.time}</p>
                {(props.event.invite_only === 1 || parseInt(props.event.max) === props.event.riders_joined) ? <p className="home-event-address b">{props.event.city}, {props.event.state} <br/></p> : <p className="home-event-address"><strong><a className="no-dec b home-event-address-link" href={`http://maps.google.com/?q=1200 ${props.event.street_address}, ${props.event.city}, ${props.event.state}, ${props.event.zip_code}`} target="_blank" rel="noopener noreferrer">{props.event.street_address}, <br/> {props.event.city}, {props.event.state}, {props.event.zip_code} <img className="home-tab-icon" src={tab} alt="" /></a></strong></p>}
                <a className="no-dec w" href={`https://www.motorcyk.com/event/${props.event.id}`} target="_blank" rel="noopener noreferrer"><p className="home-event-title r">{props.event.title} <img className="home-tab-icon" src={tab_r} alt="" /></p></a>
                <p className="home-event-going no-pointer">{props.event.max ? 'Limit: ' + props.event.max : ''} <br /> Joined: {props.event.riders_joined}</p>
                <p className="home-event-going no-pointer">{props.event.share?'Shared':'Hosted'} by {props.event.display_name==="Motorcyk"?"Motorcyk":<Link to={props.event.display_name?`/@${props.event.display_name}`:`/rider/${props.event.user_id}`} className="no-dec b hover">{props.event.display_name ? props.event.display_name : props.event.first_name}</Link>}</p>
                {/* {props.event.invite_only === 1 ? <button className="home-event-button-private" disabled={true}>INVITE ONLY</button>: 
                <form onSubmit={(e) => this.join(e, props.event.id)}>
                    {parseInt(props.event.max) === props.event.riders_joined ? <button className="home-event-button-private" disabled={true}>FULL</button> : <button type="submit" className="home-event-button">Join</button>}
                </form>} */}
            </div>
        );
        return this.state.saved_events.map(event => {
            return  <EventSaved event={event} key={event.id} />
        });
    };
    feed() {
        const Feed= (props) => (
            <div className="home-feed home-no-data">
                <h6 className="home-feed-date-time">{props.feed.formatted_date}</h6>
                {props.feed.type === "New Event" ? <p className="home-feed-stuff"><Link to={props.feed.display_name?`/@${props.feed.display_name}`:`/rider/${props.feed.user_id}`} className="home-feed-link">{props.feed.display_name ? props.feed.display_name : props.feed.first_name}</Link> has set up a new riding event, to join or follow, check it out <Link to={`/event/${props.feed.event_id}`} className="home-feed-link">HERE</Link>.</p> : ''}
                {props.feed.type === "New Post" ? <p className="home-feed-stuff"><Link to={props.feed.display_name?`/@${props.feed.display_name}`:`/rider/${props.feed.user_id}`} className="home-feed-link">{props.feed.display_name ? props.feed.display_name : props.feed.first_name}</Link> has posted a new image to their collection, go check out their profile <Link to={props.feed.display_name?`/@${props.feed.display_name}`:`/rider/${props.feed.user_id}`} className="home-feed-link">HERE</Link>.</p> : ''}
                {props.feed.type === "Event Cancelled" ? <p className="home-feed-stuff"><Link to={props.feed.display_name?`/@${props.feed.display_name}`:`/rider/${props.feed.user_id}`} className="home-feed-link">{props.feed.display_name ? props.feed.display_name : props.feed.first_name}</Link> has cancelled an event, if you had previously joined, you will no longer see it in your upcoming events.</p> : ''}
                {/* {props.feed.type === "Event Join" ? <p className="home-feed-stuff">{props.feed.display_name ? props.feed.display_name : props.feed.first_name} has posted a new event, go check it out HERE.</p> : ''} */}
            </div>
        );
        return this.state.feed.map(feed => {
            return  <Feed feed={feed} key={feed.id} />
        });
    };
    riders () {
        const Rider = (props) => (
            <div className="home-rider-profile-recommendations-box">
                <Link to={props.rider.display_name?`/@${props.rider.display_name}`:`/rider/${props.rider.id}`} className="no-dec" >
                    <img className="home-rider-profile-image" src={props.rider.image_url ? props.rider.image_url : user} alt="" />
                    <p className="home-rider-profile-name r">{props.rider.display_name ? props.rider.display_name : props.rider.first_name}</p>
                </Link>
            </div>
        );
        return this.state.riders.map(i => {
            return <Rider rider={i} key={i.id} />
        });
    };
    render() {
        let events;
        let saved_events;
        let feed;
        let riders;
        let upcoming_mobile;
        let upcoming_td;
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        events = this.state.isLoading ? '' : this.events();
        saved_events = this.state.isLoading ? '' : this.saved_events();
        feed = this.state.isLoading ? '' : this.feed();
        if (this.state.status.active_paid === true || this.state.status.on_trial === true || (this.state.status.canceled===true && this.state.status.canceled_access===true)) {
            if (this.state.upcoming === undefined || this.state.upcoming === null) {
                upcoming_mobile = this.state.isLoading?'':<p className="home-upcoming-statement no-pointer">You are currently not attending any rides! <span role="img" aria-label="">😔</span></p>
            } else {
                upcoming_mobile = this.state.isLoading?'':
                    <div className="home-event-upcoming-box">
                        <p className="home-event-upcoming-date no-pointer">{this.state.upcoming.formatted_date} @ {this.state.upcoming.time}</p>
                        <p className="home-event-upcoming-address no-pointer"><strong><a className="no-dec b home-event-address-link" href={`http://maps.google.com/?q=1200 ${this.state.upcoming.street_address}, ${this.state.upcoming.city}, ${this.state.upcoming.state}, ${this.state.upcoming.zip_code}`} target="_blank" rel="noopener noreferrer">{this.state.upcoming.street_address}, <br/> {this.state.upcoming.city}, {this.state.upcoming.state}, {this.state.upcoming.zip_code} <img className="home-tab-icon" src={tab} alt="" /></a></strong></p>
                        <Link className="no-dec w" to={`/event/${this.state.upcoming.id}`}><p className="home-event-upcoming-title r">{this.state.upcoming.title}</p></Link>
                        <h5 className="home-event-upcoming-going no-pointer">{this.state.upcoming.max ? ' <br/> | Limit: ' + this.state.upcoming.max : ''}</h5>
                        <h5 className="home-event-upcoming-going no-pointer">{this.state.upcoming.share?'Shared':'Hosted'} by {this.state.upcoming.display_name==="Motorcyk"?"Motorcyk":<Link to={this.state.upcoming.display_name?`/@${this.state.upcoming.display_name}`:`/rider/${this.state.upcoming.user_id}`} className="no-dec b hover">{this.state.upcoming.display_name ? this.state.upcoming.display_name : this.state.upcoming.first_name}</Link>}</h5>
                    </div>
            };
        } else {
            upcoming_mobile = this.state.isLoading ? '' : <p className="home-not-active no-pointer">You must be a member to join riding events, please activate your membership! <span role="img" aria-label="">😎</span></p>
        };
        if (this.state.status.active_paid === true || this.state.status.on_trial === true || (this.state.status.canceled===true && this.state.status.canceled_access===true)) {
            if (this.state.upcoming === undefined || this.state.upcoming === null) {
                upcoming_td = <p className="home-upcoming-statement-td no-pointer">You are currently not attending any rides! <span role="img" aria-label="">😔</span></p>
            } else {
                upcoming_td = 
                    <div>
                        <div className="title-attending-section">
                            <h1 className="home-title-short"><strong><Link className="no-dec w" to={`/event/${this.state.upcoming.id}`}>{this.isLoading ? '' : this.state.upcoming.title}</Link></strong></h1>
                            <p className="no-pointer"><strong>{this.state.upcoming.share?'Shared':'Hosted'} by {this.state.upcoming.display_name==="Motorcyk"?"Motorcyk":<Link to={this.state.upcoming.display_name?`/@${this.state.upcoming.display_name}`:`/rider/${this.state.upcoming.user_id}`} className="no-dec w">{this.state.upcoming.display_name ? this.state.upcoming.display_name : this.state.upcoming.first_name}</Link>}</strong></p>
                        </div>
                        <img className="home-road-icon d" src={road} alt="" />
                        <div className="date-address-section w">
                            <p className="no-pointer"><strong>{this.isLoading ? '' : this.state.upcoming.formatted_date}<br />@ {this.isLoading ? '' : this.state.upcoming.time}</strong></p>
                            <p><strong><a className="no-dec w" href={`http://maps.google.com/?q=1200 ${this.state.upcoming.street_address}, ${this.state.upcoming.city}, ${this.state.upcoming.state}, ${this.state.upcoming.zip_code}`} target="_blank" rel="noopener noreferrer">{this.isLoading ? '' : this.state.upcoming.street_address},<br />{this.isLoading ? '' : this.state.upcoming.city}, {this.isLoading ? '' : this.state.upcoming.state}<br />{this.isLoading ? '' : this.state.upcoming.zip_code}</a></strong></p>
                        </div>
                    </div>
            };
        } else {
            upcoming_td = <p className="home-upcoming-statement-td no-pointer">You must be an active member to use Motorcyk's personlized home page! <span role="img" aria-label="">😎</span></p>
        };
        if (this.state.riders.length > 0) riders = this.riders()
        else riders = ''
        const body = this.state.isLoading?<Loading />:
        <div className="home-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    <li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/settings" className="home-settings-link no-dec b">Settings</Link></li>
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                </ul>
            </div>
            <h1 className="home-greet r mobile no-pointer">Hi, {this.state.first_name}!</h1>
            {this.state.past_due?<p className="mobile" style={{fontSize:"15px",width: "90%", textAlign: "center", backgroundColor: "white", margin: "auto", marginTop: "-20px"}}>Your account is currently past due, please update payment method to avoid access interruption. <br/> <Link to={"/change-payment"} className="no-dec" ><button className="edit-profile-form-button">Update Payment</button></Link></p>:''}
            {(this.state.status.active_paid === true || this.state.status.on_trial === true || (this.state.status.canceled===true && this.state.status.canceled_access===true)) ? (this.state.upcoming===undefined&&this.state.saved_events.length===0?<p className="home-create-event-message mobile"><span role="img" aria-label="">🚨</span> Looks like you don't have much going on, try creating your first event here <Link className="no-dec r launch-message-link" to="/host"><span role="img" aria-label="">👉</span> CREATE EVENT</Link></p>:''):''}
            {(this.state.status.active_paid === true || this.state.status.on_trial === true || (this.state.status.canceled===true && this.state.status.canceled_access===true)) ? (this.state.image_url===null?<p className="home-create-event-message mobile"><strong><span role="img" aria-label="">🚨</span> Upload a profile photo, you currently don't have one, you can do it here</strong> <Link className="no-dec r launch-message-link" to={`/edit-image/${this.state.ui}`}><span role="img" aria-label="">👉</span> UPLOAD PROFILE PHOTO</Link></p>:''):''}
            {(this.state.status.active_paid === true || this.state.status.on_trial === true || (this.state.status.canceled===true && this.state.status.canceled_access===true)) ? (this.state.display_name===null?<p className="home-create-event-message mobile"><strong><span role="img" aria-label="">🚨</span> You currently don't have a rider/display name, create one here</strong> <Link className="no-dec r launch-message-link" to={`/edit-profile/${this.state.ui}`}><span role="img" aria-label="">👉</span> UPDATE PROFILE DETAILS</Link></p>:''):''}
            <div className="home-upcoming-container mobile">
                <h2 className="home-upcoming-title r mobile no-pointer">Your upcoming ride</h2>
                {upcoming_mobile}
            </div>
            {this.state.past_due?<p className="td" style={{fontSize: "15px",width: "90%", textAlign: "center", backgroundColor: "white", margin: "auto", marginTop: "75px", marginBottom: "-50px"}}>Your account is currently past due, please update payment method to avoid access interruption. <br/> <Link to={"/change-payment"} className="no-dec" ><button className="edit-profile-form-button">Update Payment</button></Link></p>:''}
            <div className="home-upcoming-container td w">
                <h1 className="home-greet w no-pointer">Hi, {this.state.first_name}!</h1>
                <p className="home-upcoming-title w no-pointer"><strong>Your upcoming ride</strong></p>
                <hr className="home-upcoming-border" />
                {upcoming_td}
            </div>
            {(this.state.status.active_paid === true || this.state.status.on_trial === true || (this.state.status.canceled===true && this.state.status.canceled_access===true)) ? (this.state.upcoming===undefined&&this.state.saved_events.length===0?<p className="home-create-event-message td"><strong><span role="img" aria-label="">🚨</span> Looks like you don't have much going on, try creating your first event here</strong> <Link className="no-dec r launch-message-link" to="/host"><span role="img" aria-label="">👉</span> CREATE EVENT</Link></p>:''):''}
            {(this.state.status.active_paid === true || this.state.status.on_trial === true || (this.state.status.canceled===true && this.state.status.canceled_access===true)) ? (this.state.image_url===null?<p className="home-create-event-message td"><strong><span role="img" aria-label="">🚨</span> Upload a profile photo, you currently don't have one, you can do it here</strong> <Link className="no-dec r launch-message-link" to={`/edit-image/${this.state.ui}`}><span role="img" aria-label="">👉</span> UPLOAD PROFILE PHOTO</Link></p>:''):''}
            {(this.state.status.active_paid === true || this.state.status.on_trial === true || (this.state.status.canceled===true && this.state.status.canceled_access===true)) ? (this.state.display_name===null?<p className="home-create-event-message td"><strong><span role="img" aria-label="">🚨</span> You currently don't have a rider/display name, create one here</strong> <Link className="no-dec r launch-message-link" to={`/edit-profile/${this.state.ui}`}><span role="img" aria-label="">👉</span> UPDATE PROFILE DETAILS</Link></p>:''):''}
            <h2 className="home-available-title r no-pointer">Available Riding Events</h2> { (this.state.status.active_paid === true || this.state.status.on_trial === true) ? <a className="home-go-explore r td" href="/explore" alt="">Go To Explore</a> : '' }
            <br/>
            { (this.state.status.active_paid === true || this.state.status.on_trial === true || (this.state.status.canceled===true && this.state.status.canceled_access===true)) ? (this.state.events.length > 0 ? <div className="home-available-container">{events}</div> : <p className="home-not-active no-pointer">There are currently no events available! <span role="img" aria-label="">⛈️</span></p>) : (this.state.isLoading ? '' : <div style={{textAlign: "center", marginTop: "20px"}}><p className="home-not-active no-pointer">You must be a member to view riding events and view other content, please activate membership! <span role="img" aria-label="">😎</span></p> <Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }} className="no-dec" ><button className="edit-profile-form-button">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link></div>) }
            { (this.state.status.active_paid === true || this.state.status.on_trial === true || (this.state.status.canceled===true && this.state.status.canceled_access===true)) ? <h2 className="home-saved-title r no-pointer">Followed Riding Events</h2> : '' }
            { (this.state.status.active_paid === true || this.state.status.on_trial === true || (this.state.status.canceled===true && this.state.status.canceled_access===true)) ? (this.state.saved_events.length > 0 ? <div className="home-saved-container"><br/>{ saved_events }</div> : <p className="home-not-active home-no-data no-pointer" style={{marginTop: "20px"}}>You currently aren't following any upcoming events! Go to <Link to="/explore" className="no-dec"><button className="home-redirect-button">Explore</button></Link> and follow events you may be interested in. <span role="img" aria-label="">🗓</span></p>) : (this.state.isLoading ? '' : '') }
            { (this.state.status.active_paid === true || this.state.status.on_trial === true || (this.state.status.canceled===true && this.state.status.canceled_access===true)) ? <div><h2 className="home-feed-title r no-pointer">Feed</h2> {this.state.feed.length>0?<a className="home-go-explore r td" href="/search-rider" alt="">Search Riders</a>:''}</div>: '' }
            {this.state.feed.length>0?<div className="home-rider-recommendations-container" style={{marginBottom: "-45px"}}>
                <p className="home-rider-recommendations-title">Suggested Riders To Follow</p> 
                <div className="home-profiles-container">
                    { riders } 
                </div>
            </div>:''}
            { (this.state.status.active_paid === true || this.state.status.on_trial === true || (this.state.status.canceled===true && this.state.status.canceled_access===true)) ? (this.state.feed.length > 0 ? <div className="home-feed-container"><br/>{ feed }</div> : <p className="home-not-active home-no-data no-pointer" style={{textAlign: "center", marginTop: "50px"}}>No activity to display! Go to <Link to="/search-rider" className="no-dec"><button className="home-redirect-button">Search</button></Link> and follow other riders to see some feed activity! <span role="img" aria-label="">😎➕</span></p>) : (this.state.isLoading ? '' : '') }
            {this.state.feed.length===0?<div className="home-rider-recommendations-container">
                <p className="home-rider-recommendations-title">Suggested Riders To Follow</p> 
                <div className="home-profiles-container">
                    { riders } 
                </div>
            </div>:''}
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>
                { body }
            </div>
        );
    };
};
export default Home;
