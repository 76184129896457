import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/Cookies.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';

class Cookies extends Component {
    constructor() {
        super()
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            redirect: false,
            ui: null,
            image_url: null,
            message_unread: false,
        };
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id }); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        const config_sub = { 
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({  status: response.data, }) 
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .then(()=> { this.setState({ isLoading: false }) })
            .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
    };
    render() {
        if (this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        const body = this.state.isLoading?<Loading />: 
        <div className="cookies-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to="/profile/user" className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}                     </ul>
            </div>
            <div className="cookies-title-container">
                <h1 className="cookies-title" >Cookie Policy</h1>
                {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
            </div>
            <div className="cookies-section">
                <Link to="/settings" className="no-dec"><button className="cookies-button">Back</button></Link>
                <p>
                    <b>Last Updated: March 1, 2022</b>
                    <br/><br/>
                    As described in our Privacy Policy, we and our service providers use cookies and other technologies to provide our Platform and to enhance your experience. This Cookie Policy sets out some further detail on how and why we use these technologies on our Platform, which includes any website, application, or service we offer. The terms "Motorcyk," "we," "us," and "our" include Motorcyk LLC and our affiliates. By using our Platform, you consent to storage and access to cookies and other technologies on your device, in accordance with this Cookie Policy.
                </p>   
                <h3>Cookies</h3>
                <p>
                    What Cookies Are. Cookies are small data files which are downloaded to your device when you visit a website. Cookies are then sent back to the originating website on each subsequent visit, or to another website that recognizes that cookie. Cookies are useful because they allow us to recognize your device.
                    <br/><br/>
                    How We Use Cookies. Cookies serve a variety of functions, like enabling us to remember certain information you provide to us as you navigate between pages on the Platform. We use cookies for the following purposes:
                    <br/><br/>
                    Authentication: We use cookies to recognize you if you are logged in to our Platform. This lets us personalize your experience on or with the Platform in the ways described below. In addition, we may employ third-party services that may use cookies to help you sign into their services from our Platform.
                    <br/><br/>
                    Security: We use cookies to support or enable security features we have deployed, and to help us detect malicious activity and violations of our Terms of Service. These cookies help us prevent fraudulent use of login credentials.
                    <br/><br/>
                    Services, features, and preferences: We use cookies to provide functionality and help us deliver our products and services according to your preferences. For example, we use cookies to know which language you prefer, what your communications preferences are, to show you personalized views related to your interests and groups, and in order to help you fill out forms on the Platform.
                    <br/><br/>
                    Performance, Analytics and Research: We use cookies to help us analyze how the Platform is being accessed and used, and enable us to track performance of the Platform. This helps us to understand, improve, and research features and content on the Platform. We may also use other services, such as Google Analytics or other third-party cookies, to assist with analyzing performance on our Platform. As part of providing these services, these service providers may use cookies and the technologies described below to collect and store information about your device, such as time of visit, pages visited, time spent on each page of the Platform, links clicked and conversion information, IP address, browser, mobile network information, and type of operating system used.
                    <br/><br/>
                    Motorcyk ads: We partner with third party publishers, advertising networks and service providers to manage our ads on other sites. Our third party partners may set cookies on your device or use the technologies described below to gather information about your activities on the Platform and other sites and online services you use, in order to provide you with Motorcyk ads that match member profiles or interests. For example, if you visit our Platform and also use a social media platform, you may see a Motorcyk ad on those social media platforms.
                    <br/><br/>
                    Third party advertisers: We may work with advertisers, and advertising service providers to serve ads that may be relevant to you based on your inferred interests, location or other information collected about you, which may use a cookie or the technologies described below, placed by Motorcyk or the third party (although we would not share any other information that identifies you with an advertiser) on our Platform, or on a third party website or online service. The placing of these technologies on your device may enable you to be identified across multiple websites and online services.
                    <br/><br/>
                    Duration of Cookies. Some cookies, known as "session cookies", will stay on your device only for as long as you are accessing our Platform. Others, known as "persistent cookies", are stored on your device for longer periods.
                </p>   
                <h3>Other Technologies</h3>
                <p>
                    Use of Other Technologies. We may allow others to provide analytics services and serve advertisements on our behalf. In addition to the uses of cookies described above, these entities may use other methods, such as the technologies described below, to collect information about your use of the Platform and other websites and online services.
                    <br/><br/>
                    Pixels tags. Pixel tags (which are also called clear GIFs, web beacons, or pixels), are small pieces of code that can be embedded on websites and emails. Pixels tags may be used to learn how you interact with our site pages and emails, and this information helps us and our partners provide you with a more tailored experience.
                    <br/><br/>
                    Device Identifiers. A device identifier is a unique label can be used to identify a mobile device. Device identifiers may be used to track, analyze and improve the performance of the Platform and ads delivered.
                    <br/><br/>
                    Local storage. Local storage is an industry-standard technology that enables the storage and retrieval of data on a computer, mobile phone or other device. Local storage may be used to store member preferences on devices.
                </p>
                <h3>Opting Out</h3>
                <p>
                    Cookies. Most devices and web browsers are set to accept cookies by default. If you prefer, you may be able to remove or reject cookies. You can learn more about how to block cookies by visiting allaboutcookies.org. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Platform.
                    <br/><br/>
                    Targeted Advertising. Third parties that use cookies and other tracking technologies to deliver targeted advertisements on third party websites may offer you a way to prevent such targeted advertisements by opting out at the websites of industry groups such as the Digital Advertising Alliance, or if located in the European Union, the European Interactive Digital Advertising Alliance. You may also be able to control advertising cookies provided by publishers, for example Google's Ad Preference Manager. Your device may also include a feature that allows you to opt out of having certain information collected through apps used for behavioral advertising purposes. Note that even if you choose to opt out of receiving targeted advertising, you may still receive advertising, although it should not be tailored to your interests or activities.
                </p>
                <h3>Revisions to this Policy</h3>
                <p>
                    We may modify this Cookie Policy from time to time. When we do, we will provide notice to you by publishing the most current version and revising the date at the top of this page. If we make any material change to this Cookie Policy, we will provide additional notice to you, such as by sending you an email, or displaying a prominent notice on our Platform. By continuing to use the Platform after any changes come into effect, you agree to the revised Cookie Policy.
                </p>
                <Link to="/settings" className="no-dec"><button className="cookies-button">Back</button></Link>
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default Cookies;