import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import ScrollToBottom from 'react-scroll-to-bottom';
import axios from 'axios';
import '../static/css/Message.css';
import user_icon from '../static/images/images/user-circle-solid.svg';
import messages_icon from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import LeftArrow from '../static/images/images/arrow-left-solid.svg';
import send from '../static/images/images/paper-plane-solid.svg';
import Linkify from 'react-linkify';
import io from 'socket.io-client';
const socket = io.connect(process.env.REACT_APP_SERVER_API + '/', { transports : ['websocket', 'polling', 'flashsocket'] });
class Message extends Component {
    constructor(props) {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            status: '',
            isLoading: true,
            redirect: false,
            pair: null,
            image_url: null,
            image_url_rider: null,
            ui: null,
            rider: null,
            name: null,
            room: "",
            message: "",
            messageList: [],
            end: null,
            cancelled: false,
            message_unread: false,
            trial_used: false,
        };
        this.onChangeMessage = this.onChangeMessage.bind(this)
        this.sendMessage = this.sendMessage.bind(this)
        this.messages = this.messages.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => {
                this.setState({ 
                    ui: response.data.id,
                    name: response.data.display_name ? response.data.display_name : response.data.first_name,
                    rider: this.props.location.state.rider,
                    trial_used: response.data.trial_used===0?false:true,
                });
            })
            .then(() => { socket.on("recieve_message", (data) => { this.setState({ messageList: [...this.state.messageList, data] }); }); })
            .catch(err => { console.log("You are not logged in or something went wrong with fetching data!", err); this.setState({ redirect: true }); });
        const config_sub = { headers: { Authorization: `Bearer ${token}` }, id: this.state.ui, };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        if (this.state.status.active===true) {
            const id = this.state.ui;
            const rider = this.state.rider;
            await axios.get(process.env.REACT_APP_SERVER_API + `/messages/${id}/${rider}`)
                .then((response) => {
                    this.setState({ 
                        messageList: [...this.state.messageList, ...response.data[1]],
                        pair: response.data[0][0].id,
                        room: response.data[0][0].room_id,
                    });
                })
                .then( () => { socket.emit("join_room", this.state.room); })
                .catch(err => { console.log(err, "Error retrieving messages!") });
        } else {
            window.location = "/signin"
        }
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .catch((err) => {console.log(err, "my error..."); })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.rider}`)
            .then((response) => { this.setState({ image_url_rider: response.data, isLoading: false, })})
            .catch((err) => {console.log(err, "my error..."); this.setState({ isLoading: false, }) })
    };
    onChangeMessage(event) { this.setState({ message: event.target.value }); };
    async sendMessage(event) {
        event.preventDefault();
        const date = new Date();
        const messageContent = {
            id: this.state.ui,
            rider: this.state.rider,
            room: this.state.room,
            pair: this.state.pair,
            content: {
                name: this.state.name,
                message: this.state.message,
                date: date.toDateString(),
                time: date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
            }
        };
        await socket.emit("send_message", messageContent);
        axios.post(process.env.REACT_APP_SERVER_API + '/message', messageContent)
            .then(() => { })
            .catch(err => { console.log(err, "Error logging message!") });
        this.setState({ 
            message: "", 
            messageList: [...this.state.messageList, messageContent.content] 
        });
    }
    messages() {
        const date = new Date();
        const Message = (props) => (
            <div className="message-set">
                <h6 className="message-date-time" style={props.message.name === this.state.name ? {color: "#FF2F46"} : {color: "black"}}><img src={props.message.name === this.state.name ? this.state.image_url ?  this.state.image_url : user_icon : this.state.image_url_rider ? this.state.image_url_rider : user_icon} alt="" /> {props.message.name} | {props.message.date === date.toDateString() ? 'Today' : props.message.date} @ {props.message.time}</h6>
                <p className="message-text" style={props.message.name === this.state.name ? {color: "#FF2F46"} : {color: "black"}}><Linkify>{props.message.message}</Linkify></p>
            </div>
        );
        return this.state.messageList.map(message => {
            return  <Message message={message} key={message.id} />
        });
    };
    render() {
        if (this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>;
        let messages;
        if (this.state.messageList.length === 0) messages = this.state.isLoading ? '' : <p className="no-message-text">No messages...</p>;
        else messages = this.state.isLoading ? '' : this.messages();
        let image;
        if (this.state.image_url_rider !== null) image = this.state.isLoading ? '' : <div className="message-image-container-s3" style={{backgroundImage: `url(${this.state.image_url_rider})`}}></div>;
        else image = this.state.isLoading ? '' : <div className="message-image-contianer-s3"><img className="message-image-image-s3" src={user_icon} alt="" /></div>;
        let message_contents;
        if (this.state.status.active===true) {
            message_contents =
                <div>
                    <ScrollToBottom className="message-display">
                        {messages} 
                    </ScrollToBottom>
                    <div className="footer-form-container">
                        <form className="message-form" onSubmit={e => {this.sendMessage(e)}}>
                            <input className="new-message-form-input" type="text" placeholder="Type message..." value={this.state.message}  onChange={this.onChangeMessage}/>
                            <button className="new-message-form-button" type="submit">Send <img className="message-send" src={send} alt="" /></button>
                        </form>
                    </div>
                </div>
        } 
        // else {
        //     message_contents =
        //         <div style={{ textAlign: "center" }}>
        //             <h3>You must have an active membership to view messages!</h3>
        //             <Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }} className="no-dec"><button className="new-message-form-button">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link>
        //         </div>
        // };
        const body = this.state.isLoading?<Loading />: 
        <div className="message-container">
            <div className="home-header">
                <Link to="/messages" className=""><img className="home-header-title arrow r" src={LeftArrow} alt=""/></Link>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages_icon} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages_icon} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user_icon} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/settings" className="home-settings-link no-dec b">Settings</Link></li>
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}                </ul>
            </div>
            <div className="message-user-info">
                <Link className="no-dec" to={`/rider/${this.state.rider}`} >
                    { image }
                    <h3 className="message-profile-name">{this.props.location.state.rider_dn ? this.props.location.state.rider_dn : this.props.location.state.rider_fn}</h3>
                </Link>
            </div>
            { message_contents }
        </div>
        return (
            <div>{ body }</div> 
        );
    };
};
export default Message;