import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/ChangeEmail.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class ChangeEmail extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            status: '',
            isLoading: true,
            redirect: false,
            ui: '',
            image_url: null,
            current_email: '',
            email: '',
            provider: false,
            confirm_email: '',
            email_required: '',
            email_valid: '',
            email_exists: '',
            confirm_email_required: '',
            emails_no_match: false,
            email_reuse: false,
            disabled: false,
            password: '',
            confirm_password: '',
            password_required: '',
            password_min: '',
            password_special_character: '',
            password_number: '',
            confirm_password_required: '',
            confirm_password_match: '',
            end: null,
            cancelled: false,
            message_unread: false,
            trial_used: false,
        };
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeConfirmEmail = this.onChangeConfirmEmail.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({  ui: response.data.id, trial_used: response.data.trial_used===0?false:true, }); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        const config_sub = { 
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
                return response.data;
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .catch((err) => {console.log(err, "my error..."); })
        if (this.state.status.active) {
            await axios.get(process.env.REACT_APP_SERVER_API + `/email/update/${this.state.ui}`)
                .then((response) => {
                    this.setState({
                        current_email: response.data.email,
                        provider: response.data.provider_id ? response.data.provider_id : false,
                        isLoading: false,
                    })
                })
                .catch(err => { console.log(err, "Something went wrong with retrieivng email!"); this.setState({ isLoading: false }) });
        } else {
            this.setState({ redirect: true})
        };
    };
    onChangeEmail(event) {
        this.setState({
            email: event.target.value
        });
    };
    onChangeConfirmEmail(event) {
        this.setState({
            confirm_email: event.target.value
        });
    };
    onChangePassword(event) {
        this.setState({
            password: event.target.value
        });
    };
    onChangeConfirmPassword(event) {
        this.setState({
            confirm_password: event.target.value
        });
    };
    async onSubmit(event) {
        event.preventDefault();
        this.setState({ disabled: true, isLoading: true, })
        if (!this.state.provider) {
            const email = {
                id: this.state.ui,
                email: this.state.email,
                confirm_email: this.state.confirm_email
            }; 
            if (email.email !== this.state.confirm_email) {
                this.setState({
                    emails_no_match: true,
                    disabled: false
                });
            } else if (email.email === this.state.current_email) {
                this.setState({
                    email_reuse: true,
                    disabled: false
                });
            } else {
                if (this.state.signed_in) { 
                    await axios.put(process.env.REACT_APP_SERVER_API + '/email/update', email)
                        .then(() => {alert('Email updated! 🚀')})
                        .then(() => { window.location.reload(true) })
                        .catch(err => {
                            console.log(err)
                            this.setState({ 
                                email_required: err.response.data.email_required,
                                email_valid: err.response.data.email_valid,
                                email_exists: err.response.data.email_exists,
                                emails_no_match: err.response.data.confirm_email_no_match ? true : false,
                                confirm_email_required: err.response.data.confirm_email_required,
                                confirm_email_match: err.response.data.confirm_email_match,
                                disabled: false
                            });
                        });
                } else {
                    window.location('/signin');
                };
            };
        } else {
            const email_provider = {
                id: this.state.ui,
                email: this.state.email,
                confirm_email: this.state.confirm_email,
                password: this.state.password,
                confirm_password: this.state.confirm_password
            };
            if (email_provider.email !== this.state.confirm_email) {
                this.setState({
                    emails_no_match: true,
                    disabled: false
                });
            } else if (email_provider.email === this.state.current_email) {
                this.setState({
                    email_reuse: true,
                    disabled: false
                });
            } else {
                if (this.state.signed_in) {
                    await axios.put(process.env.REACT_APP_SERVER_API + '/email/update-provider', email_provider)
                        .then(() => {alert('Email and Password have been updated! 🚀')})
                        .then(() => window.location = '/settings')
                        .catch(err => {
                            console.log(err)
                            this.setState({ 
                                email_required: err.response.data.email_required,
                                email_valid: err.response.data.email_valid,
                                email_exists: err.response.data.email_exists,
                                emails_no_match: err.response.data.confirm_email_no_match ? true : false,
                                confirm_email_required: err.response.data.confirm_email_required,
                                confirm_email_match: err.response.data.confirm_email_match,
                                password_required: err.response.data.password_required,
                                password_min: err.response.data.password_min,
                                password_special_character: err.response.data.password_special_character,
                                password_number: err.response.data.password_number,
                                confirm_password_required: err.response.data.confirm_password_required,
                                confirm_password_match: err.response.data.confirm_password_match,
                                disabled: false
                            });
                        });
                } else {
                    window.location('/signin');
                };
            };
        };
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let email;
        if (this.state.status.active_paid===true||this.state.status.on_trial===true||this.state.status.active===true) {
            if (!this.state.provider) {
                email = 
                    <form onSubmit={this.onSubmit}>
                        <input className="change-email-form-input" type="text" placeholder={"Current Email"} defaultValue={this.state.current_email} disabled={true}/>
                        <p className="change-email-error-message">{ this.state.email_required }</p>
                        <p className="change-email-error-message">{ this.state.email_valid }</p>
                        <p className="change-email-error-message">{ this.state.email_exists }</p>
                        <p className="change-email-error-message">{ this.state.emails_no_match ? 'Emails do not match' : '' }</p>
                        <p className="change-email-error-message">{ this.state.email_reuse ? 'You alredy have this EMAIL as your current EMAIL!' : '' }</p>
                        <input className="change-email-form-input" type="text" placeholder={"New Email"} value={this.state.email} onChange={this.onChangeEmail}/>
                        <p className="change-email-error-message">{ this.state.confirm_email_required }</p>
                        <p className="change-email-error-message">{ this.state.emails_no_match ? 'Emails do not match' : '' }</p>
                        <input className="change-email-form-input" type="text" placeholder="Confirm Email" value={this.state.confirm_email} onChange={this.onChangeConfirmEmail}/>
                        <button className="change-email-form-button" type="submit" disabled={this.state.disabled}>{this.state.disabled ? "Updating..." : "Update"}</button>
                        <Link to="/settings" className="no-dec"><button className="change-email-form-button">Cancel</button></Link>
                    </form>
            } else {
                email = 
                    <form onSubmit={this.onSubmit}>
                        <h5>NOTE: This email is associated by a third party (GOOGLE) Single Sign On. You can update your email, but you will lose access to Google's Single Sign On and you will have to create a new password!</h5>
                        <input className="change-email-form-input" type="text" placeholder={"Current Email"} defaultValue={this.state.current_email} disabled={true}/>
                        <p className="change-email-error-message">{ this.state.email_required }</p>
                        <p className="change-email-error-message">{ this.state.email_valid }</p>
                        <p className="change-email-error-message">{ this.state.email_exists }</p>
                        <p className="change-email-error-message">{ this.state.emails_no_match ? 'Emails do not match' : '' }</p>
                        <p className="change-email-error-message">{ this.state.email_reuse ? 'You are already using this EMAIL as your current EMAIL!' : '' }</p>
                        <input className="change-email-form-input" type="text" placeholder={"New Email"} value={this.state.email} onChange={this.onChangeEmail}/>
                        <p className="change-email-error-message">{ this.state.confirm_email_required }</p>
                        <p className="change-email-error-message">{ this.state.emails_no_match ? 'Emails do not match' : '' }</p>
                        <input className="change-email-form-input" type="text" placeholder="Confirm Email" value={this.state.confirm_email} onChange={this.onChangeConfirmEmail}/>
                        <p className="change-email-error-message">{ this.state.password_required }</p>
                        <p className="change-email-error-message">{ this.state.password_min }</p>
                        <p className="change-email-error-message">{ this.state.password_number }</p>
                        <p className="change-email-error-message">{ this.state.password_special_character }</p>
                        <input className="change-email-form-input" type="password" placeholder="Password" value={this.password} onChange={this.onChangePassword}/>
                        <p className="change-email-error-message">{ this.state.confirm_password_required }</p>
                        <p className="change-email-error-message">{ this.state.confirm_password_match }</p>
                        <input className="change-email-form-input" type="password" placeholder="Confirm Password" value={this.confirm_password} onChange={this.onChangeConfirmPassword}/>
                        <button className="change-email-form-button" type="submit" disabled={this.state.disabled}>{this.state.disabled ? "Updating..." : "Update"}</button>
                        <Link to="/settings" className="no-dec"><button className="change-email-form-button">Cancel</button></Link>
                    </form>
            };
        } else {
            email = 
                <div>
                    <p>You must be an active member to make edits to account! <span role="img"  aria-label="">😎</span> <br /> Please activate your membership!</p>
                    <Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }} className="no-dec"><button className="edit-profile-form-button back">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link>
                    <Link to="/settings" className="no-dec"><button className="edit-profile-form-button back">Go Back</button></Link>
                </div>
        };
        const body = this.state.isLoading?<Loading />:
        <div className="change-email-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}                </ul>
            </div>
            <div className="change-email-title-container">
                <h1 className="change-email-title" >Change Email</h1>
            </div>
            <div className="change-email-section">  
                {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
                {this.state.past_due?<p style={{fontSize: "15px",width: "90%", textAlign: "center", backgroundColor: "white", margin: "20px auto"}}>Your account is currently past due, please update payment method to avoid access interruption. <br/> <Link to={"/change-payment"} className="no-dec" ><button className="edit-profile-form-button">Update Payment</button></Link></p>:''}
                {email}
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default ChangeEmail;