import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import qs from 'qs';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/ChangePayment.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class ChangePayment extends Component {
    constructor(props) {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            status: '',
            card_type: null,
            last_four: null,
            exp_date: null,
            customer_id: null,
            subscription_id: null,
            isLoading: true,
            redirect: false,
            ui: null,
            image_url: null,
            payment: null,
            session: props.location.search.length === 0 ? null : qs.parse(props.location.search, { ignoreQueryPrefix: true }).session_id,
            end: null,
            cancelled: false,
            message_unread: false,
            trial_used: false,
        };
        this.update = this.update.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true }); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        const config_sub = { 
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
            session: this.state.session,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .catch((err) => {console.log(err, "my error..."); })
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription/change-payment`, config_sub)
            .then((response) => { 
                this.setState({ 
                    active: response.data.active, 
                    card_type: response.data.card_type,
                    last_four: response.data.last_four,
                    exp_date: response.data.exp_date,
                    customer_id: response.data.customer_id,
                    subscription_id: response.data.subscription_id,
                    end: response.data.date,
                    cancelled: response.data.status === "canceled" ? true : false,
                    past_due: response.data.past_due,
                }) 
                var end_date = new Date(response.data.date);
                var current_date = new Date();
                if (response.data.cancelled) {
                    if (end_date.setHours(0,0,0,0) < current_date.setHours(0,0,0,0)) {
                        this.setState({ active: response.data.status, end: response.data.date, }); 
                    }
                };
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        if (this.state.session !== null) {
            await axios.post(process.env.REACT_APP_SERVER_API + `/subs/update/card`, config_sub)
                .then((response) => {
                    this.setState({ 
                        active: response.data.status, 
                        card_type: response.data.card_type,
                        last_four: response.data.last_four,
                        exp_date: response.data.exp_date,
                        customer_id: response.data.customer_id,
                        subscription_id: response.data.subscription_id,
                    }) 
                })
                .then(() => { this.setState({ isLoading: false }); })
                .catch((err) => { console.log(err, "Error checking sessions!"); this.setState({ isLoading: false }); })
        } else {
            this.setState({ isLoading: false, });
        }
    };
    async update() {
        this.setState({ isLoading: true });
        const token = localStorage.getItem("token");
        const config_sub = { 
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
            customer_id: this.state.customer_id,
            subscription_id: this.state.subscription_id,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + '/subs/subscription/update-payment', config_sub)
            .then((response) => { this.setState({ payment: response.data.url }) })
            .then( () => { window.location.href = this.state.payment })
            .catch((err) => { console.log(err, "this is my error"); this.setState({ isLoading: false }) })
    }
    render() {
        if(this.state.redirect === true) {
            return <Redirect to = {{ pathname: "/signin" }}/>
        };
        let update_payment;
        if (this.state.status.active_paid===true||this.state.status.on_trial===true) {
            update_payment =
                this.state.isLoading ? '' : 
                <div className="change-payment-section">
                    {this.state.past_due?<p className="r">Your account is currently past due, please update payment method to avoid access interruption.</p>:''}
                    <p><b>Current Payment</b></p>
                    {this.state.card_type?<p>{this.state.card_type} - {this.state.last_four} - {this.state.exp_date.month}/{this.state.exp_date.year}</p>:<p>{this.state.status.on_trial===true&&!this.state.card_type?"No Payment on file, you are currently on a trial.":"You are Currently on a trial."}</p>}
                    <br/><br/>
                    <button className="change-payment-button" onClick={() => this.update()} >Update Payment Method</button><br/><br/>
                    <Link to="/settings" className="no-dec"><button className="change-payment-button">Go Back</button></Link>    
                </div>
        } else {
            if (this.state.status.active_paid===false&&this.state.status.on_trial===false) {
                update_payment = 
                    this.state.isLoading ? '' : 
                    <div className="change-payment-section">
                        <h3>No Payment on file, you are currently not on a plan.</h3>
                        <br/><br/><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }} className="no-dec"><button className="change-payment-button">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link><br/><br/>
                        <Link to="/settings" className="no-dec"><button className="change-payment-button">Go Back</button></Link>    
                    </div>
            } 
            if(this.state.status.canceled===true) {
                update_payment = 
                    this.state.isLoading ? '' : 
                    <div className="change-payment-section">
                        <h3>You have already canceled your membership, no payment is currently stored! Reactivate your membership after the end of you cycle ({ this.state.end }) to manage payment!</h3>
                        <br/><Link to="/settings" className="no-dec"><button className="change-payment-button">Go Back</button></Link>    
                    </div>
            };
        };
        const body = this.state.isLoading?<Loading />: 
        <div className="change-payment-container">
            <div className="home-header">
            <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}                </ul>
            </div>
            <div className="change-payment-title-container">
                <h1 className="change-payment-title" >Change Payment</h1>
                {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
            </div>
            { update_payment }
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default ChangePayment;