import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/BlockedUsers.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class BlockedUsers extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            redirect: false,
            ui: null,
            image_url: null,
            message_unread: false,
        };
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id }); })
            .catch(err => {
                console.log("You are not logged in or something went wrong with fetching data!", err)
                this.setState({ redirect: true });
            });
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .then(()=> { this.setState({ isLoading: false }) })
            .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        const body = this.state.isLoading?<Loading />:
        <div className="blocked-users-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    <li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to="/profile/user" className="home-profile-link no-dec b">Profile</Link></li>
                    <li><Link to="/home" className="home-home-link no-dec b">Home</Link></li>
                </ul>
            </div>
            <div className="blocked-users-title-container">
                <h1 className="blocked-users-title" >Blocked User</h1>
            </div>
            <div className="blocked-users-section">
                <p className="blocked-users">firstName lastName - <Link to="/settings" className="no-dec r">Unblock</Link></p>
                <p className="blocked-users">firstName lastName - <Link to="/settings" className="no-dec r">Unblock</Link></p>
                <p className="blocked-users">firstName lastName - <Link to="/settings" className="no-dec r">Unblock</Link></p>
                <p className="blocked-users">firstName lastName - <Link to="/settings" className="no-dec r">Unblock</Link></p>
                <p className="blocked-users">firstName lastName - <Link to="/settings" className="no-dec r">Unblock</Link></p>
                <p className="blocked-users">firstName lastName - <Link to="/settings" className="no-dec r">Unblock</Link></p>
                <p className="blocked-users">firstName lastName - <Link to="/settings" className="no-dec r">Unblock</Link></p>
                <Link to="/settings" className="no-dec"><button className="blocked-users-button">Back</button></Link>
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default BlockedUsers;