import React, { Component } from 'react';
import Loading from '../components/LoadingPage';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import '../static/css/SignUpInfo.css';
import logoIcon from '../static/images/images/logo_icon.png';
class SignUpInfo extends Component {
    constructor() {
        super();
        this.state = {
            ui: null,
            first_name: '',
            email: '',
            password: '',
            confirm_password: '',
            display_name: null,
            zip_code: null ,
            first_name_required: '',
            first_name_min: '',
            first_name_max: '',
            first_name_valid: '',
            email_required: '',
            email_valid: '',
            email_exists: '',
            email_direct: '',
            email_direct_link: null,
            password_required: '',
            password_min: '',
            password_special_character: '',
            password_number: '',
            confirm_password_required: '',
            confirm_password_match: '',
            display_name_error: '',
            display_name_max_error: '',
            display_name_exists_error: '',
            zip_code_error: '',
            customer_id: '',
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            redirect: false,
        };
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
        this.onChangeZipCode = this.onChangeZipCode.bind(this);
        this.onChangeDisplayName = this.onChangeDisplayName.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( () => { window.location = '/home'; })
            .catch(err => { console.log(err, "Error verifying token!"); this.setState({ isLoading: false, }); });
    };
    onChangeFirstName(event) {
        this.setState({
            first_name: event.target.value
        });
    };
    onChangeEmail(event) {
        this.setState({
            email: event.target.value
        });
    };
    onChangePassword(event) {
        this.setState({
            password: event.target.value
        });
    };
    onChangeConfirmPassword(event) {
        this.setState({
            confirm_password: event.target.value
        });
    };
    onChangeZipCode(event) {
        this.setState({
            zip_code: event.target.value
        });
    };
    onChangeDisplayName(event) {
        this.setState({
            display_name: event.target.value
        });
    };
    async onSubmit(event) {
        event.preventDefault();
        this.setState({ isLoading: true, })
        const user = {
            first_name: this.state.first_name,
            email: this.state.email,
            password: this.state.password,
            confirm_password: this.state.confirm_password,
            zip_code: this.state.zip_code,
            display_name: this.state.display_name
        };
        let validateLetters = /^[A-Za-z]+$/.test(user.first_name); // eslint-disable-next-line
        let validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email); // eslint-disable-next-line
        let validPasswordNumber = /\d/.test(user.password) // eslint-disable-next-line
        let validPasswordSpecialCharacter = /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/.test(user.password); // eslint-disable-next-line
        let validNumbers = /^[0-9]+$/.test(user.zip_code); // eslint-disable-next-line
        let validAlphaNumeric = /^[A-Za-z0-9]*$/.test(user.display_name); // eslint-disable-next-line
        if (user.first_name === undefined|| user.first_name === "" || user.first_name.length < 1) { 
            this.setState({first_name_required: "FIRST NAME required!"});
        } else {
            this.setState({first_name_required: true});
        };
        console.log(this.state.first_name)
        if (user.first_name.length !== 0 && user.first_name.length < 2){
            this.setState({first_name_min: "FIRST NAME must contain a minimum of 2 characters!"});
        } else {
            this.setState({first_name_min: true});
        };
        if (user.first_name.length > 15) {
            this.state({first_name_max: "FIRST NAME cannot contain more than 15 characters!"});
        } else {
            this.setState({first_name_max: true});
        };
        if (user.first_name.length !== 0 && validateLetters===false ) {
            this.setState({first_name_valid: "FIRST NAME must contain letters only!"});
        } else {
            this.setState({first_name_valid: true});
        };
        if (user.email === null || user.email === '' || user.email.length < 1) {
            this.setState({email_required:  "EMAIL required!"});
        } else {
            this.setState({email_required: true});
        };
        if (user.email.length > 0 && validEmail===false) {
            this.setState({email_valid: "Invalid EMAIL entry!"});
        } else {
            this.setState({email_valid: true});
        };
        if (user.password === null || user.password === '' || user.password.length < 1) {
            this.setState({password_required: "PASSWORD required!"});
        } else {
            this.setState({password_required: true});
        };
        if ((user.password.length > 0 && user.password.length <= 7) || (user.password.length > 0 && user.password.length > 15)) {
            this.setState({password_min: "PASSWORD can only be between 8-15 characters!"});
        } else {
            this.setState({password_min: true});
        };
        if (user.password.length >= 8 && validPasswordSpecialCharacter===false) {
            this.setState({password_special_character: "PASSWORD must contain at least one special character! (!, @, #, $, %, ^, &, *)"});
        } else {
            this.setState({password_special_character: true});
        };
        if (user.password.length >= 8 && validPasswordNumber===false) {
            this.setState({password_number: "PASSWORD must contain at least one number!"});
        } else {
            this.setState({password_number: true});
        };
        if (user.confirm_password === null || user.confirm_password === '' || user.confirm_password.length < 1) {
            this.setState({confirm_password_required: "CONFIRM PASSWORD required!"});
        } else {
            this.setState({confirm_password_required: true});
        };
        if ((user.confirm_password !== null && user.password.localeCompare(user.confirm_password) === 1) || (user.confirm_password.length !== user.password.length || user.confirm_password !== user.password)) {
            this.setState({confirm_password_match: "PASSWORDS don't match!"});
        } else {
            this.setState({confirm_password_match: true});
        };
        if (user.display_name !== null && user.display_name.length > 15) {
            this.setState({display_name_max_error: "DISPLAY NAME must only contain more than 15 characters!"});
        } else {
            this.setState({display_name_max_error: true});
        };
        if (validAlphaNumeric===false) {
            this.setState({display_name_error: "DISPLAY NAME must only contain letters and numbers with no spaces!"});
        } else {
            this.setState({display_name_error: true});
        };
        if ((user.zip_code !== null && user.zip_code.length !== 5) || (user.zip_code !== null && validNumbers===false)) {
            this.setState({zip_code_error: "Please enter a valid ZIP CODE!"});
        } else {
            this.setState({zip_code_error: true});
        };
        if (this.state.zip_code==='') {
            this.setState({zip_code: null})
        };
        if (this.state.display_name==='') {
            this.setState({display_name: null})
        };
        if (
            (this.state.first_name_required===undefined||this.state.first_name_required===true)&&
            (this.state.first_name_min===undefined||this.state.first_name_min===true)&&
            (this.state.first_name_max===undefined||this.state.first_name_max===true)&&
            (this.state.first_name_valid===undefined||this.state.first_name_valid===true)&&

            (this.state.email_required===undefined||this.state.email_required===true)&&
            (this.state.email_valid===undefined||this.state.email_valid===true)&&

            (this.state.password_required===undefined||this.state.password_required===true)&&
            (this.state.password_min===undefined||this.state.password_min===true)&&
            (this.state.password_special_character===undefined||this.state.password_special_character===true)&&
            (this.state.password_number===undefined||this.state.password_number===true)&&

            (this.state.confirm_password_required===undefined||this.state.confirm_password_required===true)&&
            (this.state.confirm_password_match===undefined||this.state.confirm_password_match===true)&&

            (this.state.display_name_error===undefined||this.state.display_name_error===true)&&
            (this.state.display_name_max_error===undefined||this.state.display_name_max_error===true)&&

            (this.state.zip_code_error===undefined||this.state.zip_code_error===true)
        ) {
            await axios.post(process.env.REACT_APP_SERVER_API + '/auth/signup', user)
                .then(response => {
                    localStorage.setItem('token', response.data.data.token);
                    this.setState({ customer_id: response.data.stripe_customer_id, });
                })
                .then(() => this.setState({redirect: true}))
                .catch(err => {
                    console.log(err, "whats my error here")
                    this.setState({
                        first_name_required: err.response.data.first_name_required,
                        first_name_min: err.response.data.first_name_min,
                        first_name_max: err.response.data.first_name_max,
                        first_name_valid: err.response.data.first_name_valid,
                        email_required: err.response.data.email_required,
                        email_valid: err.response.data.email_valid,
                        email_exists: err.response.data.email_exists,
                        password_required: err.response.data.password_required,
                        password_min: err.response.data.password_min,
                        password_special_character: err.response.data.password_special_character,
                        password_number: err.response.data.password_number,
                        confirm_password_required: err.response.data.confirm_password_required,
                        confirm_password_match: err.response.data.confirm_password_match,
                        zip_code_error: err.response.data.zip_code_error,
                        display_name_error: err.response.data.display_name_error,
                        display_name_exists_error: err.response.data.display_name_exists_error,
                        display_name_max_error: err.response.data.display_name_max_error,
                        first_name: err.response.data.first_name,
                        email: err.response.data.email,
                        password: err.response.data.password,
                        confirm_password: err.response.data.confirm_password,
                        zip_code: err.response.data.zip_code,
                        display_name: err.response.data.display_name,
                        isLoading: false,
                    });
                    if (this.state.email_exists) {
                        this.setState({
                            email_direct: "Please sign in ",
                            email_direct_link: "here.",
                            isLoading: false,
                        })
                    };
                });
        }else{
            this.setState({isLoading:false})
        }
    };
    render() {
        console.log(this.state.first_name, "***********")
        if (this.state.redirect === true) {
            return <Redirect 
                to={{ 
                    pathname: "/profile-setup-profile-picture",
                    data: {
                        email: this.state.email,
                        first_name: this.state.first_name,
                        zip_code: this.state.zip_code,
                        display_name: this.state.display_name,
                        customer_id: this.state.customer_id
                    }
                }}
            />
        };
        let email_exists;
        if (this.state.email_exists) {
            email_exists = <p className="signup-form-error-message">{ this.state.email_exists } {this.state.email_direct} <Link to="/signin" className="r"><strong>{this.state.email_direct_link}</strong></Link></p>

        } else {
            email_exists = null
        }
        const body = this.state.isLoading?<Loading />:
        <div>
            <div className="signup-info-box">
                <Link to="/" className="no-dec"><img className="signup-logo" src={logoIcon} alt="" /></Link>
                <h3 className="signup-info-title r">Sign Up</h3>
                <form onSubmit={this.onSubmit}>
                    <p className="signup-form-error-message">{ this.state.first_name_required }</p>
                    <p className="signup-form-error-message">{ this.state.first_name_min }</p>
                    <p className="signup-form-error-message">{ this.state.first_name_max }</p>
                    <p className="signup-form-error-message">{ this.state.first_name_valid }</p>
                    <input className="form-input" type="text" placeholder={this.state.first_name.length>0?this.state.first_name:"First Name"} value={this.state.first_name.length>0?this.state.first_name:""} onChange={(e)=>this.onChangeFirstName(e)}/>
                    <p className="signup-form-error-message">{ this.state.email_required }</p>
                    <p className="signup-form-error-message">{ this.state.email_valid }</p>
                    {email_exists}
                    <input className="form-input" type="text" placeholder={this.state.email.length>0?this.state.email:"Email"} value={this.state.email.length>0?this.state.email:""} onChange={this.onChangeEmail}/>
                    <p className="signup-form-error-message">{ this.state.password_required }</p>
                    <p className="signup-form-error-message">{ this.state.password_min }</p>
                    <p className="signup-form-error-message">{ this.state.password_number }</p>
                    <p className="signup-form-error-message">{ this.state.password_special_character }</p>
                    <input className="form-input" type="password" placeholder={this.state.password.length>0?this.state.password:"Password"} value={this.state.password.length>0?this.state.password:""} onChange={this.onChangePassword}/>
                    <p className="signup-form-error-message">{ this.state.confirm_password_required }</p>
                    <p className="signup-form-error-message">{ this.state.confirm_password_match }</p>
                    <input className="form-input" type="password" placeholder={this.state.confirm_password.length>0?this.state.confirm_password:"Confirm Password"} value={this.state.confirm_password.length>0?this.state.confirm_password:""} onChange={this.onChangeConfirmPassword}/>
                    {/* <h6 className="signup-info-message-zipcode w">Enter your preferred location for event recommendations around your area.</h6> */}
                    <p className="signup-form-error-message">{ this.state.zip_code_error }</p>
                    <input className="form-input" type="text" placeholder={this.state.zip_code!==null?this.state.zip_code:"Zip Code (Optional)"}  value={this.state.zip_code!==null?this.state.zip_code:null} onChange={this.onChangeZipCode}/>
                    <p className="signup-form-error-message">{ this.state.display_name_error }</p>
                    <p className="signup-form-error-message">{ this.state.display_name_max_error }</p>
                    <p className="signup-form-error-message">{ this.state.display_name_exists_error }</p>
                    <input className="form-input" type="text" placeholder={this.state.display_name!==null?this.state.display_name:"Rider Name (Optional)"} value={this.state.display_name!==null?this.state.display_name:null} onChange={this.onChangeDisplayName}/>
                    <button type="submit" className="form-button">Continue</button>
                </form>
            </div>
            <h6 className="signup-info-footer w">Motorcyk LLC</h6>
        </div>
        return (
            <div className="signup-info-container">{ body }</div>
        );
    };
};
export default SignUpInfo;