import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import '../static/css/Explore.css';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
import tab from '../static/images/images/up-right-from-square-solid.svg';
class Explore extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            status: '',
            isLoading: true,
            redirect: false,
            ui: null,
            image_url: null,
            q: "",
            search: [],
            opacity: 0.5,
            cursor: "default",
            disabled: true,
            zero: false,
            end: null,
            cancelled: false,
            message_unread: false,
            trial_used: false,
            non_active: false,
        };
        this.setQuery = this.setQuery.bind(this);
        this.runSearch = this.runSearch.bind(this);
        this.events = this.events.bind(this);
        this.join = this.join.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui:response.data.id, trial_used: response.data.trial_used===0?false:true, }); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        const config_sub = {
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
                // if (response.data.canceled===true&&response.data.canceled_access===false&&response.data.active_paid===false) {
                //     window.location = `/profile/${this.state.ui}`;
                // };
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        if (this.state.active===false) this.setState({ non_active: true, })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .then(()=> { this.setState({ isLoading: false }) })
            .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
    };
    setQuery(e) {
        this.setState({
            q: e.target.value,
            search: this.state.search,
            opacity: 1, 
            cursor: "pointer",
            disabled: false
        });
    };
    async runSearch(e) {
        e.preventDefault();
        this.setState({isSearching: true, disabled: true})
        const q = this.state.q;
        const id = { id: this.state.ui };
        if (this.state.signed_in) {
            let end_date = new Date(this.state.end);
            let current_date = new Date();
            if ((this.state.active === true) || (this.state.cancelled === true && end_date.setHours(0,0,0,0) > current_date.setHours(0,0,0,0))) {
                await axios.post(process.env.REACT_APP_SERVER_API + `/events/search?q=${q}`, id)
                    .then((response) => { this.setState({ search: response.data }) })
                    .then (() => {
                        if (this.state.search.length === 0) {
                            this.setState({
                                q: "",
                                zero: true,
                                isSearching: false,
                                disabled: false
                            })
                        } else {
                            this.setState({
                                q: "",
                                zero: false,
                                isSearching: false,
                                disabled: false
                            })
                        };
                    })
                    .catch(err => {console.log(err, "Error with retrieving search!")})
            } else {
                await axios.post(process.env.REACT_APP_SERVER_API + `/events/search?q=${q}`, id)
                    .then((response) => { this.setState({ search: response.data }) })
                    .then (() => {
                        if (this.state.search.length === 0) {
                            this.setState({
                                q: "",
                                zero: true,
                                isSearching: false,
                                disabled: false
                            })
                        } else {
                            this.setState({
                                q: "",
                                zero: false,
                                isSearching: false,
                                disabled: false
                            })
                        };
                    })
                    .catch(err => {console.log(err, "Error with retrieving search!")})
            }
        } else {
            this.setState({ redirect: true })
        };
    };
    events () {
        if (this.state.non_active===false) {
            const Event = (props) => (
                <div className="home-event-box explore-event-view">
                    <p className="home-event-date no-pointer">{props.event.formatted_date} @ {props.event.formatted_time}</p>
                    {/* <img className="home-event-map-image" src={map} alt="" /> */}
                    {((props.event.invite_only === 1 || props.event.followers === 1) || parseInt(props.event.max) === props.event.riders_joined) ? <p className="home-event-address b">{props.event.city}, {props.event.state} <br/> STREAMING COMING SOON</p> : <p className="home-event-address"><strong><a className="no-dec b home-event-address-link" href={`http://maps.google.com/?q=1200 ${props.event.street_address}, ${props.event.city}, ${props.event.state}, ${props.event.zip_code}`} target="_blank" rel="noopener noreferrer">{props.event.street_address}, <br/> {props.event.city}, {props.event.state}, {props.event.zip_code} <img className="home-tab-icon" src={tab} alt="" /></a></strong></p>}
                    <Link className="no-dec w" to={`/event/${props.event.id}`}><p className="home-event-title r">{props.event.title}</p></Link>
                    <h5 className="home-event-going no-pointer">{props.event.max ? 'Limit: ' + props.event.max : ''}</h5>
                    <h5 className="home-event-going no-pointer">{props.event.share?'Shared':'Hosted'} {props.event.display_name==="Motorcyk"?"Motorcyk":<Link to={props.event.display_name?`/@${props.event.display_name}`:`/rider/${props.event.user_id}`} className="no-dec b hover">{props.event.display_name ? props.event.display_name : props.event.first_name}</Link>}</h5>
                    {props.event.invite_only === 1 || props.event.followers === 1 ? <button className="home-event-button-private" disabled={true}>{props.event.invite_only === 1 ? "INVITE ONLY" : "FOLLOWERS ONLY"}</button>: 
                    <form onSubmit={(e) => this.join(e, props.event.id)}>
                        {parseInt(props.event.max) === props.event.riders_joined ? <button className="home-event-button-private" disabled={true}>FULL</button> : <button type="submit" className="home-event-button">Join</button>}
                    </form>}
                </div> 
            );
            return this.state.search.map(i => {
                return <Event event={i} key={i.id} />
            }); // eslint-disable-next-line
        } else {
            const Event = (props) => (
                <div className="home-event-box explore-event-view">
                    <p className="home-event-date no-pointer">{props.event.formatted_date} @ {props.event.formatted_time}</p>
                    {/* <img className="home-event-map-image" src={map} alt="" /> */}
                    {((props.event.invite_only === 1 || props.event.followers === 1) || parseInt(props.event.max) === props.event.riders_joined) ? <p className="home-event-address b">{props.event.city}, {props.event.state} <br/> STREAMING COMING SOON</p> : <p className="home-event-address"><strong><a className="no-dec b home-event-address-link" href={`http://maps.google.com/?q=1200 ${props.event.street_address}, ${props.event.city}, ${props.event.state}, ${props.event.zip_code}`} target="_blank" rel="noopener noreferrer">{props.event.street_address}, <br/> {props.event.city}, {props.event.state}, {props.event.zip_code} <img className="home-tab-icon" src={tab} alt="" /></a></strong></p>}
                    <Link className="no-dec w" to={`/event/${props.event.id}`}><p className="home-event-title r">{props.event.title}</p></Link>
                    <h5 className="home-event-going no-pointer">{props.event.max ? 'Limit: ' + props.event.max : ''}</h5>
                    <h5 className="home-event-going no-pointer">{props.event.share?'Shared':'Hosted'} {props.event.display_name==="Motorcyk"?"Motorcyk":<Link to={props.event.display_name?`/@${props.event.display_name}`:`/rider/${props.event.user_id}`} className="no-dec b hover">{props.event.display_name ? props.event.display_name : props.event.first_name}</Link>}</h5>
                </div> 
            );
            return this.state.search.map(i => {
                return <Event event={i} key={i.id} />
            }); // eslint-disable-next-line
        }; 
    };
    async join(e, event_id) {
        e.preventDefault();
        const data = {
            id: event_id,
            user: this.state.ui,
        };
        if (this.state.signed_in) {
            await axios.post(process.env.REACT_APP_SERVER_API + `/event/join`, data)
                .then(() => alert('Joined! 👊 😎 🏍 🚀'))
                .then(() => window.location = '/home')
                .catch((err) => console.log(err, "some error in the background"))
        } else {
            window.location('/signin')
        };
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let events;
        let searching;
        let zero;
        let form;
        if (this.state.search.length > 0) {
            events = this.state.isSearching ? '' : this.events()
        } else {
            events = ''
        };
        if (this.state.isSearching === true) {
            searching = <h5 className="search-event-zero-found">Searching...</h5>
        } else {
            searching = ''
        };
        if (this.state.zero === true) {
            zero = <h5 className="search-event-zero-found">No events found, please try another search...</h5>
        } else {
            zero = ''
        };
        let end_date = new Date(this.state.end);
        let current_date = new Date();
        if ((this.state.active === true) || (this.state.cancelled === true && end_date.setHours(0,0,0,0) > current_date.setHours(0,0,0,0))) {
            form =
                <form className="explore-form" onSubmit={e => {this.runSearch(e)}}>
                    <input className="explore-form-input" type="text" placeholder="Enter City, State, OR Zip Code" name="q" value={this.state.q} onChange={e => {this.setQuery(e)}}/>     
                    <button className="explore-form-button" type="submit" disabled={this.state.disabled} style={{opacity:this.state.opacity, cursor:this.state.cursor}}>Search</button>
                </form>
        } else {
            form = 
                // <div style={{ textAlign: "center" }}>
                //     <p>You must be a member to view other members hosted events! <span role="img" aria-label="">😎</span> <br /><br /> Please activate your membership!</p>
                //     <Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }} className="no-dec"><button className="create-event-form-button">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link><br/>
                //     <Link to="/home" className="no-dec"><button className="create-event-form-button">Go Home</button></Link>
                // </div>
                <form className="explore-form" onSubmit={e => {this.runSearch(e)}}>
                    <input className="explore-form-input" type="text" placeholder="Enter City, State, OR Zip Code" name="q" value={this.state.q} onChange={e => {this.setQuery(e)}}/>     
                    <button className="explore-form-button" type="submit" disabled={this.state.disabled} style={{opacity:this.state.opacity, cursor:this.state.cursor}}>Search</button>
                </form>
        }
        const body = this.state.isLoading?<Loading />: 
        <div className="explore-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/settings" className="home-settings-link no-dec b">Settings</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}
                </ul>
            </div>
            <div className="explore-title-container">
                <h1 className="explore-title" >Explore</h1>
                {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
                {this.state.past_due?<p style={{fontSize: "15px",width: "90%", textAlign: "center", backgroundColor: "white", margin: "20px auto"}}>Your account is currently past due, please update payment method to avoid access interruption. <br/> <Link to={"/change-payment"} className="no-dec" ><button className="edit-profile-form-button">Update Payment</button></Link></p>:''}
            </div>
            <div className="explore-search-items">
                { form }
            </div>
            <div className="explore-available-container" style={{textAlign: "center", marginTop: "20px"}}>
                { searching }
                { zero }
                { events }
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default Explore;