import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/NextPaymentDate.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class NextPaymentDate extends Component {
    constructor() {
        super()
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            status: '',
            isLoading: true,
            redirect: false,
            ui: null,
            image_url: null,
            next_payment: null,
            cancelled: false,
            end: null,
            message_unread: false,
            trial_used: false,
        }
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, }); })
            .catch(err => {
                this.setState({ redirect: true });
            });
        const config_sub = { 
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .catch((err) => {console.log(err, "my error..."); })
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription/next-payment`, config_sub)
            .then((response) => { 
                this.setState({ 
                    active: response.data.status, 
                    next_payment: response.data.next_payment,
                    end: response.data.date,
                    cancelled: response.data.status === "canceled" ? true : false,
                }) 
                var end_date = new Date(response.data.date);
                var current_date = new Date();
                if (response.data.cancelled) {
                    if (end_date.setHours(0,0,0,0) < current_date.setHours(0,0,0,0)) this.setState({ active: response.data.active, end: response.data.date, isLoading: false, }); 
                    else {
                        this.setState({ active: 'cancelled', end: response.data.date, isLoading: false, }); 
                    };
                } else { this.setState({ isLoading: false, }) };
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval"); this.setState({ isLoading: false, }) })
    };
    render() {
        if (this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>;
        let next_payment;
        if(this.state.status.active===true) {
            next_payment =
                <div className="next-payment-date-section">
                    <h3>You currently do not have any active membership!</h3>
                    <Link to="/settings" className="no-dec"><button className="next-payment-date-button">Back</button></Link>
                </div>
        };
        if (this.state.status.active_paid === true) {
            next_payment =
                <div className="next-payment-date-section">
                    <h3>{ this.state.next_payment }</h3>
                    <Link to="/settings" className="no-dec"><button className="next-payment-date-button">Back</button></Link>
                </div>
        } else {
            if (this.state.status.on_trial===true) {
                next_payment =
                    <div className="next-payment-date-section">
                        <p>You are currently on a trial, no payment is due until {this.state.next_payment}.</p>
                        {/* <Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }} className="no-dec"><button className="next-payment-date-button">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link><br /> */}
                        <Link to="/settings" className="no-dec"><button className="next-payment-date-button">Back</button></Link>
                    </div>
            } 
            if(this.state.status.canceled===true && this.state.status.canceled_access===true) {
                next_payment =
                    <div className="next-payment-date-section">
                        <h3>You have already cancelled your membership, so there is no upcoming payment! You can reactivate it after {this.state.end}!</h3>
                        <Link to="/settings" className="no-dec"><button className="next-payment-date-button">Back</button></Link>
                    </div>
            };
        };
        const body = this.state.isLoading?<Loading />: 
        <div className="next-payment-date-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/settings" className="home-explore-link no-dec b">Settings</Link></li>
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}                
                </ul>
            </div>
            <div className="next-payment-date-title-container">
                <h1 className="next-payment-date-title" >Next Payment Date</h1>
                {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
            </div>
            { next_payment }
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default NextPaymentDate;