import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from './LoadingPage';
import axios from 'axios';
import '../static/css/SignUpImageUpload.css';
import user from '../static/images/images/user-circle-solid.svg';
import ImageUploading from 'react-images-uploading';
class SignUpImageUpload extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            isLoading: true,
            redirect: false,
            ui: null,
            user: '',
            image: null,
            image_url: null,
            disabled: false,
            new_image: null,
            file_name: null,
            end: null,
            cancelled: false,
            message_unread: false,
            trial_used: false,
            non_active: false,
            error: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, }); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        const config_sub = { 
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    active: response.data.status,
                    end: response.data.date,
                    cancelled: response.data.status === "canceled" ? true : false, 
                });
                var end_date = new Date(response.data.date);
                var current_date = new Date();
                if (response.data.cancelled) {
                    if (end_date.setHours(0,0,0,0) < current_date.setHours(0,0,0,0)) {
                        this.setState({ active: response.data.status, end: response.data.date, }); 
                    }; 
                };
                return response.data;
            })
            // .then(async () => {
            //     var end_date = new Date(this.state.end);
            //     var current_date = new Date();
            //     if ((this.state.active === true) || ((this.state.cancelled === true) && (end_date.setHours(0,0,0,0))) > current_date.setHours(0,0,0,0)) {
                    
            //     } else {
            //         window.location = "/activate-membership";
            //     };
            // })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        if (this.state.active) {
            await axios.get(process.env.REACT_APP_SERVER_API + `/myprofile/edit/${this.state.ui}`)
                .then( (response) => {
                    this.setState({ 
                        image: response.data.image,
                        user: response.data,
                    }); 
                })
                .catch( (err) => {console.log(err, "Error in retreiving  user details!"); this.setState({ event_error: true, isLoading: false, disabled: false })})
            await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
                .then((response) => { this.setState({ image_url: response.data })})
                .then(()=> { this.setState({ isLoading: false }) })
                .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
        } else {
            await axios.get(process.env.REACT_APP_SERVER_API + `/myprofile/edit/${this.state.ui}`)
                .then( (response) => {
                    this.setState({ 
                        image: response.data.image,
                        user: response.data,
                        non_active: true,
                    }); 
                })
                .catch( (err) => {console.log(err, "Error in retreiving  user details!"); this.setState({ event_error: true, isLoading: false, disabled: false })})
            await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
                .then((response) => { this.setState({ image_url: response.data })})
                .then(()=> { this.setState({ isLoading: false }) })
                .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
        };
    };
    onChange = (picture) => {
        this.setState({new_image: picture, file_name: picture[0].file.name});
    };
    async onSubmit(event) {
        event.preventDefault();
        if (this.state.new_image===null) {
            this.setState({ error: true, })
        } else {
            this.setState({ disabled: true, isLoading: true, error: false, });
            const image = this.state.new_image;
            const data = new FormData();
            data.append('image', image[0].file, image[0].file.name);
            data.append('ui', this.state.ui);
            const config = { headers: { 'content-type': 'multipart/form-data', 'Access-Control-Allow-Origin': '*' } };
            if (this.state.signed_in) {
                await axios.post(process.env.REACT_APP_SERVER_API + '/profile/upload/image', data, config)
                    .then(() => window.location="/signup-plan-trial-signup")
                    .catch(err => { this.setState({ disabled: false, isLoading: false, }); });
            } else {
                window.location = '/signin';
            };
        };
    };
    sign_out = () => {
        localStorage.removeItem('token');
        this.setState({ signed_in: false, FirstName: '' })
    }

    render() {
        if(this.state.redirect === true) return <Redirect to={{pathname: "/signin"}}/>
        let file_name;
        let error_message = this.state.error===false?'':<p className="r">Please select an image!</p>
        if (this.state.file_name) file_name = <p className="edit-profile-file-name">{this.state.file_name}</p>
        let image;
        if (this.state.image_url !== null) {
            image = this.state.isLoading ? '' : <div className="signup-image-upload-container-s3" style={{backgroundImage: `url(${this.state.image_url})`}}></div>
        } else {
            image = this.state.isLoading ? '' : <img className="signup-image-upload-image" src={user} alt="" />
        };
        let end_date = new Date(this.state.end);
        let current_date = new Date();
        let form;
        if ((this.state.active === true) || ((this.state.cancelled === true) && (end_date.setHours(0,0,0,0))) > current_date.setHours(0,0,0,0)) {
            form =
                <div className="signup-image-upload-edit-section">
                    { image }
                    <form onSubmit={this.onSubmit}>
                        {file_name}
                        {error_message}
                        <div className="signup-image-upload-icon-container">
                            <ImageUploading
                                multiple
                                value={this.state.file_name}
                                onChange={this.onChange}
                                // onChange={this.onDrop}
                                maxNumber={1}
                                dataURLKey="data_url"
                                acceptType={["jpg", "jpeg", "heic", "png", "gif", "webp", "heif"]}
                                allowNonImageType={true}
                            >
                                {({
                                onImageUpload,
                                isDragging,
                                dragProps,
                                }) => (
                                // write your building UI
                                <div className="upload__image-wrapper">
                                    <button
                                        type="button"
                                        className="signup-image-upload-button"
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                    >
                                    Click or Drop here
                                    </button>
                                    &nbsp;
                                </div>
                                )}
                            </ImageUploading>
                        </div>
                        <button className="signup-image-upload-form-button" disabled={this.state.disabled ? true : false}>{this.state.disabled ? 'Uploading...' : 'Continue'}</button>
                        <Link to="/signup-plan-trial" className="no-dec bottom-button"><button className="signup-image-upload-form-button back">Skip</button></Link>
                    </form>
                </div>
        } else {
            form =
                <div className="signup-image-upload-edit-section">
                    { image }
                    <form onSubmit={this.onSubmit}>
                        {file_name}
                        {error_message}
                        <div className="signup-image-upload-icon-container">
                            <ImageUploading
                                multiple
                                value={this.state.file_name}
                                onChange={this.onChange}
                                // onChange={this.onDrop}
                                maxNumber={1}
                                dataURLKey="data_url"
                                acceptType={["jpg", "jpeg", "heic", "png", "gif", "webp", "heif"]}
                                allowNonImageType={true}
                            >
                                {({
                                onImageUpload,
                                isDragging,
                                dragProps,
                                }) => (
                                // write your building UI
                                <div className="upload__image-wrapper">
                                    <button
                                        type="button"
                                        className="signup-image-upload-button"
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                    >
                                    Click or Drop here
                                    </button>
                                    &nbsp;
                                </div>
                                )}
                            </ImageUploading>
                        </div>
                        <button className="signup-image-upload-form-button" disabled={this.state.disabled ? true : false}>{this.state.disabled ? 'Uploading...' : 'Continue'}</button>
                        <Link to="/signup-plan-trial" className="no-dec bottom-button"><button className="signup-image-upload-form-button back">Skip</button></Link>
                    </form>
                </div>
        };
        const body = this.state.isLoading?<Loading />: 
        <div className="signup-image-upload-container">
            {/* <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {this.state.non_active===false?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>:                    <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    {this.state.non_active===true?<li ><Link to="/" onClick={this.sign_out} className="home-home-link no-dec b">Sign Out</Link></li>:''}
                    {this.state.non_active===true?<li><Link to="/settings" className="home-settings-link no-dec b">Settings</Link></li>:''}
                    {this.state.non_active===false?<li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>:''}
                    {this.state.non_active===false?<li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>:''}
                    {this.state.non_active===true?<li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>:''}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.non_active===false?<li ><Link to="/home" className="home-profile-link no-dec b">Home</Link></li>:''}
                    {this.state.non_active===true?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</Link></li>:''}
                </ul>
            </div> */}
            <div className="signup-image-upload-title-container">
                <h1 className="signup-image-upload-title">Upload Profile Picture</h1>
            </div>
            { form }
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default SignUpImageUpload;