import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import '../static/css/MyProfile.css';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import Saved from './ProfileSaved';
import Attending from './ProfileAttending';
import Hosting from './ProfileHosting';
import Posts from './ProfilePosts';
import Feed from './ProfileFeed';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
import mv from '../static/images/images/motorcyk_verified.svg';
class MyProfile extends Component {
    constructor(props) {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            status: '',
            isLoading: true,
            redirect: false,
            image_url: null,
            first_name: '',
            display_name: '', 
            zip_code: null,
            profile_header_message: '',
            city: null,
            state: null,
            created_at: null,
            ui: null,
            user_profile: props.match.params.id,
            current_tab: 'feed',
            feed_clicked: '#FF2F46',
            feed_font: 'Permanent Marker',
            feed_font_size: '15px',
            posts_clicked: 'black',
            posts_font: 'Poppins',
            posts_font_size: '10px',
            attending_clicked: 'black',
            attending_font: 'Poppins',
            attending_font_size: '10px',
            hosting_clicked: 'black',
            hosting_font: 'Poppins',
            hosting_font_size: '10px',
            saved_clicked: 'black',
            saved_font: 'Poppins',
            saved_font_size: '10px',
            hosting_events: [],
            attending_events: [],
            saved_events: [],
            followers_count: 0,
            following_count: 0,
            posts: [],
            end: null,
            cancelled: false,
            message_unread: false,
            feed: [],
            trial_used: false,
            copy_link: "Copy/Share Profile Link",
        };
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => {
                if (parseInt(this.state.user_profile) !== response.data.id) window.location = `/${this.state.user_profile}`;
                this.setState({ 
                    ui: response.data.id,
                    first_name: response.data.first_name,
                    display_name: response.data.display_name === null ? '' : response.data.display_name, 
                    zip_code: response.data.zip_code,
                    city: response.data.city,
                    state: response.data.state,
                    created_at: response.data.created_at,
                    profile_header_message: response.data.profile_header_message,
                    trial_used: response.data.trial_used===0?false:true,
                })
            })
            .catch(err => { this.setState({ redirect: true }) });
        const config_sub = { headers: { Authorization: `Bearer ${token}`, },  id: this.state.ui, };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({  status: response.data, });
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .catch((err) => {console.log(err, "my error..."); })
        await axios.get(process.env.REACT_APP_SERVER_API + `/user/myprofile/${this.state.ui}`)
            .then((response) => { this.setState({ following_count: response.data[1].length, followers_count: response.data[2].length }) })  
            .catch((err) => { console.log(err, "whats my error in getting user info") }) 
        await axios.get(process.env.REACT_APP_SERVER_API + `/user/posts/${this.state.ui}`)
            .then((response) => { this.setState({ posts: response.data }) })
            .catch(err => { console.log(err, "Error retrieving posts!") });
        await axios.get(process.env.REACT_APP_SERVER_API + `/user/events/hosting/${this.state.ui}`)
            .then((response) => { this.setState({ hosting_events: response.data }) })
            .catch((err) => { console.log(err, "whats my error in getting hosted events") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/user/events/attending/${this.state.ui}`)
            .then((response) => { this.setState({ attending_events: response.data, }) })
            .catch((err) => { console.log(err, "whats my error in getting attending events"); })
        await axios.get(process.env.REACT_APP_SERVER_API + `/user/feed/${this.state.ui}`)
            .then((response) => { this.setState({ feed: response.data, }) })
            .catch((err) => { console.log(err, "whats my error in getting attending events"); })
        await axios.get(process.env.REACT_APP_SERVER_API + `/user/events/saved/${this.state.ui}`)
            .then((response) => { this.setState({ saved_events: response.data, isLoading: false }) })
            .catch((err) => { console.log(err, "whats my error in getting saved"); this.setState({ isLoading: false, }) })
    };
    display = (name) => {
        this.setState({
            current_tab: name,
            clicked: true
        });
        if (name === 'feed') {
            this.setState({
                saved_clicked: 'black',
                saved_font: 'Poppins',
                saved_font_size: '10px',
                attending_clicked: 'black',
                attending_font: 'Poppins',
                attending_font_size: '10px',
                hosting_clicked: 'black',
                hosting_font: 'Poppins',
                hosting_font_size: '10px',
                posts_clicked: 'black',
                posts_font: 'Poppins',
                posts_font_size: '10px',
                feed_clicked: '#FF2F46',
                feed_font: 'Permanent Marker',
                feed_font_size: '15px',
            });
        };
        if (name === 'posts') {
            this.setState({
                saved_clicked: 'black',
                saved_font: 'Poppins',
                saved_font_size: '10px',
                attending_clicked: 'black',
                attending_font: 'Poppins',
                attending_font_size: '10px',
                hosting_clicked: 'black',
                hosting_font: 'Poppins',
                hosting_font_size: '10px',
                posts_clicked: '#FF2F46',
                posts_font: 'Permanent Marker',
                posts_font_size: '15px',
                feed_clicked: 'black',
                feed_font: 'Poppins',
                feed_font_size: '10px',
            });
        };
        if (name === 'attending') {
            this.setState({
                saved_clicked: 'black',
                saved_font: 'Poppins',
                saved_font_size: '10px',
                attending_clicked: '#FF2F46',
                attending_font: 'Permanent Marker',
                attending_font_size: '15px',
                hosting_clicked: 'black',
                hosting_font: 'Poppins',
                hosting_font_size: '10px',
                posts_clicked: 'black',
                posts_font: 'Poppins',
                posts_font_size: '10px',
                feed_clicked: 'black',
                feed_font: 'Poppins',
                feed_font_size: '10px',
            });
        };
        if (name === 'hosting') {
            this.setState({
                saved_clicked: 'black',
                saved_font: 'Poppins',
                saved_font_size: '10px',
                attending_clicked: 'black',
                attending_font: 'Poppins',
                attending_font_size: '10px',
                hosting_clicked: '#FF2F46',
                hosting_font: 'Permanent Marker',
                hosting_font_size: '15px',
                posts_clicked: 'black',
                posts_font: 'Poppins',
                posts_font_size: '10px',
                feed_clicked: 'black',
                feed_font: 'Poppins',
                feed_font_size: '10px',
            });
        };
        if (name === 'saved') {
            this.setState({
                saved_clicked: '#FF2F46',
                saved_font: 'Permanent Marker',
                saved_font_size: '15px',
                attending_clicked: 'black',
                attending_font: 'Poppins',
                attending_font_size: '10px',
                hosting_clicked: 'black',
                hosting_font: 'Poppins',
                hosting_font_size: '10px',
                posts_clicked: 'black',
                posts_font: 'Poppins',
                posts_font_size: '10px',
                feed_clicked: 'black',
                feed_font: 'Poppins',
                feed_font_size: '10px',
            });
        };
    };
    copy_reverse() {
        this.setState({ copy_link: "Copy/Share Profile Link", })
    };
    copy() {
        const link = this.state.display_name?`https://www.motorcyk.com/@${this.state.display_name}`:`https://www.motorcyk.com/rider/${this.state.ui}`;
        navigator.clipboard.writeText(link);
        this.setState({ copy_link: "Link Copied To Clipboard!", })
    };
    sign_out = () => {
        localStorage.removeItem('token');
        this.setState({ signed_in: false, FirstName: '' })
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let tab;
        switch (this.state.current_tab) {
            case 'feed':
                tab = <Feed data={this.state} />
                break;
            case 'posts':
                tab = <Posts data={this.state} />
                break;
            case 'attending':
                tab = <Attending data={this.state} />
                break;
            case 'hosting':
                tab = <Hosting data={this.state} />
                break;
            case 'saved':
                tab = <Saved data={this.state} />
                break;
            default:
                tab = <Feed data={this.state} />
        };
        let name;
        let location;
        let image;
        if (this.state.display_name.length > 0) name = this.state.display_name;
        else name = this.state.first_name;
        if (this.state.zip_code !== null) location = <h3 className="profile-detail-item font-1 r">{this.state.city}{this.state.city ? ', ' : ''}{this.state.state}</h3>
        if (this.state.image_url !== null) image = this.state.isLoading ? '' : <div className="my-profile-image-container-s3" style={{backgroundImage: `url(${this.state.image_url})`}}></div>
        else image = this.state.isLoading ? '' :  <img className="profile-pic" src={user} alt=""/>
        const body = this.state.isLoading?<Loading />: 
        <div className="home-container">
            <div className="home-header">
                <h5 className="home-header-title"><Link to="/" className="no-dec"><img className="header-logo" src={logo} alt="" /></Link></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false&&(this.state.status.canceled===true && this.state.status.canceled_access===false)?<li ><Link to="/" onClick={this.sign_out} className="home-home-link no-dec b">Sign Out</Link></li>:''}
                    {this.state.status.active===true?<li><Link to="/settings" className="home-settings-link no-dec b">Settings</Link></li>:''}
                    {this.state.status.active===true?<li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>:''}
                    {this.state.status.active===true?<li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>:''}
                    {this.state.status.active===true?<li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>:''}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li ><Link to="/home" className="home-profile-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}
                </ul>
            </div>
            <div className="profile-admin-view"><p>ADMIN VIEW</p></div>
            {this.state.past_due?<p style={{fontSize: "15px",width: "90%", textAlign: "center", backgroundColor: "white", margin: "20px auto"}}>Your account is currently past due, please update payment method to avoid access interruption. <br/> <Link to={"/change-payment"} className="no-dec" ><button className="edit-profile-form-button">Update Payment</button></Link></p>:''}
            <div className="profile-pic-info-items">
                <div className="profile-pic-item">
                    { image }
                </div>
                <div className="profile-info-items">
                    <h2 className="profile-name-item">
                        {name} {this.state.status.active_paid===true||this.state.status.on_trial?<img className="home-messages-icon" title="Verified Member" style={{width: 24, height: 24, marginBottom: -6}} src={mv} alt="" />:''}
                    </h2>
                    <p className="profile-detail-item">Joined <strong>{this.state.created_at}</strong></p>
                    {/* <p className="profile-detail-item">Skill level Expert</p>  */}
                    {location}
                </div>
            </div>
            <h6 className="profile-message-status">{this.state.profile_header_message}</h6>   
            <div className="profile-follow-info">
                <Link to={`/${this.state.user_profile}/followers`} className="profile-followers-link b">Followers {this.state.followers_count}</Link>
                <Link to={`/${this.state.user_profile}/following`} className="profile-following-link b">Following {this.state.following_count}</Link><br/><br/>
                {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
            </div>
            <div className="my-profile-button-container">
                <Link to={`/edit-profile/${this.state.ui}`} className="no-dec"><button className="my-profile-button">Edit Profile Details</button></Link>
                <Link to={`/edit-image/${this.state.ui}`} className="no-dec"><button className="my-profile-button">Edit Profile Image</button></Link>
                <Link to={`/rider-tip-jar`} className="no-dec"><button className="my-profile-button">Enable/Disable Tip Jar</button></Link>
                <Link to={`/search-rider`} className="no-dec"><button className="my-profile-button">Search Rider</button></Link>
                <Link to={this.state.display_name?`/@${this.state.display_name}`:`/rider/${this.state.ui}`} className="no-dec"><button className="my-profile-button">View Public Profile</button></Link>
                <button className="my-profile-button" onClick={this.copy}>{this.state.copy_link}</button>
            </div>
            <div className="profile-tab-container">
                <p className="profile-posts-tab"  style={{ color: this.state.feed_clicked, fontFamily: this.state.feed_font, fontSize: this.state.feed_font_size }} onClick={() => this.display('feed')}>Feed</p>
                <p className="profile-posts-tab"  style={{ color: this.state.posts_clicked, fontFamily: this.state.posts_font, fontSize: this.state.posts_font_size }} onClick={() => this.display('posts')}>Posts</p>
                <p className="profile-attending-tab" style={{ color: this.state.attending_clicked, fontFamily: this.state.attending_font, fontSize: this.state.attending_font_size }} onClick={() => this.display('attending')}>Attending</p>
                <p className="profile-hosting-tab" style={{ color: this.state.hosting_clicked, fontFamily: this.state.hosting_font, fontSize: this.state.hosting_font_size }} onClick={() => this.display('hosting')}>Hosting</p>
                <p className="profile-posts-tab"  style={{ color: this.state.saved_clicked, fontFamily: this.state.saved_font, fontSize: this.state.saved_font_size }} onClick={() => this.display('saved')}>Saved Events</p>
            </div>
            <div className="profile-active-tab">
                {tab}
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default MyProfile;