import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Loading from '../components/LoadingPage';
import '../static/css/ProfilePosts.css';
import Popup from 'reactjs-popup';
class ProfilePosts extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            delete: "block",
            confirm: "none",
            image_selected: null,
            delete_error: "none"
        };
        this.delete = this.delete.bind(this);
        this.cancel_delete = this.cancel_delete.bind(this);
        this.confirm_delete = this.confirm_delete.bind(this);
    };

    delete(e, id) {
        e.preventDefault()
        this.setState({ 
            delete: "none",
            confirm: "block",
            image_selected: id,
        });
    };

    cancel_delete() {
        this.setState({
            delete: "block",
            confirm: "none",
            image_selected: null,
        });
    };

    async confirm_delete() {
        this.setState({ 
            isLoading: true, 
            delete: "block",
            confirm: "none",
            image_selected: null,
        });
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + `/user/delete/post/${this.props.data.ui}/${this.state.image_selected}`, config)
            .then(() => { alert("Post deleted!") })
            .then(() => window.location.reload())
            .catch((err) => { console.log(err, "Error deleteing post"); this.setState({ delete_error: "default", isLoading: false, }) });
    };

    posts() {
        const Post = (props) => (
            <div className="profile-posts-post no-dec">
                <Popup 
                    onClick={() => {console.log(props.post.id, "id inside pop up?")}}                        
                    trigger={
                        <img src={props.post.image} alt="" className="button profile-posts-image" onClick={() => {console.log(props.post.id, "id inside pop up?")}}  />
                    } 
                    arrow={false} 
                    modal
                >
                    {/* <div className="profile-posts-post-pop-up">
                        <img src={props.post.image} alt="" className="profile-posts-image-test" />
                    </div> */}
                    {close => (
                        <div className="modal">
                            <button className="close" onClick={() => close }>&times;</button>
                            <img src={props.post.image} alt="" className="profile-posts-image-test" />
                            <button className="profile-posts-delete-button" style={{ width:"90%", margin: "auto", marginTop: "10px", display: this.state.delete}} onClick={(e) => this.delete(e, props.post.id)}>Delete</button>
                        </div>
                    )}
                </Popup>
            </div>
        );
        return this.props.data.posts.map(post => {
            return  <Post post={post} key={post.id} />
        });
    };
    render() { 
        if (this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let posts;
        let posts_message;
        let statement;
        let active_statement;
        let delete_error = <p className="profile-posts-statement" style={{display: this.state.delete_error, color: "#FF2F46"}}>There was an error deleting image, please try again later!</p>
        if (this.props.data.status.active === true) {
            active_statement = this.props.data.isLoading ? '' : <p className="profile-posts-statement">You haven't posted anything yet! <span role="img" aria-label="">😔</span><br /> Upload your first image from one of your riding adventures below! 😎</p>
        } else {
            active_statement = this.props.data.isLoading ? '' : <p className="profile-posts-statement">Activate your membership to post!</p>
        };
        // if (this.props.data.non_active===true) {
        //     posts_message = this.props.data.isLoading ? '' : <p className="profile-posts-statement">You haven't posted anything yet! <span role="img" aria-label="">😔</span><br /> Upload your first image from one of your riding adventures below! 😎</p>

        // }
        if (this.props.data.posts.length > 0) {
            posts = this.props.data.isLoading ? '' : this.posts();
            statement = '';
        } else {
            posts = '';
            statement = this.props.data.isLoading ? '' : active_statement;          
        };
        const body = (this.props.data.isLoading||this.state.isLoading)?<Loading />:
        <div className="profile-posts-container" style={{opacity:`${this.state.opacity}`}}>
            <div className="profile-posts-statement-container">
                { delete_error }
                { statement }
                { posts_message }
            </div>
            { this.props.data.status.active === true ? <Link to="/upload-post" className="profile-posts-upload no-dec">Upload</Link> : <Link to={{ pathname: this.props.data.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="profile-posts-upload no-dec">{this.props.data.trial_used===false?"Activate Free Trial":"Activate Membership"}</Link>}
            <div className="profile-posts-post-container">
                { posts }
            </div>
        </div>
        return (
            <div className="profile-posts-container" style={{opacity:`${this.state.opacity}`}}>
                { body }
                <div className="profile-posts-confirm" style={{display: this.state.confirm}}>
                    <p style={{ margin: "auto", color: "#FF2F46", display: this.state.confirm}}>Are you sure?</p>
                    <button className="profile-post-delete-confirm-button" style={{ width:"90%", margin: "auto", marginTop: "10px", display: this.state.confirm}} onClick={this.confirm_delete}>Yes</button>
                    <button className="profile-post-delete-confirm-button" style={{ width:"90%", margin: "auto", marginTop: "10px", display: this.state.confirm}} onClick={this.cancel_delete}>No</button>
                </div>
            </div>
        );
    };
};
export default ProfilePosts;


