import React, { Component } from 'react';
import Loading from '../components/LoadingPage';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import '../static/css/SignUp.css';
import logoIcon from '../static/images/images/logo_icon.png';
const google = window.google;
class SignUp extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            redirect: false,
            redirect_signin: false,
            user: null,
            first_name: null,
            email: null,
            user_id: null,
            provider_id: null,
            g_error: null,
            g_error_link: null,
            e_error: null,
            e_error_link: null,
            customer_id: null,
        };
    };
    signup_g = async (response) => {
        var userObject = await jwt_decode(response.credential);
        this.setState({ 
            user: userObject,
            first_name: userObject.given_name,
            email: userObject.email,
            user_id: userObject.sub,
        });
        const user = {
            first_name: this.state.first_name,
            email: this.state.email,
            user_id: this.state.user_id,
        }
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/signup_g', user)
            .then(response => { 
                localStorage.setItem('token', response.data.data.token);
                this.setState({ customer_id: response.data.stripe_customer_id });
            })
            .then(() => this.setState({redirect: true}))
            .catch(err => {
                console.log(err);
                if (err.response.data === "GTRUE") {
                    this.setState({
                        g_error: "Account with Google login already exits, please sign in ",
                        g_error_link: "HERE",
                        e_error: null,
                        e_error_lilnk: null
                    });
                };
                if (err.response.data === "ETRUE") {
                    this.setState({
                        e_error: "Email is already associated with an account, please sign in ",
                        e_error_link: "HERE",
                        g_error: null,
                        g_error_link: null,
                    });
                };
            })
    };
    async componentDidMount () {
        this.setState({ isLoading: true });
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( () => { this.setState({ redirect: true }) })
            .catch(err => { console.log(err, "No valid token found!"); this.setState({ isLoading: false })
        });
        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID,
            callback: this.signup_g,
        });
        google.accounts.id.renderButton(
            document.getElementById("buttonDiv"),
            { 
                type: "standard",
                shape: "rectangular",
                theme: "outline", 
                text: "signup_with",
                size: "large", 
                logo_alignment: "center",
                width: "250px"
            }  
        );
    };
    render() {
        if(this.state.redirect_signin === true) return <Redirect to={{pathname: "/signin"}}/>
        if (this.state.redirect === true) {
            return <Redirect 
                to={{ 
                    pathname: "/profile-confirm-details",
                    data: {
                        email: this.state.email,
                        first_name: this.state.first_name,
                        customer_id: this.state.customer_id
                    }
                }}
            />
        };
        const body = this.state.isLoading?<Loading />:
        <div className="signup-container"> 
            <div className="signup-box">
                <Link to="/" className="no-dec"><img className="signup-logo-icon" src={logoIcon} alt="" /></Link>
                <h3 className="signup-box-title r">Sign Up</h3>
                <p className="signup-form-error-message r">{ this.state.g_error }<Link to="/signin" className="no-dec r"><strong><u>{this.state.g_error_link}</u></strong></Link></p>
                <p className="signup-form-error-message r">{ this.state.e_error }<Link to="/signin" className="no-dec r"><strong><u>{this.state.e_error_link}</u></strong></Link></p>
                <div className="signup-google-button-content">
                    <div className="signup-with-google-container">
                        <div id="buttonDiv"></div> 
                    </div> 
                </div>
                <Link to="/signup-info" className="no-dec"><button className="signup-with-email">Continue With Email</button></Link>
                <h6 className="signup-box-signup">Already have an acocunt? <Link to="/signin" className="no-dec"><strong>Sign In</strong></Link></h6>
                <h6 className="signup-box-signup">
                    By continuing, you agree to Motorcyk's <Link to="/terms-of-service-read" className="no-dec"><strong>Terms Of Service</strong></Link>. We Will manage information about you as described in our <Link to="/privacy-read" className="no-dec"><strong>Privacy Policy</strong></Link>, and <Link to="/cookies-read" className="no-dec"><strong>Cookie Policy</strong></Link>.
                </h6>
            </div>
            <h6 className="signup-footer w">Motorcyk LLC</h6>
        </div>
        return (
            <div>
                { body }
            </div>
        );
    };
};
export default SignUp;