import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import '../static/css/SearchRider.css';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class SearchRider extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            status: '',
            isLoading: true,
            isSearching: false,
            redirect: false,
            ui: null,
            image_url: null,
            q: "",
            search: [],
            opacity: 0.5,
            cursor: "default",
            disabled: true,
            zero: false,
            message_unread: false,
            trial_used: false,
        };
        this.setQuery = this.setQuery.bind(this);
        this.runSearch = this.runSearch.bind(this);
        this.riders = this.riders.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui:response.data.id, trial_used: response.data.trial_used===0?false:true, }); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        const config_sub = { headers: { Authorization: `Bearer ${token}`, }, id: this.state.ui, };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data, isLoading: false  })})
            .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
    };
    setQuery(e) {
        this.setState({
            q: e.target.value,
            search: this.state.search,
            opacity: 1, 
            cursor: "pointer",
            disabled: false
        });
    };
    async runSearch(e) {
        e.preventDefault();
        this.setState({isSearching: true, disabled: true})
        const q = this.state.q
        await axios.get(process.env.REACT_APP_SERVER_API + `/riders/search?q=${q}`)
            .then((response) => { this.setState({ search: response.data }) })
            .then (() => {
                if (this.state.search.length === 0) {
                    this.setState({
                        q: "",
                        zero: true,
                        isSearching: false,
                        disabled: false
                    })
                } else {
                    this.setState({
                        q: "",
                        zero: false,
                        isSearching: false,
                        disabled: false
                    });
                };
            })
            .catch(err => {console.log(err, "Error with retrieving search!")})
    };
    riders () {
        const Rider = (props) => (
            <div className="search-rider-profile-container-user">
                <Link to={props.rider.display_name?`/@${props.rider.display_name}`:`/rider/${props.rider.id}`} className="search-rider-user-icon-link b" >
                    <img className="search-rider-user-icon" src={props.rider.image_url ? props.rider.image_url : user} alt="" />
                    <p className="search-rider-text-name-user r">{props.rider.display_name ? props.rider.display_name : props.rider.first_name}</p>
                </Link>
            </div>
        );
        return this.state.search.map(i => {
            return <Rider rider={i} key={i.id} />
        });
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let riders;
        let searching;
        let zero;
        let active;
        if (this.state.search.length > 0) riders = this.state.isSearching ? '' : this.riders()
        else riders = ''
        if (this.state.isSearching === true) searching = <h5 className="search-rider-zero-found">Searching...</h5>
        else searching = ''
        if (this.state.zero === true) zero = <h5 className="search-rider-zero-found">No riders found, please try another search...</h5>
        else zero = ''
        if (this.state.active!==false) {
            active =
                <form onSubmit={e => {this.runSearch(e)}}>
                    <input className="search-rider-form-input" type="text" placeholder="Enter Rider Name Or Zip Code..." name="q" value={this.state.q} onChange={e => {this.setQuery(e)}}/>
                    <button className="search-rider-form-button" type="submit" disabled={this.state.disabled} style={{opacity:this.state.opacity, cursor:this.state.cursor}}>Search</button>
                </form>
        } else {
            active =
                <form onSubmit={e => {this.runSearch(e)}}>
                    <input className="search-rider-form-input" type="text" placeholder="Enter Rider Name Or Zip Code..." name="q" value={this.state.q} onChange={e => {this.setQuery(e)}}/>
                    <button className="search-rider-form-button" type="submit" disabled={this.state.disabled} style={{opacity:this.state.opacity, cursor:this.state.cursor}}>Search</button>
                </form>
            // active =
            //     <div>
            //         <p>You must have an active membership to explore other members! <span role="img" aria-label="">😎</span><br />Please activate your memebership!</p>
            //         <Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="no-dec"><button className="search-rider-form-button">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link><br/><br/>
            //         <Link to={`/home`} className="no-dec"><button className="search-rider-form-button">Go home</button></Link>
            //     </div>
        }
        const body = this.state.isLoading?<Loading />: 
        <div className="search-rider-container">
            <div className="home-header">
                <h5 className="home-header-title"><Link to="/" className="no-dec"><img className="header-logo" src={logo} alt="" /></Link></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/settings" className="home-settings-link no-dec b">Settings</Link></li>
                    {this.state.active===true?<li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>:""}
                    {this.state.active===true?<li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>:""}
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}
                </ul>
            </div>
            <h1 className="search-rider-title r">Search Rider</h1>
            <div className="search-rider-user-icons-container">
                { active }
                { searching }
                { zero }
                { riders }
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default SearchRider;