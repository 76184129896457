import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import qs from 'qs';
import '../static/css/Rider.css';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import Attending from './RiderAttending';
import Hosting from './RiderHosting';
import Posts from './RiderPosts';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
import Instagram from '../static/images/images/instagram.svg';
import Facebook from '../static/images/images/facebook.svg';
import Twitter from '../static/images/images/twitter.svg';
import Pinterest from '../static/images/images/pinterest.svg';
import Youtube from '../static/images/images/youtube.svg';
import Rumble from '../static/images/images/rumble.svg';
import TikTok from '../static/images/images/tiktok.svg';
import Snapchat from '../static/images/images/snapchat.svg';
import Tumblr from '../static/images/images/tumblr.svg';
import mv from '../static/images/images/motorcyk_verified.svg';
class Rider extends Component {
    constructor(props) {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            status: '',
            guest: true,
            isLoading: true,
            redirect: false,
            first_name: '',
            display_name: '', 
            zip_code: null,
            city: null,
            state: null,
            instagram: null,
            facebook: null,
            twitter: null,
            tiktok: null,
            pinterest: null,
            snapchat: null,
            youtube: null,
            rumble: null,
            tumblr: null,
            created_at: null,
            image_url: null,
            image_url_rider: null,
            ui: null,
            rider_id: null,
            user_profile: props.match.params.rider?props.match.params.rider:props.match.params.id,
            current_tab: 'posts',
            posts_clicked: '#FF2F46',
            posts_font: 'Permanent Marker',
            attending_clicked: 'black',
            attending_font: 'Poppins',
            hosting_clicked: 'black',
            hosting_font: 'Poppins',
            hosting_events: [],
            attending_events: [],
            profile_header_message: '',
            following: false,
            followers_count: 0,
            following_count: 0,
            posts: [],
            end: null,
            cancelled: false,
            rider_profile: false,
            message_unread: false,
            trial_used: false,
            rider_jar_enabled: false,
            tip_five_id: null,
            tip_ten_id: null,
            tip_twentytwo_id: null,
            tip_custom_id: null,
            tip: null,
            tip_session: props.location.search.length === 0 ? null : qs.parse(props.location.search, { ignoreQueryPrefix: true }).session_id,
        };
        this.plan = this.plan.bind(this);
        this.delete = this.unfollow.bind(this);
        this.join = this.follow.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, guest: false, trial_used: response.data.trial_used===0?false:true}) })
            .catch(() => { this.setState({ guest: true }); });
        await axios.get(process.env.REACT_APP_SERVER_API + `/subs/tip/prices`)
            .then((response) => { this.setState({tip_five_id: response.data.tips[3], tip_ten_id: response.data.tips[2], tip_twentytwo_id: response.data.tips[1], tip_custom_id: response.data.tips[0]}); })
            .catch(() => { })
        if (this.state.guest === false) {
            const id = this.state.ui;
            const rider = this.state.user_profile;
            if (rider === 2105 || rider === ("Motorcyk" || "motorcyk")) {
                this.setState({ rider_profile: false })
                window.location.href = `/profile/${id}`
            } else {
                await axios.get(process.env.REACT_APP_SERVER_API + `/user/rider/${id}/${rider}`)
                .then((response) => {
                    this.setState({ 
                        rider_id: response.data[0].id,
                        first_name: response.data[0].first_name,
                        display_name: response.data[0].display_name, 
                        zip_code: response.data[0].zip_code,
                        city: response.data[0].city,
                        state: response.data[0].state,
                        instagram: response.data[0].instagram,
                        facebook: response.data[0].facebook,
                        twitter: response.data[0].twitter,
                        tiktok: response.data[0].tiktok,
                        pinterest: response.data[0].pinterest,
                        snapchat: response.data[0].snapchat,
                        youtube: response.data[0].youtube,
                        rumble: response.data[0].rumble,
                        tumblr: response.data[0].tumblr,
                        active_paid: response.data[0].active_paid,
                        on_trial: response.data[0].on_trial,
                        created_at: response.data[0].created_at,
                        profile_header_message: response.data[0].profile_header_message,
                        rider_jar_enabled: response.data[0].tip_jar===0?false:true,
                        followers_count: response.data[2].length,
                        following_count: response.data[1].length,
                        following: response.data[3].length === 0 ? false : true,
                        guest: false,
                        rider_profile: true,
                    })
                })
                .catch((err) => { console.log(err, "Error retrieving rider info"); this.setState({ rider_profile: false, isLoading: false })})
            }
            const config_sub = { headers: { Authorization: `Bearer ${token}` }, id: this.state.ui, };
            await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
                .then((response) => { this.setState({ status: response.data, }); })
                .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
            if (this.state.status.active === true) {
                await axios.get(process.env.REACT_APP_SERVER_API + `/user/events/attending/${this.state.user_profile}`)
                    .then((response) => { this.setState({ attending_events: response.data }) })
                    .catch((err) => { console.log(err, "Error retrieving attending events!") })  
                await axios.get(process.env.REACT_APP_SERVER_API + `/user/events/hosting/${this.state.user_profile}`)
                    .then((response) => { this.setState({ hosting_events: response.data }) })
                    .catch((err) => { console.log(err, "Error retrieving hosted events!") }) 
                await axios.get(process.env.REACT_APP_SERVER_API + `/user/posts/${this.state.user_profile}`)
                    .then((response)=>{ this.setState({ posts: response.data }) })
                    .catch(err => { console.log(err, "Error retrieving posts!") });
                await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
                    .then((response) => { this.setState({ message_unread: response.data.status }); })
                    .catch((err) => { console.log(err, "Error checking for message status!") })
                await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
                    .then((response) => { this.setState({ image_url: response.data })})
                    .catch((err) => { console.log(err, "my error in retrieving user profile image") })
            };
            if (this.state.rider_jar_enabled===true) {
                await axios.get(process.env.REACT_APP_SERVER_API + `/user/posts/${this.state.user_profile}`)
                    .then((response)=>{ this.setState({ posts: response.data }) })
                    .catch(err => { console.log(err, "Error retrieving posts!") });
            }
            if (this.state.active===false) {
                await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
                    .then((response) => { this.setState({ image_url: response.data })})
                    .catch((err) => { console.log(err, "my error in retrieving user profile image") })
            };
            await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${rider}`)
                .then((response) => { this.setState({ image_url_rider: response.data })})
                .then(() => { this.setState({ isLoading: false }) })
                .catch((err) => { console.log(err, "my error...");  this.setState({ isLoading: false }) })
        } else {
            const rider = this.state.user_profile;
            if (rider === 2105 || rider === ("Motorcyk" || "motorcyk")) {
                this.setState({ rider_profile: false })
                window.location.href = `/signin`
            } else {
                await axios.get(process.env.REACT_APP_SERVER_API + `/rider/${this.state.user_profile}`)
                    .then((response) => {
                        this.setState({
                            rider_id: response.data[0].id,
                            first_name: response.data[0].first_name,
                            display_name: response.data[0].display_name, 
                            zip_code: response.data[0].zip_code,
                            city: response.data[0].city,
                            state: response.data[0].state,
                            instagram: response.data[0].instagram,
                            facebook: response.data[0].facebook,
                            twitter: response.data[0].twitter,
                            tiktok: response.data[0].tiktok,
                            pinterest: response.data[0].pinterest,
                            snapchat: response.data[0].snapchat,
                            youtube: response.data[0].youtube,
                            rumble: response.data[0].rumble,
                            tumblr: response.data[0].tumblr,
                            active_paid: response.data[0].active_paid,
                            on_trial: response.data[0].on_trial,
                            created_at: response.data[0].created_at,
                            profile_header_message: response.data[0].profile_header_message,
                            rider_jar_enabled: response.data[0].tip_jar===0?false:true,
                            followers_count: response.data[2].length,
                            following_count: response.data[1].length,
                            rider_profile: true,
                        });
                    })
                    .catch((err) => {console.log(err, "Rider not found!"); this.setState({ rider_profile: false, })})
            }
            if (this.state.rider_jar_enabled===true) {
                await axios.get(process.env.REACT_APP_SERVER_API + `/user/posts/${this.state.user_profile}`)
                    .then((response)=>{ this.setState({ posts: response.data }) })
                    .catch(err => { console.log(err, "Error retrieving posts!") });
            } else {
                await axios.get(process.env.REACT_APP_SERVER_API + `/guest/user/posts/${this.state.user_profile}`)
                    .then((response)=>{ this.setState({ posts: response.data }) })
                    .catch(err => { console.log(err, "Error retrieving posts!") });
            }
            await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.user_profile}`)
                .then((response) => { this.setState({ image_url_rider: response.data })})
                .then(() => { this.setState({ isLoading: false }) })
                .catch((err) => { console.log(err, "Error...");  this.setState({ isLoading: false }) })
        };
        if (this.state.tip_session!==null) {
            const data = {session: this.state.tip_session, rider_id: this.state.rider_id};
            axios.post(process.env.REACT_APP_SERVER_API + '/subs/tipped', data).then().catch()
        }
    };
    display = (name) => {
        this.setState({
            current_tab: name,
            clicked: true
        });
        if (name === 'attending') {
            this.setState({
                attending_clicked: '#FF2F46',
                attending_font: 'Permanent Marker',
                hosting_clicked: 'black',
                hosting_font: 'Poppins',
                posts_clicked: 'black',
                posts_font: 'Poppins'
            });
        };
        if (name === 'hosting') {
            this.setState({
                attending_clicked: 'black',
                attending_font: 'Poppins',
                hosting_clicked: '#FF2F46',
                hosting_font: 'Permanent Marker',
                posts_clicked: 'black',
                posts_font: 'Poppins'
            });
        };
        if (name === 'posts') {
            this.setState({
                attending_clicked: 'black',
                attending_font: 'Poppins',
                hosting_clicked: 'black',
                hosting_font: 'Poppins',
                posts_clicked: '#FF2F46',
                posts_font: 'Permanent Marker'
            });
        };
    };
    async follow(e) {
        e.preventDefault();
        this.setState({
            following: true, 
            followers_count: this.state.followers_count + 1,
        });
        const id = this.state.ui;
        const rider = this.state.user_profile;
        if (this.state.signed_in) {
            await axios.post(process.env.REACT_APP_SERVER_API + `/follow/${id}/${rider}`)
                .then(() => {})
                .catch( (err) => {console.log(err, "Error in follow!");})
        } else {
            window.location = '/signin';
        };
    };
    async unfollow(e) {
        e.preventDefault();
        this.setState({
            following: false, 
            followers_count: this.state.followers_count - 1,
        });
        const id = this.state.ui;
        const rider = this.state.user_profile;
        if (this.state.signed_in) {
            await axios.delete(process.env.REACT_APP_SERVER_API + `/unfollow/${id}/${rider}`)
                .then(() => {})
                .catch( (err) => {console.log(err, "Error in unfollow!");})
        } else {
            window.location = '/signin';
        };
    };
    async plan(price_id) {
        this.setState({ isLoading: true })
        const config = { 
            id: this.state.ui,
            rider_id: this.state.rider_id,
            guest: this.state.guest,
            priceId: price_id,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + '/subs/tip/session', config)
            .then((response) => { this.setState({ tip: response.data.url  }) })
            .then(() => { window.location.href = this.state.tip })
            .catch((err) => { console.log(err, "Error with checkout.") })
    };
    render() {
        if (this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let image;
        if (this.state.image_url_rider !== null) image = this.state.isLoading ? '' : <div className="rider-image-contianer-s3" style={{backgroundImage: `url(${this.state.image_url_rider})`}}></div>
        else image = this.state.isLoading ? '' :  <img className="profile-pic" src={user} alt=""/>
        let tab;
        switch (this.state.current_tab) {
            case 'posts':
                tab = <Posts data={this.state} />
                break;
            case 'attending':
                tab = <Attending data={this.state} />
                break;
            case 'hosting':
                tab = <Hosting data={this.state} />
                break;
            default:
                tab = tab = <Posts data={this.state} />
        };
        let name;
        let location;
        let follow_message;
        let header_buttons;
        let header_buttons_f;
        let rider_contents;
        if (this.state.display_name !== null) name = this.state.display_name
        else name = this.state.first_name
        if (this.state.zip_code !== null) location = <h3 className="rider-detail-item font-1 r">{this.state.city}{this.state.city ? ', ' : ''}{this.state.state}</h3>
        if (this.state.guest===false) {
            header_buttons = this.state.ui===parseInt(this.state.rider_id)?'':
                <div className="rider-info-button-container no-dec">
                    <form onSubmit={(e) => {this.follow(e)}}>
                        <button className="rider-info-button">Follow</button>
                    </form>
                    <Link className="no-dec" to={{ pathname: `/message`, state: {rider: this.state.user_profile, rider_dn: this.state.display_name, rider_fn: this.state.first_name} }}><button className="rider-info-button no-dec">Message</button></Link>
                </div>
        };
        if (this.state.guest===false) {
            header_buttons_f = this.state.ui===parseInt(this.state.rider_id)?'':
                <div className="rider-info-button-container">
                    <form onSubmit={(e) => {this.unfollow(e)}}>
                        <button className="rider-info-button-unfollow">Unfollow</button>
                    </form>
                    <Link className="no-dec" to={{ pathname: `/message`, state: {rider: this.state.user_profile, rider_dn: this.state.display_name, rider_fn: this.state.first_name} }}><button className="rider-info-button no-dec">Message</button></Link>
                </div>
        };
        if (this.state.ui !== parseInt(this.state.user_profile)) {
            if (this.state.following === false) follow_message = header_buttons;
            else follow_message = header_buttons_f;
        };
        if (this.state.rider_profile === true) {
            rider_contents =
                <div className="rider-pic-info-items">
                    <div className="rider-pic-item">
                    { image }
                    </div>
                    <div className="rider-info-items">
                        <h2 className="rider-name-item">
                            {name} {this.state.active_paid===1||this.state.on_trial===1?<img className="home-messages-icon" style={{width: 24, height: 24, marginBottom: -6}} src={mv} alt="" />:''}
                        </h2>
                        <p className="rider-detail-item">Member since {this.state.created_at}</p>
                        {location}
                        {this.state.user_profile !== ("Motorcyk" || "motorcyk") ? (this.state.guest ? <p className="rider-detail-item" style={{cursor: "default"}}>Followers {this.state.followers_count}</p> : <Link to={`/${this.state.user_profile}/followers`} className="rider-detail-item no-dec b link extra-margin" style={{marginTop: "10px"}}>Followers {this.state.followers_count}</Link>) : null}
                        {follow_message}
                    </div>
                    <h6 className="rider-message-status">
                        {this.state.profile_header_message}
                        <div className="rider-detail-socials-container">
                            {(this.state.instagram===""||this.state.instagram===null||this.state.instagram===undefined)?'':<a className="rider-detail-socials-link" href={`https://www.instagram.com/${this.state.instagram}`} target="_blank" rel="noopener noreferrer"><img className="rider-detail-socials-icon" src={Instagram} alt=""/></a>}
                            {(this.state.facebook===""||this.state.facebook===null||this.state.facebook===undefined)?'':<a className="rider-detail-socials-link" href={`https://www.facebook.com/${this.state.facebook}`} target="_blank" rel="noopener noreferrer"><img className="rider-detail-socials-icon" src={Facebook} alt=""/></a>} 
                            {(this.state.twitter===""||this.state.twitter===null||this.state.twitter===undefined)?'':<a className="rider-detail-socials-link" href={`https://www.twitter.com/${this.state.twitter}`} target="_blank" rel="noopener noreferrer"><img className="rider-detail-socials-icon" src={Twitter} alt=""/></a>}
                            {(this.state.tiktok===""||this.state.tiktok===null||this.state.tiktok===undefined)?'':<a className="rider-detail-socials-link" href={`https://www.tiktok.com/@${this.state.tiktok}`} target="_blank" rel="noopener noreferrer"><img className="rider-detail-socials-icon" src={TikTok} alt="" style={{width:"12px"}}/></a>} 
                            {(this.state.pinterest===""||this.state.pinterest===null||this.state.pinterest===undefined)?'':<a className="rider-detail-socials-link" href={`https://www.pinterest.com/${this.state.pinterest}`} target="_blank" rel="noopener noreferrer"><img className="rider-detail-socials-icon" src={Pinterest} alt=""/></a>} 
                            {(this.state.snapchat===""||this.state.snapchat===null||this.state.snapchat===undefined)?'':<a className="rider-detail-socials-link" href={`https://www.snapchat.com/add/${this.state.snapchat}`} target="_blank" rel="noopener noreferrer"><img className="rider-detail-socials-icon" src={Snapchat} alt=""/></a>} 
                            {(this.state.youtube===""||this.state.youtube===null||this.state.youtube===undefined)?'':<a className="rider-detail-socials-link" href={`https://www.youtube.com/@${this.state.youtube}`} target="_blank" rel="noopener noreferrer"><img className="rider-detail-socials-icon" src={Youtube} alt=""/></a>} 
                            {(this.state.rumble===""||this.state.rumble===null||this.state.rumble===undefined)?'':<a className="rider-detail-socials-link" href={`https://www.rumble.com/${this.state.rumble}`} target="_blank" rel="noopener noreferrer"><img className="rider-detail-socials-icon" src={Rumble} alt=""/></a>} 
                            {(this.state.tumblr===""||this.state.tumblr===null||this.state.tumblr===undefined)?'':<a className="rider-detail-socials-link" href={`https://www.tumblr.com/${this.state.tumblr}`} target="_blank" rel="noopener noreferrer"><img className="rider-detail-socials-icon" src={Tumblr} alt="" style={{width:"8px", marginTop:"4px"}}/></a>} 
                        </div>
                    </h6>   
                    {this.state.rider_jar_enabled===false?'':<div className="rider-tip-button-container">
                        <p className="rider-tip-title">Tip <span className="rider-tip-button-name r">{ this.state.display_name?this.state.display_name:this.state.first_name }</span> to show your support! <span role="img" aria-label="">😁</span></p>
                        <button className="rider-tip-button" onClick={() => {this.plan(this.state.tip_five_id)}}>$5</button>
                        <button className="rider-tip-button" onClick={() => {this.plan(this.state.tip_ten_id)}}>$10</button>
                        <button className="rider-tip-button" onClick={() => {this.plan(this.state.tip_twentytwo_id)}}>$22</button>
                        <button className="rider-tip-button-custom" onClick={() => {this.plan(this.state.tip_custom_id)}}>Custom Tip</button>
                    </div>}
                    <div className="rider-tab-container">
                        <p className="rider-posts-tab"  style={{ color: this.state.posts_clicked, fontFamily: this.state.posts_font}} onClick={() => this.display('posts')}>Posts</p>
                        <p className="rider-attending-tab" style={{ color: this.state.attending_clicked, fontFamily: this.state.attending_font }} onClick={() => this.display('attending')}>Attending</p>
                        <p className="rider-hosting-tab" style={{ color: this.state.hosting_clicked, fontFamily: this.state.hosting_font}} onClick={() => this.display('hosting')}>Hosting</p>
                    </div>
                    <div className="rider-active-tab">
                        {tab}
                    </div>
                </div>
        } else {
            rider_contents =
                <div className="rider-pic-info-items" style={{ marginTop: "200px", textAlign: "center", }}>
                    <h3>Rider does not exist!</h3>
                </div>
        };
        const body = this.state.isLoading?<Loading />: 
        <div>
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    { this.state.guest===true ? <li ><Link to="/signup" className="home-message-link-guest no-dec b">Sign Up</Link></li> : <li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li> }
                    { this.state.guest===true ? <li>|</li> : '' }
                    { this.state.guest===true ? <li ><Link to="/signin" className="home-message-link-guest no-dec b">Sign in</Link></li> : <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li> }
                </ul>
                <ul className="home-header-items no-dec td">
                    { this.state.guest===true ? <li ><Link to="/signup" className="home-message-link-guest no-dec b">Sign Up</Link></li> : <li><Link to="/settings" className="home-settings-link no-dec b">Settings</Link></li> }
                    { this.state.guest===true ? <li>|</li> : '' }
                    { this.state.guest===true ? <li ><Link to="/signin" className="home-message-link-guest no-dec b">Sign in</Link></li> : this.state.status.active===false?"":<li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li> }
                    { this.state.guest===true ? '' : this.state.status.active===false?"":<li><Link to={"/host"} className="home-host-link no-dec b">Host</Link></li> }
                    { this.state.guest===true ? '' : <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li> }
                    { this.state.guest===true ? '' : <li><Link to={"/profile/" + this.state.ui} className="home-message-link no-dec b">Profile</Link></li> }
                    { this.state.guest===true ? '' : this.state.status.active===false?"":<li ><Link to="/home" className="home-rider-link no-dec b">Home</Link></li> }
                    {this.state.guest===true ? '' : this.state.status.active===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}

                </ul>
            </div>
            { rider_contents }
            { this.state.guest ? '' : <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/> }
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default Rider;