import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import Loading from '../components/LoadingPage';
import '../static/css/AboutMotorcyk.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
import Instagram from '../static/images/images/instagram.svg';
import Facebook from '../static/images/images/facebook.svg';
import Twitter from '../static/images/images/twitter.svg';
// import TikTok from '../static/images/images/tiktok.svg';
import Pinterest from '../static/images/images/pinterest.svg';
// import Reddit from '../static/images/images/reddit.svg';
// import Snapchat from '../static/images/images/snapchat.svg';
// import LinkedIn from '../static/images/images/linkedin.svg';
import Youtube from '../static/images/images/youtube.svg';
// import Rumble from '../static/images/images/rumble.svg';
// import Tumblr from '../static/images/images/tumblr.svg';
class AboutMotorcyk extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            redirect: false,
            ui: null,
            image_url: null,
            message_unread: false,
        };
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id }); })
            .catch(err => {
                console.log("You are not logged in or something went wrong with fetching data!", err)
                this.setState({ redirect: true });
            });
        const config_sub = { 
            headers: { Authorization: `Bearer ${token}`, }, 
            id: this.state.ui,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!")})
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .then(()=> { this.setState({ isLoading: false }) })
            .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>;
        const body = this.state.isLoading?<Loading />:
        <div className="about-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}                  
                </ul>
            </div>
            <div className="about-section">
                <div className="about-title-container">
                    <h1 className="about-title" >About Motorcyk</h1>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
                    <h3>Welcome to Motorcyk, a platform connecting motorcyclists from all over the world.</h3>
                </div>
                <div className="about-content">
                    <h2 className="about-header">Our Vision</h2>
                    <p className="about-statement">To connect motorcylists from all around the world into one platform.</p>
                    <h2 className="about-header">Our Mission</h2>
                    <p className="about-statement">There are too many platforms out there mixed in with too many other communities making it difficult to make new connections, our mission is to have one platform where motorcylists can come together, network, grow, motivate those whom want to start, go out on rides, and sharing those experiences.</p>
                    {/* <h2 className="about-header">About The Founder</h2> */}
                    {/* <p className="about-statement">Motorcyk was just an idea in late 2019. After a few years of riding, founder Gustavo Arellano, experienced that there are too many communities within too many platforms that made it difficult to connect and share experiences with other riders. Gustavo created Motorcyk not only for current riders, but to also give motivation and encouragement to those wanting to get on a motorcycle and join the riding community, as well as to bring those lone riders whom want to network with other riders and go on a ride together. Motorcyk became official in 2022 and it's an exclusive platform for subscribed members.</p> */}
                    <h2 className="about-header" style={{fontSize: 20}}>Follow Us And Share Your Adventures</h2>
                    <h3 className="about-follow-icons" >
                        <a href="https://www.instagram.com/motorcyk_/" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Instagram} alt=""/></a> 
                        <a href="https://www.facebook.com/motorcyk" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Facebook} alt=""/></a> 
                        <a href="https://twitter.com/motorcyk" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Twitter} alt=""/></a> 
                        {/* <a href="https://www.tiktok.com/@motorcyk?is_from_webapp=1&sender_device=pc" target="_blank" rel="noopener noreferrer"><img className="about-social-icon" src={TikTok} alt=""/></a>  */}
                        <a href="https://www.pinterest.com/motorcyk" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Pinterest} alt=""/></a> 
                        {/* <a href="https://www.reddit.com/user/motorcyk" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Reddit} alt=""/></a>  */}
                        {/* <a href="#" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Snapchat} alt=""/></a>  */}
                        {/* <a href="https://www.linkedin.com/company/motorcyk" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={LinkedIn} alt=""/></a>  */}
                        <a href="https://www.youtube.com/channel/UC74mB3afir3CvrF3NdDjc2w" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Youtube} alt=""/></a> 
                        {/* <a href="https://rumble.com/c/c-2134132" target="_blank" className="about-social-icon-link" rel="noopener noreferrer" ><img className="about-social-icon" src={Rumble} alt=""/></a>  */}
                        {/* <a href="https://www.tumblr.com/motorcyk" target="_blank" rel="noopener noreferrer" className="about-social-icon-link"><img className="about-social-icon" src={Tumblr} alt=""/></a> */}
                    </h3>
                    <h4 className="about-tag">#LetsRideMotorcyk<br />#LetsRide<br />#Motorcyk</h4>
                    <Link to="/settings" className="no-dec"><button className="about-button">Back</button></Link>
                </div>
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default AboutMotorcyk;