import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import '../static/css/Messages.css';
import user from '../static/images/images/user-circle-solid.svg';
// import messages_icon from '../static/images/images/comment-dots-solid.svg';
// import logout from '../static/images/images/right-from-bracket-solid.svg';
// import dot from '../static/images/images/circle-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
import createMessage from '../static/images/images/pen-to-square-solid.svg';
class Messages extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            status: '',
            isLoading: true,
            redirect: false,
            ui: null,
            message_url: null,
            messages: [],
            has_unread: false,
            end: null,
            cancelled: false,
            message_unread: false,
            trial_used: false,
        };
        this.messages = this.messages.bind(this);
        this.read = this.read.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, }); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        const config_sub = { headers: { Authorization: `Bearer ${token}`, }, id: this.state.ui, };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        if (this.state.status.active === true) {
            const id = { id: this.state.ui }
            await axios.post(process.env.REACT_APP_SERVER_API + '/message-list', id)
                .then((response) => { this.setState({ messages: response.data }) })
                .catch(err => { console.log(err, "Error in retrieving message list!") });
        };
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data })})
            .then(()=> { this.setState({ isLoading: false }) })
            .catch((err) => {console.log(err, "my error...");  this.setState({ isLoading: false }) })
    };
    read(mp, ui, rider) {
        const info = {
            mp: mp,
            ui: ui,
            rider: rider
        }
        axios.put(process.env.REACT_APP_SERVER_API + '/read', info)
            .then(() => { })
            .catch(err => { console.log(err, "Error with updating read!") });
    }
    messages() {
        const Message = (props) => (
            <Link to={{ pathname: `/message`, state: {rider: props.message.pair_name[0].id, rider_dn: props.message.pair_name[0].display_name, rider_fn: props.message.pair_name[0].first_name} }} className="messages-message-container no-dec r" onClick={() => {this.read(props.message.message_pair_id, this.state.ui, props.message.pair_name[0].id)}}>
                <div className="messages-message-user-icon" style={{backgroundImage: `url(${props.message.image_url ? props.message.image_url : user})`}}></div>
                <p className="messages-message-user-name-preview"><strong>{props.message.pair_name[0].display_name ? props.message.pair_name[0].display_name : props.message.pair_name[0].first_name }</strong> <br/>{props.message.message}</p>
                { props.message.read === 0 ? <h6 className="messages-message-read-status r">Unread</h6> : <h6 className="messages-message-read-status b">Read</h6> }
            </Link>
        );
        return this.state.messages.map(message => {
            return  <Message message={message} key={message.id} />
        });
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>;
        let messages;
        if (this.state.status.active===true) {
            if (this.state.messages.length !== 0) {
                messages = this.state.isLoading ? '' : this.messages();
            } else {
                messages =  this.state.isLoading ? '' : <p className="no-messages">No messages to display!</p>
            };
        };
        let active;
        if (this.state.status.active===true) {
            active = messages;
        } 
        // else {
        //     active = 
        //         <div style={{textAlign: "center"}}>
        //             <p>Messages are only available to those with an active membership! <span role="img" aria-label="">😎</span> Please activate your membership!</p>
        //             <Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="no-dec"><button className="edit-profile-form-button back">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button></Link>                
        //         </div>
        // };
        const body = this.state.isLoading?<Loading />: 
        <div className="messages-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/settings" className="home-settings-link no-dec b">Settings</Link></li>
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}                 
                </ul>
            </div>
            <div className="messages-title-container">
                <h1 className="messages-title" >Messages</h1>
                {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
                {this.state.status.past_due?<p style={{fontSize: "15px",width: "90%", textAlign: "center", backgroundColor: "white", margin: "20px auto"}}>Your account is currently past due, please update payment method to avoid access interruption. <br/> <Link to={"/change-payment"} className="no-dec" ><button className="edit-profile-form-button">Update Payment</button></Link></p>:''}
            </div>
            <div className="messages-create-new-message-statement-container">
                {this.state.status.active===true ? <Link className="messages-create-new-message-statement no-dec" to={`/new-message`}>New Message <img className="messages-new-message-icon" src={createMessage} alt="" /></Link> : null} 
            </div>
            <div className="messages-messages-container">               
                { active }
            </div>
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default Messages;