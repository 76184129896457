import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import '../static/css/ResetPassword.css';
import logoIcon from '../static/images/images/logo_icon.png';
class ResetPassword extends Component {
    constructor() {
        super();
        this.state = {
            logged_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            redirect: false,
            redirect_home: false,
            email: null,
            password: '',
            confirm_password: '',
            password_required: null,
            password_min: null,
            password_number: null,
            password_special_character: null,
            confirm_password_required: null,
            confirm_password_match: null,
        };
        this.onChangePassword = this.onChangePassword.bind(this)
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    };
    async componentDidMount () {
        this.setState({ isLoading: true });
        const {id} = this.props.match.params;
        const {token} = this.props.match.params;
        await axios.get(process.env.REACT_APP_SERVER_API + `/auth/reset-password/${id}/${token}`)
            .then( (response) => {
                this.setState({ 
                    isLoading: false,
                    email: response.data
                });
            })
            .catch(err => {
                this.setState({ redirect: true });
            });
        const verify = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${verify}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( () => {})
            .catch(err => { console.log(err, "Error, not logged in or server error."); this.setState({ isLoading: false }); });
    };
    onChangePassword(event) {
        this.setState({
            password: event.target.value
        });
    };
    onChangeConfirmPassword(event) {
        this.setState({
            confirm_password: event.target.value
        });
    };
    async onSubmit(event) {
        event.preventDefault();
        const {id} = this.props.match.params;
        const {token} = this.props.match.params;
        const password = {
            password: this.state.password,
            confirm_password: this.state.confirm_password
        }
        await axios.post(process.env.REACT_APP_SERVER_API + `/auth/reset-password/${id}/${token}`, password)
            .then( () => { this.setState({ redirect_home: true }) })
            .catch(err => {
                console.log(err, "Validation failed...")
                this.setState({
                    password_required: err.response.data.password_required,
                    password_min: err.response.data.password_min,
                    password_special_character: err.response.data.password_special_character,
                    password_number: err.response.data.password_number,
                    confirm_password_required: err.response.data.confirm_password_required,
                    confirm_password_match: err.response.data.confirm_password_match,
                });
            });
    };
    render() {
        if (this.state.redirect === true) return <Redirect to={{pathname: "/forgot-password"}}/>
        if (this.state.redirect_home === true) return <Redirect to={{pathname: "/signin"}}/>
        const body = this.state.isLoading?<h1>Loading...</h1>:
        <div className="reset-password-container">
            <h1 className="reset-password-title r">Reset Password</h1>
            <Link to="/" className="no-dec"><img className="reset-logo" src={logoIcon} alt="" /></Link>
            <h6 className="reset-password-message w">Please enter a new password for your account. <br/> ({this.state.email})</h6>
            <form className="reset-password-form" onSubmit={this.onSubmit}>
                <p className="reset-password-form-error-message">{ this.state.password_required }</p>
                <p className="reset-password-form-error-message">{ this.state.password_min }</p>
                <p className="reset-password-form-error-message">{ this.state.password_number }</p>
                <p className="reset-password-form-error-message">{ this.state.password_special_character }</p>
                <input type="password" placeholder="New Password" className="reset-password-input" value={this.password} onChange={this.onChangePassword}/>
                <p className="reset-password-form-error-message">{ this.state.confirm_password_required }</p>
                <p className="reset-password-form-error-message">{ this.state.confirm_password_match }</p>
                <input type="password" placeholder="Confirm Password" className="reset-password-input" value={this.confirm_password} onChange={this.onChangeConfirmPassword}/>
                <button className="reset-password-button no">Reset Password</button>
            </form>
            <h6 className="reset-password-footer w">Motorcyk LLC</h6>
        </div>
        return (
            <div>
                { body }
            </div>
        );
    };
};
export default ResetPassword;