import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from './LoadingPage';
import axios from 'axios';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/RiderTipJarCashout.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
import paypal_logo from '../static/images/images/paypal-logo.png';
class RiderTipJarCashout extends Component {
    constructor() {
        super()
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            active: false,
            status: '',
            isLoading: true,
            redirect: false,
            ui: null,
            image_url: null,
            payment_method: 'PAYPAL',
            payment_method_id: null,
            cancelled: false,
            end: null,
            message_unread: false,
            trial_used: false,
            balance: 0,
            transactions: 0,
            update: "inline",
            update_method: "none",
            cancel_method: "none",
            confirm_cashout: "none",
            display_no_method_message: "block",
            display_no_method_button: "block",
            info: "",
            info_no_match: "none",
            info_required: "none",
            info_confirm: '',
            info_confirm_missing: "none",
            cashout_button: "none",
            cancel_cashout_button: "none",
            info_direct_link: null,
            cancel_confirm_cashout: "none",
            service_fee: '',
            service_fee_decimal: '',
            transaction_fees: '',
            transaction_fees_decimal: '',
        }
        this.onChangeInfo = this.onChangeInfo.bind(this);
        this.onChangeInfoConfirm = this.onChangeInfoConfirm.bind(this);
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}` } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, }); })
            .catch(() => { this.setState({ redirect: true }); });
        const config_sub = { headers: { Authorization: `Bearer ${token}`, },  id: this.state.ui, };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
            .then((response) => { 
                this.setState({ 
                    status: response.data,
                }) 
                // IF A PAID/TRIAL MEMBER
                if (response.data.active_paid===true||response.data.on_trial===true||(response.data.canceled===true||response.data.canceled_access===true)) {
                    this.setState({ service_fee: "20%", service_fee_decimal: .20, transaction_fees: "0.40", transaction_fees_decimal: .40, })
                }

                // IF NOT PAID/TRIAL MEMBER
                if (response.data.active_paid===false&&response.data.on_trial===false) {
                    this.setState({ service_fee: "25%", service_fee_decimal: .25, transaction_fees: "0.60", transaction_fees_decimal: .60, })
                }
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.post(process.env.REACT_APP_SERVER_API + `/rider/tip/jar/cashout/${this.state.ui}`, config)
            .then((response) => { this.setState({ transactions: response.data.tips.length, balance: response.data.balance/100, payment_method: response.data.payment_method.method, payment_method_id: response.data.payment_method.method_id, cashout_button: response.data.payment_method.method_id===null?"none":"block", cancel_cashout_button: response.data.payment_method.method_id===null?"none":"block"}); console.log(response.data, "***")})
            .then(()=>{if(this.state.balance===0) window.location='/rider-tip-jar'})
            .catch((err) => { console.log(err, "Error retrieving balance!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data, isLoading: false })})
            .catch((err) => {this.setState({ isLoading: false }); })
    };
    onChangeInfo(event) {
        this.setState({
            info: event.target.value
        });
    };
    onChangeInfoConfirm(event) {
        this.setState({
            info_confirm: event.target.value
        });
    };
    update_button() {
        this.setState({ update: "none", update_method: "block", cancel_method: "block", display_no_method_message: "none", display_no_method_button: "none", cashout_button: "none", cancel_cashout_button: "none", confirm_cashout: "none"});
    };
    cancel_update() {
        this.setState({ update: "inline", update_method: "none", cancel_method: "none", display_no_method_message: "block", display_no_method_button: "block", info: '', info_confirm: '', cashout_button: "block", cancel_cashout_button: "block" })
    };
    cashout() {
        this.setState({ confirm_cashout: "block", cashout_button: "none", cancel_cashout_button: "none", cancel_confirm_cashout: "block"})
    };
    cancel_confirm_cashout() {
        this.setState({ confirm_cashout: "none", cashout_button: "block", cancel_cashout_button: "block", cancel_confirm_cashout: "none"})
    };
    async update_payment_method(e) {
        e.preventDefault();
        if (this.state.info!==this.state.info_confirm) {
            this.setState({info_no_match: "block"});
        } else {
            this.setState({info_no_match: "none"});
        };
        if (this.state.info.length===0) {
            this.setState({info_required: "block"});
        } else {
            this.setState({info_required: "none"});
        };
        if (this.state.info_confirm.length===0) {
            this.setState({info_confirm_missing: "block"});
        } else {
            this.setState({info_confirm_missing: "none"});
        };
        if (this.state.info===this.state.info_confirm&&this.state.info.length>0&&this.state.info_confirm.length>0) {
            this.setState({ isLoading: true })
            const token = localStorage.getItem("token");
            const config = { 
                headers: { Authorization: `Bearer ${token}`, },
                id: this.state.ui,
                method: "PAYPAL", // CHANGE THIS WHEN MORE OPTIONS BECOME AVAILALBLE
                method_id: this.state.info,
            };
            await axios.post(process.env.REACT_APP_SERVER_API + `/rider/tip/jar/update/payment_method`, config)
                .then(()=>{ alert('Payment method updated successfully! ✅') })
                .then(()=>window.location='/rider-tip-jar-cashout')
                .catch(()=>{alert('Something went wrong with updating payment method, please try again or email us at hello@motorcyk.com. 🛑'); this.setState({isLoading: false, })})
        };  
    };
    async confirm_continue_cashout(e) {
        e.preventDefault();
        const token = localStorage.getItem("token");
        const balance = this.state.balance.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        const transaction_fee = (this.state.transactions*this.state.transaction_fees).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        const service_fee = (this.state.balance*this.state.service_fee_decimal).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        const cashout_amount = ((this.state.balance)-(this.state.transactions*this.state.transaction_fees)-(this.state.balance*this.state.service_fee_decimal)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        const config = { 
            headers: { Authorization: `Bearer ${token}`, },
            id: this.state.ui,
            jar_balance: balance,
            total_service_fee: service_fee,
            total_transactions: this.state.transactions,
            total_transaction_fees: transaction_fee,
            total_cashout: cashout_amount,
            method: "PAYPAL", // CHANGE THIS WHEN MORE OPTIONS BECOME AVAILALBLE
            method_id: this.state.payment_method_id,
        };
        await axios.post(process.env.REACT_APP_SERVER_API + `/tip/jar/cashout`, config)
            .then((response)=>{console.log(response, "** route call was successfull!")})
            .then(()=>{window.location='/rider-tip-jar'})
            .catch(()=>{console.log("Error happend during cashout!")})
    };
    render() {
        console.log("this is the method state: ", this.state.payment_method)
        if (this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>;
        let balance;
        let transaction_fee;
        let service_fee;
        let cashout_amount;
        balance = this.state.balance.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        transaction_fee = (this.state.transactions*this.state.transaction_fees_decimal).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        service_fee = (this.state.balance*this.state.service_fee_decimal).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        cashout_amount = ((this.state.balance)-(this.state.transactions*this.state.transaction_fees_decimal)-(this.state.balance*this.state.service_fee_decimal)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    
        const body = this.state.isLoading?<Loading />: 
        <div className="next-payment-date-container">
            <div className="home-header">
                <h5 className="home-header-title"><Link to="/" className="no-dec"><img className="header-logo" src={logo} alt="" /></Link></h5>
                <ul className="home-header-items no-dec mobile">
                    <li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/settings" className="home-explore-link no-dec b">Settings</Link></li>
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}

                </ul>
            </div>
            <div className="next-payment-date-title-container">
                <h1 className="next-payment-date-title" >Tip Jar Cashout</h1>
                {this.state.status.active_paid===true||this.state.status.on_trial===true?'':<Link to={"/signup-plan"} className="r no-dec font-1 hover mobile">Subscribe To Motorcyk</Link>}
                {this.state.past_due?<p style={{fontSize: "15px",width: "90%", textAlign: "center", backgroundColor: "white", margin: "20px auto"}}>Your account is currently past due, please update payment method to avoid access interruption. <br/> <Link to={"/change-payment"} className="no-dec" ><button className="edit-profile-form-button">Update Payment</button></Link></p>:''}
            </div>
            <div className="tip-jar-cashout-total">
                <p>Tip Jar Balance: ${balance}</p>
                <p>Total Tip Transactions {this.state.transactions} x {this.state.transaction_fees}: -${transaction_fee}</p>
                <p>Service Fee ({this.state.service_fee}): -${service_fee}</p>
                <p>TOTAL CASHOUT AMOUNT: <strong><u>${cashout_amount}</u></strong></p>
                <p>Payout Method: <img src={paypal_logo} alt="PayPal Logo" className="tip-jar-cashout-paypal-logo"/></p>
                <p>Account: <span style={{fontSize: "12px"}}>{this.state.payment_method_id===null?'No Account':this.state.payment_method_id}</span></p>
                <button className="tip-jar-cashout-paypal-account" onClick={()=>this.update_button()} style={{display: this.state.update, }}>{this.state.payment_method===null?'Add Payout Method':'Update Payout Method'}</button>
            </div>

            {this.state.payment_method===null?<p style={{margin: "50px auto", width: "90%", textAlign: "center", display: this.state.display_no_method_message }}>You must have a payout method on file in order to cashout, please update it above!</p>:''}
            {this.state.payment_method===null?<Link to="/rider-tip-jar" className="no-dec" style={{display: this.state.display_no_method_button,}}><button className="tip-jar-cashout-continue">Go Back</button></Link>:''}
            <div className="tip-jar-cashout-account-method-form-container" style={{display: this.state.update_method}}>
                <form className="tip-jar-cashout-account-method-form" onSubmit={(e)=>this.update_payment_method(e)}>
                    <p className="r" style={{display: this.state.info_no_match}}>Both fields do not match.</p>
                    <p className="r" style={{display: this.state.info_required}}>Both fields must be filled out.</p>
                    <label>ENTER your Paypal username, email address, or mobile number:</label>
                    <input type="text" placeholder="Enter PayPal username or phone number" value={this.state.info} onChange={this.onChangeInfo} style={{textAlign:"center"}}/>
                    <p className="r" style={{display: this.state.info_confirm_missing}}>Please confirm your payout method info.</p>
                    <label>Confirm your Paypal username, email address, or mobile number:</label>
                    <input type="text" placeholder="Enter PayPal username or phone number" value={this.state.info_confirm} onChange={this.onChangeInfoConfirm} style={{textAlign:"center"}}/>
                    <button type="submit">{this.state.payment_method===null?"Add":"Update"}</button>
                    <button type="button" style={{display: this.state.cancel_method}} onClick={()=>this.cancel_update()}>Cancel</button>
                </form>

            </div>
            {this.state.payment_method===null?'':<div className="tip-jar-cashout-continue-container">
                <button className="tip-jar-cashout-continue" style={{display: this.state.cashout_button}} onClick={()=>{this.cashout()}}>Cashout</button>
                <Link to="/rider-tip-jar" className="no-dec" style={{display: this.state.cancel_cashout_button}}><button className="tip-jar-cashout-continue">Cancel</button></Link>
            </div>}
            <div className="tip-jar-confirm-cashout-confirmation" style={{display: this.state.confirm_cashout}}>
                <p>Once you continue and cashout, you will not be able to undo this action, your jar will be emptied, and your total cashout amount will be deposited into your default cashout account. Please allow 7-14 days for processing and funds to be sent out.</p>
            </div>
            <div className="tip-jar-confirm-cashout-container" style={{display: this.state.confirm_cashout}}>
                <form onSubmit={(e)=>this.confirm_continue_cashout(e)}><button type="submit" className="tip-jar-confirm-cashout-continue">Confirm and Cashout</button></form>
                <button className="tip-jar-cashout-continue" style={{display: this.state.cancel_confirm_cashout}} onClick={()=>{this.cancel_confirm_cashout()}}>Cancel</button>
            </div>

            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default RiderTipJarCashout;