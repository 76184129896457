import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/LoadingPage';
import axios from 'axios';
import user from '../static/images/images/user-circle-solid.svg';
import messages from '../static/images/images/comment-dots-solid.svg';
import dot from '../static/images/images/circle-solid.svg';
import logout from '../static/images/images/right-from-bracket-solid.svg';
import MobileFooterNav from './MobileFooterNav';
import '../static/css/CancelMembership.css';
import logo from '../static/images/images/Motorcyk_SSa-R03a_Mil.png';
class CancelMembership extends Component {
    constructor() {
        super();
        this.state = {
            signed_in: localStorage.getItem('token') ? true : false,
            isLoading: true,
            redirect: false,
            ui: null,
            image_url: null,
            status: '',
            cancel: "block",
            confirm_cancel: "none",
            active: false,
            end: null,
            cancelled: false,
            message_unread: false,
            trial_used: false,
        };
        this.cancel = this.cancel.bind(this);
        this.confirm_cancel = this.confirm_cancel.bind(this)
    };
    async componentDidMount () {
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}`, } };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/verify', config)
            .then( (response) => { this.setState({ ui: response.data.id, trial_used: response.data.trial_used===0?false:true, }); })
            .catch(err => {
                console.log(err, "You are not logged in or something went wrong with fetching data!")
                this.setState({ redirect: true });
            });
        const config_sub = { headers: { Authorization: `Bearer ${token}` }, id: this.state.ui, };
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription`, config_sub)
        .then((response) => { 
            this.setState({ 
                status: response.data,
            }) 
        })
        .catch((err)=>{ console.log(err, "Error in subscription retrieval") })
        await axios.post(process.env.REACT_APP_SERVER_API + `/subs/subscription/cancel`, config_sub)
            .then((response) => { 
                this.setState({ 
                    active: response.data.status, 
                    end: response.data.date,
                    cancelled: response.data.status === "canceled" ? true : false,
                }) 
                var end_date = new Date(response.data.date);
                var current_date = new Date();
                if (response.data.cancelled) {
                    if (end_date.setHours(0,0,0,0) < current_date.setHours(0,0,0,0)) this.setState({ active: response.data.active, end: response.data.date, isLoading: false, }); 
                    else {
                        this.setState({ active: 'cancelled', end: response.data.date, isLoading: false, }); 
                    };
                } else { this.setState({ isLoading: false, }) };
            })
            .catch((err)=>{ console.log(err, "Error in subscription retrieval"); this.setState({ isLoading: false, }) })
        await axios.get(process.env.REACT_APP_SERVER_API + `/read/status/${this.state.ui}`)
            .then((response) => { this.setState({ message_unread: response.data.status }); })
            .catch((err) => { console.log(err, "Error checking for message status!") })
        await axios.get(process.env.REACT_APP_SERVER_API + `/profile/image/${this.state.ui}`)
            .then((response) => { this.setState({ image_url: response.data, isLoading: false, })})
            .catch((err) => {console.log(err, "my error..."); this.setState({ isLoading: false, })})
    };
    cancel = () => { this.setState({ cancel: "none", confirm_cancel: "block"}); };
    confirm_cancel = async () => {
        this.setState({ isLoading: true });
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}`, },
            id: this.state.ui
        }
        await axios.post(process.env.REACT_APP_SERVER_API + '/subs/cancel/membership', config)
            .then(() => { alert("Your membership has been cancelled!"); })
            .then(() => { window.location.reload(); })
            .catch((err) => { console.log(err, "this is my error with cancellation"); this.setState({ isLoading: false }) })
    };
    render() {
        if(this.state.redirect === true) return <Redirect to = {{pathname: "/signin"}}/>
        let contents;
        let end_date = new Date(this.state.end);
        let current_date = new Date();
        if (this.state.active === true) {
            contents =
                <div className="cancel-membership-section">
                    <button className="cancel-membership-button" style={{display: this.state.cancel, margin: "auto", marginTop: "20px"}} onClick={() => this.cancel()}>CANCEL MEMBERSHIP</button>
                    <p style={{display: this.state.confirm_cancel, margin: "auto", marginTop: "20px"}}>We're sad to see you go, but are you sure you want to cancel? Once cancelled, you will still be able to use all membership features until the end of your payment cycle, then you will lose access to all membership features!</p>
                    <button className="cancel-membership-button" style={{display: this.state.confirm_cancel, margin: "auto", marginTop: "20px"}} onClick={() => this.confirm_cancel()}>CONFIRM CANCELLATION</button>
                    <Link to="/settings" className="no-dec"><button className="cancel-membership-button">Go Back</button></Link>
                </div>
        } else {
            if ((this.state.active !== "canceled" && end_date.setHours(0,0,0,0) < current_date.setHours(0,0,0,0)) || (this.state.active === "canceled" && end_date.setHours(0,0,0,0) < current_date.setHours(0,0,0,0))) {
                contents =
                    <div className="cancel-membership-section">
                        <h3>You currently do not have an active membership!</h3>
                        <Link 
                            to={{ 
                                pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan",
                                data: {
                                    id: this.state.ui,
                                    customer_id: this.state.customer_id
                                }
                            }}
                            className="no-dec"
                        >
                            <button className="cancel-membership-button">{this.state.trial_used===false?"Activate Free Trial":"Activate Membership"}</button>
                        </Link>
                        <Link to="/settings" className="no-dec"><button className="cancel-membership-button">Go Back</button></Link>
                    </div>
            } else {
                    contents = 
                        <div className="cancel-membership-section">
                            <p>You have already cancelled your membership, features will be available until <strong>{this.state.end}</strong>. You can then reactivate your membership.</p>
                            <Link to="/settings" className="no-dec"><button className="cancel-membership-button">Go Back</button></Link>
                        </div>
            }
        };
        const body = this.state.isLoading?<Loading />:
        <div className="cancel-membership-container">
            <div className="home-header">
                <h5 className="home-header-title"><img className="header-logo" src={logo} alt="" /></h5>
                <ul className="home-header-items no-dec mobile">
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true || (this.state.status.canceled===true && this.state.status.canceled_access===true))?<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>: <li><Link to="/" onClick={this.sign_out} className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={logout} alt="" /></Link></li>}
                    {(this.state.status.active_paid===true||this.state.status.on_trial===true||(this.state.status.canceled===true&&this.state.status.canceled_access===true))?'':<li><Link to="/messages" className="home-message-link no-dec r"><img className="home-messages-icon" style={{width: 24, height: 24}} src={messages} alt="" />{this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>}
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec r"><img className="home-user-icon" style={{borderRadius: '50%', width: 25, height: 25}} src={this.state.image_url ? this.state.image_url : user} alt="" /></Link></li>
                </ul>
                <ul className="home-header-items no-dec td">
                    <li><Link to="/explore" className="home-explore-link no-dec b">Explore</Link></li>
                    <li><Link to="/host" className="home-host-link no-dec b">Host</Link></li>
                    <li><Link to="/messages" className="home-message-link no-dec b">Messages {this.state.message_unread ? <img className="home-message-dot" src={dot} alt="" /> : null}</Link></li>
                    <li ><Link to={`/profile/${this.state.ui}`} className="home-profile-link no-dec b">Profile</Link></li>
                    {this.state.status.active_paid===true||this.state.status.on_trial===true?<li ><Link to="/home" className="home-home-link no-dec b">Home</Link></li>:''}
                    {this.state.status.active_paid===false&&this.state.status.on_trial===false?<li><Link to={{ pathname: this.state.trial_used===false?"/signup-plan-trial":"/signup-plan", }}  className="home-settings-link no-dec b">{this.state.trial_used===false?"Activate Free Trial":"Subscribe"}</Link></li>:''}                 
                </ul>
            </div>
            <div className="cancel-membership-title-container">
                <h1 className="cancel-membership-title" >Cancel Membership</h1>
            </div>
            { contents }
            <MobileFooterNav data={{ id: this.state.ui, path: this.props.location.pathname }}/>
        </div>
        return (
            <div>{ body }</div>
        );
    };
};
export default CancelMembership;